import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import ProductsService from "../../../services/products.service";
import { Col, Row, RangeSlider, SelectPicker } from "rsuite";
import ProductCard from "../../../components/ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../../stores/appSlice";
import CategoryService from "../../../services/Category.Service";
import ScrollToTop from "../../../utilities/ScrollToTop";
import { toast } from "react-toastify";
import { useSmallScreen } from "../../../utilities/useWindowSize";

const Productslist = ({ pageTitle }) => {
  const authState = useSelector((state) => state.authState);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState("none");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [highPrice, setHighPrice] = useState(0);
  const [priceRange, setPriceRange] = useState([0, 0]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  const getHighestPrice = (products) => {
    if (products?.length === 0) {
      return 0;
    }
    const highestPricedProduct = products?.reduce((highest, product) => {
      return product?.price > highest?.price ? product : highest;
    }, products[0]);

    const roundedPrice = Math.ceil(highestPricedProduct?.price / 50) * 50;
    setHighPrice(roundedPrice);
    setPriceRange([0, roundedPrice]);
    return roundedPrice;
  };

  const getAllCategories = async () => {
    try {
      const resp = await trackPromise(CategoryService.getCategory());
      setCategories(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Error fetching categories", error);
    }
  };

  const getAllProducts = async () => {
    try {
      const response = await trackPromise(ProductsService.products());
      const products = response.data?.data || [];
      setProducts(products);
      setFilteredProducts(products);
      getHighestPrice(products);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Error fetching products", error);
    }
  };

  useEffect(() => {
    getAllProducts();
    getAllCategories();
  }, []);

  const filterProductsByCategory = (categoryId) => {
    if (!categoryId) {
      setFilteredProducts(products);
    } else {
      const filteredProduct = products.filter(
        (product) => product.subCategory.category._id === categoryId
      );
      setFilteredProducts(filteredProduct);
    }
  };

  const filterProductsByPrice = () => {
    const [minPrice, maxPrice] = priceRange;
    const filteredProduct = products.filter(
      (product) => product.price >= minPrice && product.price <= maxPrice
    );
    sortProducts(filteredProduct);
  };

  const sortProducts = (productsToSort) => {
    let sortedProducts;
    switch (sortOrder) {
      case "asc":
        sortedProducts = productsToSort.sort((a, b) => a.price - b.price);
        break;
      case "desc":
        sortedProducts = productsToSort.sort((a, b) => b.price - a.price);
        break;
      default:
        sortedProducts = productsToSort;
    }
    setFilteredProducts(sortedProducts);
  };

  useEffect(() => {
    filterProductsByPrice();
  }, [priceRange, products, sortOrder]);

  useEffect(() => {
    filterProductsByCategory(selectedCategory);
  }, [selectedCategory, products]);

  const filterData = [
    { value: "none", label: "Default" },
    { value: "asc", label: "Price: Low to High" },
    { value: "desc", label: "Price: High to Low" },
  ];

  const isSmallScreen = useSmallScreen(768);

  return (
    <div className="product-page-container">
      <ScrollToTop />
      <Row>
        <Col xs={24} lg={6}>
          <aside className="sidebar">
            <div className="categories-section">
              <h4>Categories</h4>
              <ul>
                <li
                  className="category-filter"
                  onClick={() => setSelectedCategory(null)}
                  style={{ cursor: "pointer" }}
                >
                  All <span>({products.length})</span>
                </li>
                {categories?.map((category) => (
                  <li
                    className="category-filter"
                    key={category._id}
                    onClick={() => setSelectedCategory(category._id)}
                    style={{ cursor: "pointer" }}
                  >
                    {category.categoryName}{" "}
                    <span>
                      (
                      {
                        products.filter(
                          (product) =>
                            product.subCategory.category._id === category._id
                        ).length
                      }
                      )
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className="categories-section">
              <h4>Filter by Price</h4>
              <RangeSlider
                className="range-slider"
                min={0}
                max={highPrice}
                value={priceRange}
                onChange={setPriceRange}
                style={{ width: 200 }}
              />
              <div className="price-range">
                <span>Price: ₹{priceRange[0]}</span> -{" "}
                <span>₹{priceRange[1]}</span>
              </div>
            </div> */}
            <div className="categories-section">
              <h4>Sort by Price</h4>
              <SelectPicker
                className="product-list-picker"
                onChange={(value) => setSortOrder(value)}
                data={filterData}
                searchable={false}
                placeholder="Default"
              />
            </div>
          </aside>
        </Col>
        <Col xs={24} lg={18}>
          <main className="content">
            <div className="header">
              <div className="shop-head">Products</div>
              <div className="results-sorting">
                <span>Showing all {filteredProducts?.length} results</span>
              </div>
            </div>
            <div className="product-grid">
              <Row className="product-row">
                {filteredProducts?.length > 0 ? (
                  filteredProducts?.map((product) => (
                    <Col xs={12} md={8} key={product?._id}>
                      <ProductCard
                        product={product}
                        slice={isSmallScreen ? 20 : 24}
                      />
                    </Col>
                  ))
                ) : (
                  <Col xs={24}>
                    <div className="no-products-message">
                      No products available
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </main>
        </Col>
      </Row>
    </div>
  );
};

export default Productslist;
