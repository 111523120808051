import httpClient from "../httpClient";
const baseSlug = "/category";

class httpService {
  getCategory() {
    return httpClient.get(`${baseSlug}/valid`);
  }
  getCategoryList() {
    return httpClient.get(`${baseSlug}`);
  }

  getCategoryById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  createCategory(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  updateCategory(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }

  deleteCategory(id, data) {
    return httpClient.put(`${baseSlug}/delete/${id}`, data);
  }

  changeStatus(id, data) {
    return httpClient.put(`${baseSlug}/status/${id}`, data);
  }
}

export default new httpService();
