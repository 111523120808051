import httpClient from "../httpClient";
const baseSlug = "/invoices";

class httpService {
  invoices() {
    return httpClient.get(`${baseSlug}`);
  }

  invoiceById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  byOrder(id) {
    return httpClient.get(`${baseSlug}/order/${id}`);
  }

  updateInvoice(id, data) {
    return httpClient.put(`${baseSlug}/status/${id}`, data);
  }
}

export default new httpService();
