import React from "react";
import { Panel, Badge, Button, Tag } from "rsuite";
import "./addressCard.css";

const AddressCard = ({
  address,
  onSelect,
  isSelected,
  showEdit = false,
  onEdit,
  showDefaultBadge = true,
  onDelete,
  disable,
}) => {
  return (
    <Panel
      bordered
      className={`address-card ${isSelected ? "selected" : ""}`}
      onClick={onSelect}
      style={{ marginBottom: "20px", position: "relative" }}
    >
      {showDefaultBadge && address.isDefault && (
        <Tag
          style={{
            backgroundColor: "#75c32c",
            color: "#fff",
            position: "absolute",
            top: 5,
            right: 10,
            borderRadius: "25px",
          }}
        >
          Default
        </Tag>
      )}
      <div className="address-header">
        <span className="address-title">{`${address.lane}, ${address.city}`}</span>
      </div>
      <div className="address-details">
        <p>{`${address.lane}, ${address.city}, ${address.state}, ${address.pincode}`}</p>
        <p>Landmark: {address.landmark}</p>
        <p>Phone: {address.phone}</p>
      </div>
      {showEdit && (
        <div className="address-actions">
          <Button onClick={onEdit} appearance="link" className="prod-color">
            Edit
          </Button>
          <Button
            disabled={disable || address.isDefault}
            appearance="link"
            color="red"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(e);
            }}
          >
            Delete
          </Button>
        </div>
      )}
    </Panel>
  );
};

export default AddressCard;
