import React, { useEffect } from "react";
import {
  InputNumber,
  Button,
  Panel,
  Divider,
  Row,
  Col,
  Text,
  Heading,
  FlexboxGrid,
  Container,
  Dropdown,
} from "rsuite";
import Column from "rsuite/esm/Table/TableColumn";
import "./cart.css";
import EnvConfig from "../../../envConfig";
import { FaTrashCan } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../../utilities/cart";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../../stores/appSlice";
import { toast } from "react-toastify";
import ScrollToTop from "../../../utilities/ScrollToTop";
import { Cell, HeaderCell, Table } from "rsuite-table";
import { HashLink as Link } from "react-router-hash-link";
import { formatAmount } from "../../../utilities/formatAmount";

const ImageCell = ({ rowData, ...props }) => {
  return (
    <Cell {...props} className="image-cell gdavsx">
      <Link to={`/products/${rowData._id}`}>
        <img
          src={`${EnvConfig?.MediaBase}/${rowData?.assetsDir}/${rowData?.prodImages?.[0]}`}
          alt="Product"
        />
      </Link>
    </Cell>
  );
};

const Cart = ({ pageTitle }) => {
  const {
    cart,
    getCart,
    handleReplaceQuantity,
    removeFromCart,
    removeProductFromLocalCart,
    saveCartToLocalStorage,
  } = useCart();
  const authState = useSelector((state) => state.authState);
  const isAuth = authState.isAuthenticated;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  const handleCheckout = () => {
    if (isAuth) {
      if (cart.items.length === 0) {
        toast.error(
          "Your cart is empty. Add items to the cart before proceeding to checkout."
        );
      } else {
        navigate("/checkout");
      }
    } else {
      toast.error("Please login first");
    }
  };
  return (
    <Container className="shopping-cart">
      <ScrollToTop />
      <div className="shopping-cart-container">
        <Row>
          <Col xs={24} lg={17}>
            <Panel className="shopping-cart-panel">
              <div className="flexbox">
                <h3>Cart</h3>
                <Link to="/contact#contactForm">
                  <Button className="btn-green" appearance="primary">
                    Bulk Order
                  </Button>
                </Link>
              </div>
              <p>{cart?.items?.length} items in your cart.</p>

              {cart.items?.length > 0 ? (
                <Table data={cart?.items} autoHeight>
                  <Column width={100} align="center">
                    <HeaderCell>Image</HeaderCell>
                    <ImageCell />
                  </Column>
                  <Column width={200}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name">
                      {(rowData) => (
                        <Link
                          title={rowData.name}
                          className="prod-color fw-10"
                          to={`/products/${rowData._id}`}
                        >
                          {rowData.name}
                        </Link>
                      )}
                    </Cell>
                  </Column>
                  <Column width={100}>
                    <HeaderCell>Price</HeaderCell>
                    <Cell>{(rowData) => formatAmount(rowData?.price)}</Cell>
                  </Column>
                  <Column width={100}>
                    <HeaderCell>Tax</HeaderCell>
                    <Cell>{(rowData) => formatAmount(rowData?.taxAmt)}</Cell>
                  </Column>
                  <Column width={100}>
                    <HeaderCell>Discount</HeaderCell>
                    <Cell>
                      {(rowData) => formatAmount(rowData?.discountAmt)}
                    </Cell>
                  </Column>
                  <Column width={100}>
                    <HeaderCell>Total</HeaderCell>
                    <Cell>{(rowData) => formatAmount(rowData?.total)}</Cell>
                  </Column>
                  <Column width={120}>
                    <HeaderCell>Quantity</HeaderCell>
                    <Cell className="">
                      {(rowData) => (
                        <InputNumber
                          max={10}
                          className="inpooooo"
                          value={rowData.quantity}
                          onKeyDown={(e) => e.preventDefault()}
                          onChange={(value) => {
                            const newValue = Math.min(value, 10);
                            isAuth
                              ? handleReplaceQuantity(rowData._id, newValue)
                              : saveCartToLocalStorage(
                                  rowData._id,
                                  newValue,
                                  true
                                );
                          }}
                        />
                      )}
                    </Cell>
                  </Column>

                  <Column width={70}>
                    <HeaderCell>Actions</HeaderCell>
                    <Cell>
                      {(rowData) => (
                        <Button
                          className="delete-item-btn"
                          appearance="subtle"
                          onClick={() =>
                            isAuth
                              ? removeFromCart(rowData._id)
                              : removeProductFromLocalCart(rowData._id)
                          }
                        >
                          <FaTrashCan title="Delete" color="red" />
                        </Button>
                      )}
                    </Cell>
                  </Column>
                </Table>
              ) : (
                <Text weight="regular" align="center" size="lg">
                  Your cart is empty
                </Text>
              )}
            </Panel>
          </Col>
          <Col xs={24} lg={7}>
            <Panel className="checkout-panel">
              <Row className="cart-summary">
                <Col xs={24}>
                  <Row className="cart-summary-item">
                    <Col xs={12}>
                      <Text size="lg" align="left" weight="regular">
                        Cart Subtotal(₹)
                      </Text>
                    </Col>
                    <Col xs={12} className="text-right">
                      <Text size="lg" weight="medium">
                        <strong> {formatAmount(cart?.amount)}</strong>
                      </Text>
                    </Col>
                  </Row>
                  {/* <Row className="cart-summary-item">
                    <Col xs={12}>
                      <Text size="lg" align="left" weight="regular">
                        Delivery(₹)
                      </Text>
                    </Col>
                    <Col xs={12} className="text-right">
                      <Text size="lg" weight="medium">
                        Free
                      </Text>
                    </Col>
                  </Row> */}

                  <Row className="cart-summary-item">
                    <Col xs={12}>
                      <Text size="lg" align="left" weight="regular">
                        Tax(₹)
                      </Text>
                    </Col>
                    <Col xs={12} className="text-right">
                      <Text size="lg" weight="medium">
                        <strong>{cart.tax}</strong>
                      </Text>
                    </Col>
                  </Row>
                  <Row className="cart-summary-item">
                    <Col xs={12}>
                      <Text size="lg" align="left" weight="regular">
                        Charge(₹)
                      </Text>
                    </Col>
                    <Col xs={12} className="text-right">
                      <Text size="lg" weight="medium">
                        <strong>{formatAmount(cart?.charge)}</strong>
                      </Text>
                    </Col>
                  </Row>
                  <Row className="cart-summary-item">
                    <Col xs={12}>
                      <Text size="lg" align="left" weight="regular">
                        Discount(₹)
                      </Text>
                    </Col>
                    <Col xs={12} className="text-right">
                      <Text size="lg" weight="medium">
                        <strong>-{formatAmount(cart?.discount)}</strong>
                      </Text>
                    </Col>
                  </Row>
                  <Divider />
                  <Row className="cart-summary-item">
                    <Col xs={12}>
                      <Heading size="xxl" align="left" level={4}>
                        Cart Total(₹)
                      </Heading>
                    </Col>
                    <Col xs={12} className="text-right">
                      <Heading level={4}>{formatAmount(cart?.total)}</Heading>
                    </Col>
                  </Row>
                  <Button
                    appearance="primary"
                    className="check-btn btn-green"
                    block
                    onClick={handleCheckout}
                  >
                    <h5>Checkout</h5>
                  </Button>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Cart;
