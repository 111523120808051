import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Popover,
  Whisper,
  Stack,
  Badge,
  Avatar,
  IconButton,
  List,
  Button,
  Divider,
} from "rsuite";
import NoticeIcon from "@rsuite/icons/Notice";
import "./header.css";
import { useDispatch, useSelector } from "react-redux";
import {
  initNotification,
  selectAllNotifications,
  selectUnreadCount,
} from "../../stores/notificationSlice";
import { Link } from "react-router-dom";
import { clearAppData } from "../../stores/store";
import { timeDifference } from "../../utilities/timeDifference";
import notificationService from "../../services/notification.service";
import { trackPromise } from "react-promise-tracker";
import EnvConfig from "../../envConfig";
import { toast } from "react-toastify";
import { useSmallScreen } from "../../utilities/useWindowSize";

// Function to render Admin speaker
const renderAdminSpeaker =
  (user) =>
  ({ onClose, left, top, className }, ref) => {
    const handleSelect = (eventKey) => onClose();

    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <ul
          className="user-menu-link"
          style={{
            listStyle: "none",
            padding: "10px 30px 10px 10px",
            margin: 0,
          }}
        >
          <li>
            <span>
              <strong>
                {user.firstName} {user.lastName}
              </strong>
              <br />
              <p className="italic capitalize1">{user.role?.roleCode}</p>
            </span>
          </li>
          <hr />

          <li>
            <Link onClick={onClose} to="/profile/edit">
              Profile
            </Link>
          </li>
          <li>
            <Link onClick={onClose} to="/change-password">
              Change Password
            </Link>
          </li>
          <hr />
          <li>
            <Link onClick={clearAppData} to="/my-reviews">
              Sign Out
            </Link>
          </li>
        </ul>
      </Popover>
    );
  };

const renderNoticeSpeaker =
  (notifications, unreadCount, dispatch) =>
  ({ onClose, left, top, className }, ref) => {
    const [visibleCount, setVisibleCount] = useState(3);
    const [isScrollable, setIsScrollable] = useState(false);

    useEffect(() => {
      if (visibleCount > 5) {
        setIsScrollable(true);
      }
    }, [visibleCount]);

    const loadMore = () => {
      setVisibleCount((prevCount) => prevCount + 3);
    };

    const getPath = (noteType, noteOrigin) => {
      switch (noteType) {
        case "order":
          return `/orders/${noteOrigin}`;
        case "user":
          return `/users/${noteOrigin}`;
        case "contactMessage":
          return `/messages/${noteOrigin}`;
        case "productReview":
          return `/reviews/`;
        default:
          return "/";
      }
    };

    const handleClick = async (id) => {
      try {
        const resp = await trackPromise(notificationService.markRead(id));
        dispatch(initNotification({ notes: resp.data?.data || [] }));
      } catch (error) {
        toast.error(error.response.data.message);
        console.error("Failed to mark read", error);
      }
      onClose();
    };

    const isSmallScreen = useSmallScreen(768);

    return (
      <Popover
        ref={ref}
        className={className}
        style={{ left, top, width: isSmallScreen ? 240 : 300 }}
        title="Notifications"
      >
        <div
          style={{
            maxHeight: isScrollable ? "500px" : "auto",
            overflowY: isScrollable ? "scroll" : "visible",
          }}
        >
          <List>
            {notifications.slice(0, visibleCount).map((notification, index) => {
              return (
                <List.Item className="admin-notes" key={index}>
                  <Stack spacing={4}>
                    {!notification.isRead ? (
                      <Badge style={{ backgroundColor: "red" }} />
                    ) : (
                      ""
                    )}
                    <span>{timeDifference(notification.createdAt)}</span>
                  </Stack>
                  <Link
                    to={getPath(notification.noteType, notification.noteOrigin)}
                    state={{ noteOrigin: notification?.noteOrigin }}
                    onClick={() => handleClick(notification._id)}
                  >
                    <p className="noti-title">{notification.title}</p>
                  </Link>
                </List.Item>
              );
            })}
          </List>
          {visibleCount < notifications?.length && (
            <div className="loadnore">
              <Button onClick={loadMore}>More notifications</Button>
            </div>
          )}
        </div>
      </Popover>
    );
  };

// Header component
const Header = ({ user }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectAllNotifications);
  const unreadCount = useSelector(selectUnreadCount);
  const authState = useSelector((state) => state.authState);
  const appState = useSelector((state) => state.appState);

  return (
    <Stack className="header" spacing={8}>
      <div className="header-title">{appState.routeData.pageTitle}</div>
      <div className="header-icons">
        <Whisper
          placement="bottomEnd"
          trigger="click"
          speaker={renderNoticeSpeaker(notifications, unreadCount, dispatch)}
        >
          <IconButton
            icon={
              <Badge content={unreadCount}>
                <NoticeIcon style={{ fontSize: 20 }} />
              </Badge>
            }
          />
        </Whisper>

        <Whisper
          placement="bottomEnd"
          trigger="click"
          speaker={renderAdminSpeaker(user)}
        >
          <Avatar
            size="sm"
            circle
            src={`${EnvConfig.MediaBase}/${authState?.user?.assetsDir}/${authState?.user?.profileImage}`}
            alt="User Avatar"
            style={{ marginLeft: 8 }}
          />
        </Whisper>
      </div>
    </Stack>
  );
};

export default Header;
