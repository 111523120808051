import React, { useEffect, useState } from "react";
import { Col, Row, Panel } from "rsuite";
import "./productCard.css";
import { FaHeart } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { Link } from "react-router-dom";
import EnvConfig from "../../envConfig";
import { useCart } from "../../utilities/cart";
import { useSelector } from "react-redux";

const ProductCard = ({ product, slice }) => {
  const { cart, handleUpdateQuantity, saveCartToLocalStorage } = useCart();
  const authState = useSelector((state) => state.authState);
  const isAuth = authState.isAuthenticated;
  return (
    <Link to={`/products/${product._id}`}>
      <div className="product-card-container">
        <Panel className="product-card">
          <div className="product-image-container">
            <img
              src={`${EnvConfig.MediaBase}/${product.assetsDir}/${product.prodImages?.[0]}`}
              alt={product.name}
              className="product-image"
            />
            <div className="icons-container">
              {/* <div className="icon-wrapper">
                <FaHeart className="icon wishlist-icon" />
              </div> */}
              <button
                title="cart"
                onClick={(e) => {
                  e.preventDefault();
                  isAuth
                    ? handleUpdateQuantity(product._id)
                    : saveCartToLocalStorage(product._id);
                }}
                className="icon-wrapper"
              >
                <FaShoppingCart className="icon cart-icon" />
              </button>
            </div>
          </div>
          <Row className="product-info">
            <Col xs={24} className="product-category">
              {product?.size}
            </Col>
            <Col xs={24} className="product-names prod-hover">
              {product?.name.length > slice
                ? `${product.name.slice(0, slice)}...`
                : product.name}
            </Col>
            <Col xs={24} className="product-price">
              {product?.discountedPrice ? (
                <>
                  <span className="original-price">₹{product?.price}</span>
                  <span className="discounted-price">
                    ₹{product?.discountedPrice}
                  </span>
                </>
              ) : (
                <span>₹{product?.price}</span>
              )}
            </Col>
          </Row>
        </Panel>
      </div>
    </Link>
  );
};

export default ProductCard;
