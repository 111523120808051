import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Content,
  CustomProvider,
  Footer,
  Header,
  Row,
} from "rsuite";
import UserHeader from "../components/Header/UserHeader/UserHeader";
import "./userLayout.css";
import { Outlet } from "react-router";
import UserFooter from "../components/Footer/UserFooter";
import { useSelector } from "react-redux";

const UserLayout = ({ children }) => {
  const authState = useSelector((state) => state.authState);
  const appState = useSelector((state) => state.appState);
  const [theme, setTheme] = useState("light");

  const toggleTheme = (checked) => {
    setTheme(checked ? "light" : "dark");
  };

  const pageTitle = appState.routeData.pageTitle;

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <CustomProvider theme={theme}>
      <Container className="user-layout-container">
        <Header
          className={`header-not-sticky ${isSticky ? "header-sticky" : ""}`}
          style={
            pageTitle === "Home" || pageTitle === "About"
              ? {
                  position: "fixed",
                  backgroundColor: isSticky ? "white" : "transparent",
                }
              : {
                  position: "fixed",
                  backgroundColor: "white",
                }
          }
        >
          <UserHeader authState={authState} />
        </Header>
        <Content
          style={
            pageTitle !== "Home" && pageTitle !== "About"
              ? { marginTop: "5rem" }
              : {}
          }
        >
          <Outlet />
        </Content>
        <Footer>
          <UserFooter />
        </Footer>
      </Container>
    </CustomProvider>
  );
};

export default UserLayout;
