import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import {
  Modal,
  Button,
  Panel,
  Form,
  FlexboxGrid,
  Input,
  InputGroup,
  Tabs,
  Checkbox,
  Row,
  Col,
} from "rsuite";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import UserService from "../../../services/user.service";
import * as Yup from "yup";
import { useFormik } from "formik";
import cartService from "../../../services/cart.service";
import { setToken } from "../../../stores/authSlice";
import {
  clearAppData,
  clearCartFromLocalStorage,
  getUserCart,
  getUserProfile,
  LOCAL_KEY,
  setLocalData,
} from "../../../stores/store";
import { Link } from "react-router-dom";

const getValidationSchema = (isRegister, activeKey) => {
  return Yup.object().shape({
    email:
      activeKey === "email" || isRegister
        ? Yup.string().email("Invalid email").required("Email is required.")
        : Yup.string(),
    phone:
      activeKey === "phone" || isRegister
        ? Yup.string()
            .required("Phone number is required.")
            .length(10, "Phone number should be 10 digits")
        : Yup.string(),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required."),
    confirmPassword: isRegister
      ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password is required.")
      : Yup.string(),
    firstName: isRegister
      ? Yup.string().required("First name is required.")
      : Yup.string(),
    lastName: isRegister
      ? Yup.string().required("Last name is required.")
      : Yup.string(),
    termsAccepted: isRegister
      ? Yup.bool().oneOf([true], "You must accept the terms.")
      : Yup.bool(),
  });
};

const LoginModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [backdrop, setBackdrop] = useState("static");
  const [isRegister, setIsRegister] = useState(false);
  const [activeKey, setActiveKey] = useState("email");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleModalClose = () => {
    resetForm();
    setServerError("");
    onClose();
    setActiveKey("email");
  };

  useEffect(() => {
    if (open) {
      setIsRegister(false);
    }
  }, [open]);

  const { values, errors, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      termsAccepted: false,
    },
    validationSchema: getValidationSchema(isRegister, activeKey),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        if (isRegister) {
          setLoading(true);
          const resp = await trackPromise(UserService.register(values));
          if (resp.data.success) {
            setLoading(false);
            navigate("/");
            toast.success(resp.data.message);
            handleModalClose();
          }
        } else {
          const loginPayload =
            activeKey === "email"
              ? { email: values.email, password: values.password }
              : { phone: values.phone, password: values.password };

          const resp = await trackPromise(UserService.login(loginPayload));
          if (resp.data.success) {
            dispatch(setToken({ token: resp.data.data.token }));
            setLocalData({
              authToken: resp.data.data.token,
            });
            getUserProfile();
            const roleCode = await getUserProfile();
            if (roleCode !== "admin") {
              const localCartData = JSON.parse(localStorage.getItem(LOCAL_KEY));
              if (localCartData?.cart?.items.length > 0) {
                cartService.syncCart(localCartData.cart);
              }
              getUserCart();
            }
            resetForm();
            clearCartFromLocalStorage();
            handleModalClose();
            setServerError("");
            toast.success(resp.data.message);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
        setServerError(error.response.data.message);
        clearAppData();
      }
    },
  });

  const handleRegisterToggle = () => {
    setServerError("");
    resetForm();
    setIsRegister(!isRegister);
    setPasswordVisible(false);
  };

  const handleTabSelect = (key) => {
    setActiveKey(key);
    resetForm();
    setPasswordVisible(false);
    setServerError("");
  };

  return (
    <Modal
      backdrop={backdrop}
      open={!!open}
      onClose={handleModalClose}
      size={isRegister ? "sm" : "xs"}
    >
      <Modal.Header>
        <Modal.Title>{isRegister ? "Register" : "Login"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={24}>
            <Panel bordered>
              {isRegister ? (
                <Form fluid onSubmit={handleSubmit}>
                  <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={11}>
                      <Form.Group>
                        <Form.ControlLabel>First Name</Form.ControlLabel>
                        <Input
                          name="firstName"
                          value={values.firstName}
                          onChange={(value) =>
                            setFieldValue("firstName", value)
                          }
                        />
                        {errors.firstName && (
                          <div className="error-messaaage">
                            {errors.firstName}
                          </div>
                        )}
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={11}>
                      <Form.Group>
                        <Form.ControlLabel>Last Name</Form.ControlLabel>
                        <Input
                          name="lastName"
                          value={values.lastName}
                          onChange={(value) => setFieldValue("lastName", value)}
                        />
                        {errors.lastName && (
                          <div className="error-messaaage">
                            {errors.lastName}
                          </div>
                        )}
                      </Form.Group>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>

                  <Form.Group>
                    <Form.ControlLabel>Email</Form.ControlLabel>
                    <Input
                      name="email"
                      value={values.email}
                      onChange={(value) => setFieldValue("email", value)}
                    />
                    {errors.email && (
                      <div className="error-messaaage">{errors.email}</div>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Phone</Form.ControlLabel>
                    <Input
                      name="phone"
                      type="number"
                      value={values.phone}
                      onChange={(value) => setFieldValue("phone", value)}
                    />
                    {errors.phone && (
                      <div className="error-messaaage">{errors.phone}</div>
                    )}
                  </Form.Group>

                  <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={11}>
                      <Form.Group>
                        <Form.ControlLabel>Password</Form.ControlLabel>
                        <InputGroup>
                          <Input
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            value={values.password}
                            onChange={(value) =>
                              setFieldValue("password", value)
                            }
                          />
                          <InputGroup.Button
                            title={
                              passwordVisible
                                ? "hide password"
                                : "show password"
                            }
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                          </InputGroup.Button>
                        </InputGroup>
                        {errors.password && (
                          <div className="error-messaaage">
                            {errors.password}
                          </div>
                        )}
                      </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={11}>
                      <Form.Group>
                        <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                        <InputGroup>
                          <Input
                            type={passwordVisible ? "text" : "password"}
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onChange={(value) =>
                              setFieldValue("confirmPassword", value)
                            }
                          />
                          <InputGroup.Button
                            title={
                              passwordVisible
                                ? "hide password"
                                : "show password"
                            }
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                          </InputGroup.Button>
                        </InputGroup>
                        {errors.confirmPassword && (
                          <div className="error-messaaage">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </Form.Group>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>

                  <Form.Group>
                    <label className="check-flex">
                      <input
                        className="checkbox-log"
                        type="checkbox"
                        name="termsAccepted"
                        checked={values.termsAccepted || false}
                        onChange={(e) =>
                          setFieldValue("termsAccepted", e.target.checked)
                        }
                      />
                      I agree to
                      <Link
                        to="/terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="prod-color ml-5"
                      >
                        Ayurharmony Terms
                      </Link>
                    </label>
                    {errors.termsAccepted && (
                      <div className="error-messaaage">
                        {errors.termsAccepted}
                      </div>
                    )}
                  </Form.Group>

                  <Button
                    appearance="primary"
                    loading={loading}
                    disabled={loading ? true : false}
                    type="submit"
                    block
                    className="btn-green"
                  >
                    Register
                  </Button>
                </Form>
              ) : (
                <Tabs
                  className="login-tabs"
                  activeKey={activeKey}
                  onSelect={handleTabSelect}
                >
                  <Tabs.Tab eventKey="email" title="Email">
                    <Form fluid onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.ControlLabel>Email</Form.ControlLabel>
                        <Input
                          name="email"
                          value={values.email}
                          onChange={(value) => setFieldValue("email", value)}
                        />
                        {errors.email && (
                          <div className="error-messaaage">{errors.email}</div>
                        )}
                      </Form.Group>

                      <Form.Group>
                        <Form.ControlLabel>Password</Form.ControlLabel>
                        <InputGroup inside>
                          <Input
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            value={values.password}
                            onChange={(value) =>
                              setFieldValue("password", value)
                            }
                          />
                          <InputGroup.Button
                            title={
                              passwordVisible
                                ? "hide password"
                                : "show password"
                            }
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                          </InputGroup.Button>
                        </InputGroup>
                        {errors.password && (
                          <div className="error-messaaage">
                            {errors.password}
                          </div>
                        )}
                      </Form.Group>

                      <Button
                        className="btn-green"
                        appearance="primary"
                        type="submit"
                        block
                      >
                        Login
                      </Button>
                    </Form>
                  </Tabs.Tab>
                  <Tabs.Tab eventKey="phone" title="Phone">
                    <Form fluid onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.ControlLabel>Phone</Form.ControlLabel>
                        <Input
                          name="phone"
                          type="number"
                          value={values.phone}
                          onChange={(value) => setFieldValue("phone", value)}
                        />
                        {errors.phone && (
                          <div className="error-messaaage">{errors.phone}</div>
                        )}
                      </Form.Group>

                      <Form.Group>
                        <Form.ControlLabel>Password</Form.ControlLabel>
                        <InputGroup inside>
                          <Input
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            value={values.password}
                            onChange={(value) =>
                              setFieldValue("password", value)
                            }
                          />
                          <InputGroup.Button
                            title={
                              passwordVisible
                                ? "hide password"
                                : "show password"
                            }
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                          </InputGroup.Button>
                        </InputGroup>
                        {errors.password && (
                          <div className="error-messaaage">
                            {errors.password}
                          </div>
                        )}
                      </Form.Group>

                      <Button
                        className="btn-green"
                        appearance="primary"
                        type="submit"
                        block
                      >
                        Login
                      </Button>
                    </Form>
                  </Tabs.Tab>
                </Tabs>
              )}
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Body>
      <Modal.Footer>
        <Row className="register-btns">
          <Col xs={12} className="cancel-btn">
            <Button appearance="default" onClick={handleModalClose}>
              Cancel
            </Button>
          </Col>
          {isRegister ? (
            <Col xs={12}>
              <Button
                className="prod-color"
                onClick={() => handleRegisterToggle()}
                appearance="link"
              >
                {isRegister
                  ? "Already have an account? Log in"
                  : "Don't have an account? Register"}
              </Button>
            </Col>
          ) : (
            <Col xs={12}>
              {!isRegister && (
                <Link to="/forgot-password" onClick={handleModalClose}>
                  <Button appearance="link" color="red">
                    Forgot Password?
                  </Button>
                </Link>
              )}
            </Col>
          )}
        </Row>

        {serverError && <div className="error-messaaage">{serverError}</div>}

        {!isRegister ? (
          <Button
            className="prod-color"
            onClick={() => handleRegisterToggle()}
            appearance="link"
          >
            {isRegister
              ? "Already have an account? Log in"
              : "Don't have an account? Register"}
          </Button>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModal;
