import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import CartService from "../services/cart.service";
import { removeProduct, initCart } from "../stores/cartSlice";
import { toast } from "react-toastify";
import store, {
  getVisitorCart,
  LOCAL_KEY,
  setLocalData,
} from "../stores/store";

export const useCart = () => {
  const authState = useSelector((state) => state.authState);
  const isAuth = authState.isAuthenticated;
  const [localCart, setLocalCart] = useState([]);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const getCart = async () => {
    try {
      const resp = await trackPromise(CartService.cartOfUser());
      const cartData = resp.data.data;
      setLocalCart(cartData.items);
      dispatch(initCart(cartData));
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Error fetching cart:", error);
    }
  };

  const saveCartToLocalStorage = async (productId, newQuantity, replace) => {
    const cartString = localStorage.getItem(LOCAL_KEY);
    let cart = cartString ? JSON.parse(cartString).cart : { items: [] };

    const quantityToUpdate = newQuantity !== undefined ? newQuantity : 1;

    const existingProduct = cart.items.find(
      (item) => item.product === productId
    );

    if (existingProduct) {
      if (replace) {
        existingProduct.quantity = quantityToUpdate;
      } else {
        existingProduct.quantity += quantityToUpdate;
      }
    } else {
      cart.items.push({
        product: productId,
        quantity: quantityToUpdate,
      });
    }

    setLocalData({ cart });

    setLocalCart(cart.items);

    try {
      const resp = await trackPromise(CartService.cartOfVisitor(cart));
      toast.success("Product added to cart", {
        position: "bottom-right",
      });
      setLocalCart(resp.data.data.items);
      dispatch(initCart(resp.data.data));
    } catch (error) {
      console.error("Error updating cart:", error);
      toast.error(error.response.data.message);
    }
  };

  const removeProductFromLocalCart = (productId) => {
    const cartString = localStorage.getItem(LOCAL_KEY);
    let cartData = cartString ? JSON.parse(cartString).cart : { items: [] };

    cartData.items = cartData.items.filter(
      (item) => item.product !== productId
    );

    setLocalData({ cart: cartData });
    window.location.reload();
    setLocalCart(cartData.items);
  };

  const handleUpdateQuantity = async (productId, newQuantity) => {
    const currentProduct = localCart.find(
      (item) => item?.product?._id === productId
    );
    const currentQuantity = currentProduct?.quantity || 0;

    const quantityToUpdate =
      newQuantity !== undefined ? newQuantity : currentQuantity + 1;

    if (quantityToUpdate > 0) {
      try {
        const resp = await trackPromise(
          CartService.cartAlter({
            productId,
            quantity: quantityToUpdate - currentQuantity,
          })
        );
        const updatedCart = resp.data.data.items;
        setLocalCart(updatedCart);
        dispatch(initCart(resp.data.data));
        toast.success(resp.data.message);
      } catch (error) {
        toast.error(error.response?.data?.message);
      }
    } else {
      removeFromCart(productId);
    }
  };

  const handleReplaceQuantity = async (productId, quantity) => {
    try {
      const resp = await trackPromise(
        CartService.cartAlter({ productId, quantity, replaceQty: true })
      );
      const updatedCart = resp.data.data.items;
      setLocalCart(updatedCart);
      dispatch(initCart(resp.data.data));
      toast.success(resp.data.message);
    } catch (error) {
      console.error(error);
      toast.error(error.respoonse.data.message);
    }
  };

  const removeFromCart = async (productId) => {
    try {
      const resp = await trackPromise(CartService.removeProduct(productId));
      dispatch(initCart(resp.data.data));
      toast.success("Item deleted successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return {
    cart,
    localCart,
    handleUpdateQuantity,
    handleReplaceQuantity,
    removeFromCart,
    getCart,
    saveCartToLocalStorage,
    removeProductFromLocalCart,
  };
};
