// src/components/ReviewDetailsModal.js
import React from "react";
import { Modal, Button, Rate } from "rsuite";
import "./reviews.css";
import { formatDate } from "../../utilities/formatDate";

const ReviewDetailsModal = ({
  review,
  isOpen,
  onClose,
  onIgnoreReview,
  dontShowActions,
  admin,
}) => {
  console.log("jbc", admin);
  if (!review) return null;

  return (
    <Modal open={isOpen} onClose={onClose} className="review-details-modal">
      <Modal.Header>
        <Modal.Title>
          <strong>Review Details</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="detail-container">
          <div className="detail-item">
            <span className={`${!admin ? "detail-value-rating" : "rrrat"}`}>
              <Rate color="green" size="sm" value={review.rating} readOnly />
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-key">Product Name:</span>
            <span className="detail-value">
              {review.product?.name || "N/A"}
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-value">
              <strong>{review.reviewTitle}</strong>
            </span>
          </div>
          <div className="detail-itemmn">
            <div
              dangerouslySetInnerHTML={{ __html: review.reviewDescription }}
            />
          </div>
          {!dontShowActions && (
            <div className="detail-item">
              <span className="detail-key">Status:</span>
              <span className="detail-value">
                {review.isActive ? "Active" : "Ignored"}
              </span>
            </div>
          )}
        </div>
      </Modal.Body>
      {!dontShowActions ? (
        <Modal.Footer>
          <Button onClick={() => onIgnoreReview(review)} appearance="primary">
            {review.isActive ? "Ignore" : "Activate"} Review
          </Button>
          <Button onClick={onClose} appearance="default">
            Close
          </Button>
        </Modal.Footer>
      ) : (
        <div className="reviews-footer">
          <div className="reviewer-info">
            <div>
              {review?.user?.firstName} {review?.user?.lastName} -{" "}
              {formatDate(review?.updatedAt)}
            </div>
          </div>
          {review?.hadOrdered && (
            <div className="certified-buyer">
              <span>Certified Buyer</span>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ReviewDetailsModal;
