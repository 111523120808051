import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  DateRangePicker,
  Loader,
  Modal,
  Pagination,
  Tabs,
  IconButton,
  Affix,
} from "rsuite";
import "./messages.css"; // Import your CSS file if needed
import { trackPromise } from "react-promise-tracker";
import messageService from "../../services/message.service";
import { formatDateTime } from "../../utilities/formatDate";
import { setRouteData } from "../../stores/appSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FaThList } from "react-icons/fa";
import TrashIcon from "@rsuite/icons/Trash";
import DeleteModal from "../../components/DeleteModal/Delete.Modal";
import useWindowSize, {
  isSmallScreen,
  useSmallScreen,
} from "../../utilities/useWindowSize";

const { Column, HeaderCell, Cell } = Table;

const Messages = ({ pageTitle }) => {
  const [messages, setMessages] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [unresolvedPage, setUnresolvedPage] = useState(1);
  const [unresolvedLimit, setUnresolvedLimit] = useState(5);
  const [resolvedLimit, setResolvedLimit] = useState(5);
  const [resolvedPage, setResolvedPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("unresolved");
  const dispatch = useDispatch();
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleOpenModal = (id) => {
    setIsModalOpen(true);
    setSelectedItemId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  useEffect(() => {
    const now = new Date();
    const startDate = new Date(now);
    startDate.setDate(now.getDate() - 30);
    setDateRange([startDate, now]);
    fetchMessagesByDateRange([startDate, now]);
  }, []);

  const fetchMessagesByDateRange = async (range) => {
    if (!range[0] || !range[1]) return;

    try {
      const startDate = new Date(range[0].setHours(0, 0, 0, 0)).toISOString();
      const endDate = new Date(
        range[1].setHours(23, 59, 59, 999)
      ).toISOString();
      const response = await trackPromise(
        messageService.getMessagesByDates({ startDate, endDate })
      );
      setMessages(response.data.data);
      setTotalMessages(response.data.data.length);
    } catch (error) {
      console.error(
        error.response.data.message ||
          "Failed to fetch messages by date range:",
        error
      );
    }
  };

  const handleDateRangeChange = (value) => {
    setDateRange(value);
    if (value?.[0] && value?.[1]) {
      fetchMessagesByDateRange(value);
    }
  };

  const handleDeleteMessage = async (id) => {
    try {
      const resp = await trackPromise(messageService.deleteMessage(id));
      toast.success(resp.data.message);
      fetchMessagesByDateRange(dateRange);
      setIsModalOpen(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handlePageChange = (newPage) => {
    if (selectedTab === "unresolved") {
      setUnresolvedPage(newPage);
    } else {
      setResolvedPage(newPage);
    }
  };

  const handleLimitChange = (newLimit) => {
    if (selectedTab === "unresolved") {
      setUnresolvedLimit(newLimit);
      setUnresolvedPage(1);
    } else {
      setResolvedLimit(newLimit);
      setResolvedPage(1);
    }
  };

  const filteredMessages = messages
    .filter((message) =>
      selectedTab === "resolved" ? message.isResolved : !message.isResolved
    )
    .reverse();

  const paginatedMessages =
    selectedTab === "unresolved"
      ? filteredMessages.slice(
          (unresolvedPage - 1) * unresolvedLimit,
          unresolvedPage * unresolvedLimit
        )
      : filteredMessages.slice(
          (resolvedPage - 1) * resolvedLimit,
          resolvedPage * resolvedLimit
        );

  const isSmallScreen = useSmallScreen(768);

  return (
    <div className="message-bg">
      <Col xs={24} className="messages-container">
        <Container className="messages-page">
          <Row className="date-butns">
            <Affix>
              <Col
                xs={6}
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              >
                <DateRangePicker
                  placeholder="Select Date Range"
                  onChange={handleDateRangeChange}
                  value={dateRange}
                />
              </Col>
            </Affix>
          </Row>
          <Row>
            <Col xs={18}>
              <Tabs activeKey={selectedTab} onSelect={setSelectedTab}>
                <Tabs.Tab
                  eventKey="unresolved"
                  title={`Unresolved (${
                    messages.filter((msg) => !msg.isResolved).length
                  })`}
                />
                <Tabs.Tab
                  eventKey="resolved"
                  title={`Resolved (${
                    messages.filter((msg) => msg.isResolved).length
                  })`}
                />
              </Tabs>
            </Col>
          </Row>

          <Row>
            <Table
              affixHeader={50}
              wordWrap="break-word"
              data={paginatedMessages}
              autoHeight
            >
              <Column width={150}>
                <HeaderCell>Sender</HeaderCell>
                <Cell dataKey="senderName" />
              </Column>
              <Column width={200}>
                <HeaderCell>Subject</HeaderCell>
                <Cell dataKey="subject" />
              </Column>
              <Column width={350}>
                <HeaderCell>Message</HeaderCell>
                <Cell dataKey="message">
                  {(rowData) =>
                    rowData.message.length > 100
                      ? `${rowData.message.slice(0, 120)}...`
                      : rowData.message
                  }
                </Cell>
              </Column>
              <Column width={250}>
                <HeaderCell>Date</HeaderCell>
                <Cell>{(rowData) => formatDateTime(rowData.createdAt)}</Cell>
              </Column>
              <Column width={150}>
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div className="action-icons">
                      <Link to={`/messages/${rowData._id}`}>
                        <IconButton
                          title="details"
                          icon={<FaThList className="icon-blue" />}
                        />
                      </Link>
                      <Link to="#" onClick={() => handleOpenModal(rowData._id)}>
                        <IconButton
                          title="delete"
                          icon={<TrashIcon color="red" />}
                        />
                      </Link>
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
          </Row>

          <Row>
            <div className="pagination-0">
              <Pagination
                activePage={
                  selectedTab === "unresolved" ? unresolvedPage : resolvedPage
                }
                total={filteredMessages.length}
                limit={
                  selectedTab === "unresolved" ? unresolvedLimit : resolvedLimit
                }
                onChangePage={handlePageChange}
                onChangeLimit={handleLimitChange}
                size={isSmallScreen ? "xs" : "md"}
                layout={[
                  "total",
                  "-",
                  `${!isSmallScreen ? "limit" : ""}`,
                  `${!isSmallScreen ? "|" : ""}`,
                  "pager",
                  `${!isSmallScreen ? "|" : ""}`,
                  `${!isSmallScreen ? "skip" : ""}`,
                ]}
                limitOptions={[5, 10, 20, 50]}
                prev
                next
                first
                last
                ellipsis
                maxButtons={5}
                boundaryLinks
              />
            </div>
          </Row>
          {isModalOpen && (
            <DeleteModal
              itemId={selectedItemId}
              onClose={handleCloseModal}
              isOpen={handleOpenModal}
              onDelete={handleDeleteMessage}
            />
          )}
        </Container>
      </Col>
    </div>
  );
};

export default Messages;
