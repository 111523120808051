import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Divider,
  Heading,
  Schema,
  Input,
} from "rsuite";
import "./contact.css";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../../stores/appSlice";
import ScrollToTop from "../../../utilities/ScrollToTop";
import { trackPromise } from "react-promise-tracker";
import messageService from "../../../services/message.service";
import { toast } from "react-toastify";

const { StringType, Required } = Schema.Types;

const validationSchema = Schema.Model({
  name: StringType().isRequired("Name is required."),
  email: StringType()
    .isEmail("Please enter a valid email.")
    .isRequired("Email is required."),
  subject: StringType().isRequired("Subject is required."),
  message: StringType().isRequired("Message is required."),
});

const Contact = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  const handleChange = (value, name) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const payload = {
      senderName: formValues.name,
      senderEmail: formValues.email,
      subject: formValues.subject,
      message: formValues.message,
    };

    try {
      const resp = await trackPromise(messageService.sendMessage(payload));
      toast.success(resp?.data?.message || "Message posted successfully");

      setFormValues({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (err) {
      toast.error(err?.respoonse?.data?.message);
    }
  };

  return (
    <Container className="contact-container">
      <ScrollToTop />
      <Col xs={22} lg={20} className="contact">
        <Row gutter={30}>
          <Col xs={24} lg={12} className="coon-tact">
            <Row>
              <Col xs={24} md={12} lg={24}>
                <div className="contact-details">
                  <h3>Contact Details</h3>
                  <div className="contact-item">
                    <div>
                      <h5>Our Location</h5>
                      <p>
                        S.No. 44/2A, Plot No. 14,
                        <br /> T1 - Saraswati Sadan,
                        <br /> Opposite Satralkar Sqaure,
                        <br /> Wanleshwadi, Sangli 416416
                      </p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <div>
                      <h5>Call Us</h5>
                      <p>+91-9922218614</p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <div>
                      <h5>Our Email</h5>
                      <p>support@ayuharmony.com</p>
                    </div>
                  </div>
                </div>{" "}
              </Col>
              <Col xs={24} md={12} lg={24} className="contact-form">
                <h3>Send Us a Message</h3>
                <Form
                  fluid
                  onSubmit={(checkStatus, event) => {
                    if (checkStatus) handleSubmit();
                  }}
                  model={validationSchema}
                  formValue={formValues}
                  onChange={setFormValues}
                >
                  <Form.Group>
                    <Form.ControlLabel>Name</Form.ControlLabel>
                    <Form.Control
                      name="name"
                      placeholder="Your Name"
                      value={formValues.name}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Email</Form.ControlLabel>
                    <Form.Control
                      name="email"
                      placeholder="Your Email"
                      value={formValues.email}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Subject</Form.ControlLabel>
                    <Form.Control
                      name="subject"
                      placeholder="Subject"
                      value={formValues.subject}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Message</Form.ControlLabel>
                    <textarea
                      name="message"
                      rows={5}
                      placeholder="Your message"
                      value={formValues.message}
                      onChange={(e) => handleChange(e.target.value, "message")}
                      className="rs-input"
                    />
                  </Form.Group>

                  <Button
                    appearance="primary"
                    className="btn-green"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={12} className="map">
            <Heading level={4}>Find Us</Heading>
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.052569551706!2d73.03579507470556!3d19.01740505381035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3ac8aae5db3%3A0x12d5ac1a9e62cd2f!2sPeracto%20Infotech!5e0!3m2!1sen!2sin!4v1724907288915!5m2!1sen!2sin"
                style={{ border: 0 }}
                allowFullScreen=""
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default Contact;
