import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Pagination,
  IconButton,
  InputGroup,
  Input,
  Modal,
  Button,
  Affix,
} from "rsuite";
import { trackPromise } from "react-promise-tracker";
import invoiceService from "../../services/invoice.service";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FaThList } from "react-icons/fa";
import TrashIcon from "@rsuite/icons/Trash";
import DeleteModal from "../../components/DeleteModal/Delete.Modal";
import useWindowSize, { useSmallScreen } from "../../utilities/useWindowSize";
import { setRouteData } from "../../stores/appSlice";
import { formatDate, formatDateTime } from "../../utilities/formatDate";
import { formatAmount } from "../../utilities/formatAmount";
import EditIcon from "@rsuite/icons/Edit";

const { Column, HeaderCell, Cell } = Table;

const Invoice = ({ pageTitle }) => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryName, setSearchQueryName] = useState("");
  const dispatch = useDispatch();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [newStatus, setNewStatus] = useState("");

  const handleOpenStatusModal = (invoice) => {
    setSelectedInvoice(invoice);
    setIsStatusModalOpen(true);
  };

  const handleCloseStatusModal = () => {
    setIsStatusModalOpen(false);
    setSelectedInvoice(null);
    setNewStatus("");
  };

  const handleStatusUpdate = async () => {
    try {
      if (selectedInvoice) {
        const resp = await invoiceService.updateInvoice(selectedInvoice._id, {
          status: selectedInvoice.status === "paid" ? "pending" : "paid",
        });
        toast.success(resp.data.message);
        fetchInvoices();
        handleCloseStatusModal();
      } else {
        toast.error("Please select a valid status");
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to update status:", error);
    }
  };

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const response = await trackPromise(invoiceService.invoices());
      const data = response.data.data;
      setInvoices(data);
      setFilteredInvoices(data);
      setTotalInvoices(data.length);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch invoices:", error);
    }
  };

  const handlePageChange = (newPage) => setPage(newPage);
  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  useEffect(() => {
    const lowerCasedFilterInvoice = searchQuery.toLowerCase();
    const lowerCasedFilterName = searchQueryName.toLowerCase();

    const filteredData = invoices.filter(
      (invoice) =>
        (lowerCasedFilterInvoice === "" ||
          invoice?.invoiceId
            ?.toLowerCase()
            .includes(lowerCasedFilterInvoice)) &&
        (lowerCasedFilterName === "" ||
          `${invoice.user.firstName} ${invoice.user.lastName}`
            .toLowerCase()
            .includes(lowerCasedFilterName))
    );

    setFilteredInvoices(filteredData);
  }, [searchQuery, searchQueryName, invoices]);

  const paginatedInvoices = filteredInvoices.slice(
    (page - 1) * limit,
    page * limit
  );

  const isSmallScreen = useSmallScreen(768);

  return (
    <div className="message-bg">
      <Col xs={24} className="messages-container">
        <Affix>
          <div
            className="search-flex"
            style={{
              width: "100%",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Col xs={6} className="custom-search-tabs">
              <InputGroup inside style={{ marginBottom: 10 }}>
                <Input
                  placeholder="Search by Invoice ID"
                  value={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                />
              </InputGroup>
            </Col>
            <Col xs={6} className="custom-search-tabs">
              <InputGroup inside style={{ marginBottom: 10 }}>
                <Input
                  placeholder="Search by User name"
                  value={searchQueryName}
                  onChange={(value) => setSearchQueryName(value)}
                />
              </InputGroup>
            </Col>
          </div>
        </Affix>
        <Container className="messages-page">
          <Row>
            <Table
              affixHeader={60}
              wordWrap="break-word"
              data={paginatedInvoices}
              autoHeight
            >
              <Column width={200}>
                <HeaderCell>Invoice Id</HeaderCell>
                <Cell dataKey="_id">
                  {(rowData) => (
                    <Link to={`/invoices/${rowData._id}`}>
                      {rowData.invoiceId}
                    </Link>
                  )}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>Order Id</HeaderCell>
                <Cell dataKey="_id">
                  {(rowData) => (
                    <Link to={`/orders/${rowData.order._id}`}>
                      {rowData.order.orderId}
                    </Link>
                  )}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>User</HeaderCell>
                <Cell>
                  {(rowData) =>
                    `${rowData.user.firstName} ${rowData.user.lastName}`
                  }
                </Cell>
              </Column>

              <Column width={140}>
                <HeaderCell>Mobile</HeaderCell>
                <Cell dataKey="user.phone" />
              </Column>
              <Column width={200}>
                <HeaderCell>Order Date</HeaderCell>
                <Cell>
                  {(rowData) => {
                    const date = formatDateTime(rowData.createdAt);
                    return date;
                  }}
                </Cell>
              </Column>
              <Column width={150}>
                <HeaderCell>Product Count</HeaderCell>
                <Cell>{(rowData) => rowData.items.length}</Cell>
              </Column>
              <Column width={150}>
                <HeaderCell>City</HeaderCell>
                <Cell>
                  {(rowData) => rowData?.order?.deliveryAddress?.city}
                </Cell>
              </Column>
              <Column width={150}>
                <HeaderCell>Delivery Date</HeaderCell>
                <Cell>
                  {(rowData) =>
                    rowData?.order?.deliveryDate
                      ? formatDate(rowData?.order?.deliveryDate)
                      : " - "
                  }
                </Cell>
              </Column>
              <Column width={150}>
                <HeaderCell>Total Amount</HeaderCell>
                <Cell dataKey="total">
                  {(rowData) => formatAmount(rowData?.total)}
                </Cell>
              </Column>
              {/* <Column width={150}>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="status" />
              </Column> */}
              <Column width={150}>
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div className="action-icons">
                      <Link to={`/invoices/${rowData._id}`}>
                        <IconButton
                          title="details"
                          icon={<FaThList className="icon-blue" />}
                        />
                      </Link>
                      {/* <IconButton
                        icon={<EditIcon />} // Use an edit icon here
                        onClick={() => handleOpenStatusModal(rowData)} // Open the modal for this invoice
                      /> */}
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
          </Row>

          <Row>
            <div className="pagination-0">
              <Pagination
                activePage={page}
                total={filteredInvoices.length}
                limit={limit}
                onChangePage={handlePageChange}
                onChangeLimit={handleLimitChange}
                size="md"
                layout={[
                  "total",
                  "-",
                  `${!isSmallScreen ? "limit" : ""}`,
                  `${!isSmallScreen ? "|" : ""}`,
                  "pager",
                  `${!isSmallScreen ? "|" : ""}`,
                  `${!isSmallScreen ? "skip" : ""}`,
                ]}
                limitOptions={[5, 10, 20, 50]}
                prev
                next
                first
                last
                ellipsis
                maxButtons={5}
                boundaryLinks
              />
            </div>
          </Row>
        </Container>
      </Col>

      <Modal open={isStatusModalOpen} onClose={handleCloseStatusModal}>
        <Modal.Header>
          <Modal.Title>Update Invoice Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Current Status: {selectedInvoice?.status}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleStatusUpdate} appearance="primary">
            {selectedInvoice?.status === "paid"
              ? "Update Status to Pending"
              : "Update Status to Paid"}
          </Button>
          <Button onClick={handleCloseStatusModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Invoice;
