import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Panel,
  Rate,
  Button,
  InputNumber,
  Tabs,
  Input,
  FlexboxGrid,
  SelectPicker,
} from "rsuite";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductsService from "../../services/products.service";
import ProductGallery from "../../components/ProductGallery/ProductGallery";
import "./products.css";
import { setRouteData } from "../../stores/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import ScrollToTop from "../../utilities/ScrollToTop";
import ReviewCarddo from "../../components/ReviewCard/newReviewCard";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import { toast } from "react-toastify";

const ProductDetail = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const authState = useSelector((state) => state.authState);
  const isAuth = authState.isAuthenticated;
  const navigate = useNavigate();

  const reviewsRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    if (id) fetchProduct();
  }, [id]);

  async function fetchProduct() {
    try {
      const resp = await trackPromise(ProductsService.getProductById(id));
      const { data } = resp;
      if (data.success) {
        setProduct(data.data);
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error("Fetch product details catch => ", err);
    }
  }

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleReviewLinkClick = (e) => {
    e.preventDefault();
    setActiveTab("2");
    if (reviewsRef.current) {
      reviewsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const allReviews = product?.reviews;

  const filteredReviews = allReviews
    ?.filter(
      (review) =>
        review.reviewTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
        review.reviewDescription
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === "highest") {
        return b.rating - a.rating;
      } else if (sortOrder === "lowest") {
        return a.rating - b.rating;
      }
      return 0;
    });

  const reviewFilterData = [
    { value: "highest", label: "Highest Rating" },
    { value: "lowest", label: "Lowest Rating" },
  ];

  return (
    <Container className="product-bg">
      <ScrollToTop />
      <Col xs={24} className="product">
        <Row className="bg">
          <FlexboxGrid justify="end" className="mt-50">
            <FlexboxGrid.Item>
              <Button
                onClick={() => navigate(-1)}
                appearance="ghost"
                color="blue"
              >
                <ArowBackIcon />
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <div className="product-container">
            <Col xs={24} md={12}>
              <div className="image-container">
                <ProductGallery
                  images={product?.prodImages}
                  assetsDir={product?.assetsDir}
                />
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="details-section">
                <div className="product-name">{product?.name}</div>
                <div className="price">
                  <div className="product-rating">
                    <strong className="rating-text">
                      Avg Rating: (
                      {`${product?.averageRating?.toFixed(1) || 0}`})
                    </strong>
                    <Rate
                      className="rate-stars"
                      value={product?.averageRating || 0}
                      readOnly
                      style={{ marginRight: 8, color: "#f5a623" }}
                    />
                    <span className="review-count-text">
                      <Link to="#" onClick={handleReviewLinkClick}>
                        {` ${product?.reviewCount || 0} ${
                          product?.reviewCount === 1 ? "review" : "reviews"
                        }`}
                      </Link>
                    </span>
                  </div>
                </div>

                <div className="price">
                  <span className="detail-title">Price: </span>
                  <strong className="detail-value">
                    ₹ {product?.price} + Free Shipping
                  </strong>
                </div>

                <div className="price">
                  <span className="detail-title">Size: </span>
                  <strong className="detail-value">{product?.size}</strong>
                </div>

                <div className="price">
                  <span className="detail-title">Category: </span>
                  <strong className="detail-value">
                    {product?.subCategory?.category?.categoryName}
                  </strong>
                </div>

                <div className="price">
                  <span className="detail-title">Sub-Category: </span>
                  <strong className="detail-value">
                    {product?.subCategory?.subCatName}
                  </strong>
                </div>
              </div>
              {!product.isDeleted && (
                <Col xs={18} md={12}>
                  <Link to={`/product/edit/${product._id}`}>
                    <Button appearance="primary" className="add-to-cart-button">
                      Edit Product
                    </Button>
                  </Link>
                </Col>
              )}
            </Col>
          </div>
          <Col xs={24} className="description">
            <Tabs
              onSelect={(key) => setActiveTab(key)}
              defaultActiveKey="1"
              activeKey={activeTab}
              appearance="subtle"
            >
              <Tabs.Tab eventKey="1" title="Description">
                <div className="description-content">
                  <div className="description-head">Description</div>
                  <div
                    dangerouslySetInnerHTML={{ __html: product?.description }}
                  ></div>
                </div>
              </Tabs.Tab>
              <Tabs.Tab eventKey="2" title="Reviews">
                <div className="reviews-controls">
                  <Input
                    placeholder="Search reviews"
                    value={searchQuery}
                    onChange={(value) => setSearchQuery(value)}
                    style={{ width: 200, marginRight: 10 }}
                  />
                  <SelectPicker
                    data={reviewFilterData}
                    searchable={false}
                    onChange={(value) => setSortOrder(value)}
                  />
                </div>
                <div className="reviews-section">
                  {filteredReviews?.slice(0, 3)?.map((review) => (
                    <ReviewCarddo key={review._id} review={review} />
                  ))}
                  {filteredReviews?.length > 3 ? (
                    <Link to={`/all-reviews/${product?._id}`}>
                      <Button appearance="primary" color="green">
                        View more
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </Tabs.Tab>
            </Tabs>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default ProductDetail;
