import React, { useState } from "react";
import { Modal, Button } from "rsuite";
import { trackPromise } from "react-promise-tracker";
import CategoryService from "../../services/Category.Service";
import { toast } from "react-toastify";

const CategoryDeleteModal = ({
  itemId,
  isOpen,
  onClose,
  fetch,
  status,
  disable,
}) => {
  const [message, setMessage] = useState("");
  const [warning, setWarning] = useState("");
  const [consentRequired, setConsentRequired] = useState(false);

  const deleteCategory = async () => {
    try {
      const resp = await trackPromise(CategoryService.deleteCategory(itemId));
      const { data } = resp.data;
      setMessage(data.message);

      if (data?.consentRequired) {
        setConsentRequired(true);
      } else {
        toast.success(resp.data.message || "Category deleted successfully");
        fetch();
        onClose();
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setWarning(error.response.data.message);
      console.error("Delete error:", error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const resp = await trackPromise(
        CategoryService.deleteCategory(itemId, { consentGranted: true })
      );
      toast.success(resp.data.message);
      onClose();
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error(error.response.data.message);
    }
  };

  const disableCategory = async () => {
    try {
      const resp = await trackPromise(
        CategoryService.changeStatus(itemId, { status: !status })
      );
      toast.success(resp.data.message);
      setMessage(resp.data.message);
      fetch();
      onClose();
    } catch (error) {
      console.error("Disable error:", error);
      toast.error(error.response.data.message);
      setWarning(error.response.data.message);
    }
  };
  return (
    <Modal open={!!isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>
          {disable ? (!status ? "Enable" : "Disable") : "Delete Category"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            {message ||
              (disable
                ? `Are you sure you want to ${
                    !status ? "enable" : "disable"
                  } this category?`
                : "Are you sure you want to delete this category?")}
          </p>
          <p className="error-message">{warning}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {disable ? (
          <Button
            onClick={disableCategory}
            appearance="primary"
            color={status ? "red" : "blue"}
          >
            {status ? "Disable" : "Enable"}
          </Button>
        ) : (
          <Button
            onClick={() => {
              if (consentRequired) {
                handleConfirmDelete();
              } else {
                deleteCategory(itemId);
              }
            }}
            appearance="primary"
            color="red"
          >
            {consentRequired ? "Confirm Delete" : "Delete"}
          </Button>
        )}
        <Button onClick={onClose} appearance="default">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CategoryDeleteModal;
