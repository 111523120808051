import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  Content,
  Panel,
  List,
  FlexboxGrid,
  Row,
  Col,
  Button,
  Divider,
  Table,
} from "rsuite";
import { useNavigate, useParams } from "react-router";
import jsPDF from "jspdf";
import "jspdf-autotable";
import invoiceService from "../../services/invoice.service";
import EnvConfig from "../../envConfig";
import ScrollToTop from "../../utilities/ScrollToTop";
import "./invoice.css";
import { generateInvoicePDF } from "../../utilities/generateInvoice";
import { useSelector } from "react-redux";
import { formatDate } from "../../utilities/formatDate";
import { Link } from "react-router-dom";
import Column from "rsuite/esm/Table/TableColumn";
import { Cell, HeaderCell } from "rsuite-table";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import { formatAmount } from "../../utilities/formatAmount";
import { toast } from "react-toastify";

const ImageCell = ({ rowData, ...props }) => {
  return (
    <Cell {...props} className="image-cell gdavsx">
      <Link to={`/products/${rowData?.product?._id}`}>
        <img
          src={`${EnvConfig?.MediaBase}/${rowData?.product?.assetsDir}/${rowData?.product?.prodImages?.[0]}`}
          alt="Product"
        />
      </Link>
    </Cell>
  );
};

const InvoiceDetails = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState();
  const authState = useSelector((state) => state.authState);
  const adminAddress = authState?.user?.addresses?.[0];
  const navigate = useNavigate();

  const getInvoiceDetails = async () => {
    try {
      const resp = await invoiceService.invoiceById(id);
      setInvoice(resp.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch invoice details:", error);
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, [id]);

  return (
    <Container className="message-detail">
      <ScrollToTop />
      <Row>
        <Col xs={23} className="message-details-container">
          <FlexboxGrid
            justify="space-between"
            align="middle"
            className="d-b-btn"
          >
            <FlexboxGrid.Item>
              <button
                onClick={() => generateInvoicePDF(invoice)}
                className="down-button"
              >
                Download PDF
              </button>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <Button
                onClick={() => navigate("/invoices")}
                appearance="ghost"
                color="blue"
              >
                <ArowBackIcon />
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <FlexboxGrid className="address-total" justify="space-between">
            <FlexboxGrid.Item as={Col} md={5} colspan={12}>
              <h4 className="order-det-head">Billing Address</h4>
              <div className="invoice-address">
                <p>
                  <strong>
                    {adminAddress?.city} {adminAddress?.state}{" "}
                    {adminAddress?.pincode}
                  </strong>
                </p>
                <p>
                  <strong>{adminAddress?.lane}</strong>
                </p>
                <p>
                  <strong>{adminAddress?.landmark}</strong>
                </p>
                <p>
                  <strong>{adminAddress?.phone}</strong>
                </p>
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} md={5} colspan={12}>
              <h4 className="order-det-head">Sold by</h4>
              <div className="invoice-address">
                <p>
                  <strong>
                    {invoice?.order?.deliveryAddress?.city}{" "}
                    {invoice?.order?.deliveryAddress?.state}{" "}
                    {invoice?.order?.deliveryAddress?.pincode}
                  </strong>
                </p>
                <p>
                  <strong>{invoice?.order?.deliveryAddress?.lane}</strong>
                </p>
                <p>
                  <strong>{invoice?.order?.deliveryAddress?.landmark}</strong>
                </p>
                <p>
                  <strong>{invoice?.order?.deliveryAddress?.phone}</strong>
                </p>
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} md={8} colspan={24} className="dets-2i">
              <h4 className="order-det-head">Invoice Details</h4>
              <Row>
                <Row>
                  <Col xs={7} className="order-det-body">
                    <p>Invoice Id</p>
                  </Col>
                  <Col>
                    <p>
                      <strong>{invoice?.invoiceId}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={7} className="order-det-body">
                    <p>Invoice Date</p>
                  </Col>
                  <Col>
                    <p>
                      <strong>{formatDate(invoice?.createdAt)}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={7} className="order-det-body">
                    <p>Order Id</p>
                  </Col>
                  <Col>
                    <p>
                      <strong>
                        <Link to={`/orders/${invoice?.order?._id}`}>
                          {invoice?.order?.orderId}
                        </Link>
                      </strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={7} className="order-det-body">
                    <p>Order Date</p>
                  </Col>
                  <Col>
                    <p>
                      <strong>{formatDate(invoice?.order?.orderDate)}</strong>
                    </p>
                  </Col>
                </Row>
              </Row>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} md={4} colspan={12}>
              <h4 className="order-det-head">Calculations</h4>
              <Row>
                <Col xs={16}>
                  <p className="invoice-details-head">Subtotal (₹)</p>
                </Col>
                <Col xs={7} className="total-check">
                  <p className="invoice-details-prod total-check">
                    <strong>{formatAmount(invoice?.amount)}</strong>
                  </p>
                </Col>
              </Row>

              <Row>
                <Col xs={16}>
                  <p className="invoice-details-head">Tax (₹)</p>
                </Col>
                <Col xs={7} className="total-check">
                  <p className="invoice-details-prod">
                    <strong>{formatAmount(invoice?.tax)}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={16}>
                  <p className="invoice-details-head">Charge (₹)</p>
                </Col>
                <Col xs={7} className="total-check">
                  <p className="invoice-details-prod">
                    <strong>{formatAmount(invoice?.charge)}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={16}>
                  <p className="invoice-details-head">Discount (₹)</p>
                </Col>
                <Col xs={7} className="total-check">
                  <p className="invoice-details-prod">
                    <strong>-{formatAmount(invoice?.discount)}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={16}>
                  <p className="invoice-details-head">Total (₹)</p>
                </Col>
                <Col xs={7} className="total-check">
                  <p className="invoice-details-prod">
                    <strong>{formatAmount(invoice?.total)}</strong>
                  </p>
                </Col>
              </Row>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Col>
        <Col xs={23} className="message-details-container">
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item colspan={24}>
              <Panel>
                <h4 className="order-det-head">Purchased Items</h4>
                <Table data={invoice?.items} autoHeight>
                  <Column width={90} align="center">
                    <HeaderCell>Image</HeaderCell>
                    <ImageCell />
                  </Column>
                  <Column width={300}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="product.name">
                      {(rowData) => (
                        <Link to={`/products/${rowData?.product?._id}`}>
                          {rowData.product.name}
                        </Link>
                      )}
                    </Cell>
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Price</HeaderCell>
                    <Cell>{(rowData) => formatAmount(rowData?.price)}</Cell>
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Quantity</HeaderCell>
                    <Cell>{(rowData) => rowData?.quantity}</Cell>
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Tax</HeaderCell>
                    <Cell>{(rowData) => formatAmount(rowData?.taxAmt)}</Cell>
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Discount</HeaderCell>
                    <Cell>{(rowData) => formatAmount(rowData?.discount)}</Cell>
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Total</HeaderCell>
                    <Cell>{(rowData) => formatAmount(rowData?.total)}</Cell>
                  </Column>
                </Table>
                {/* <FlexboxGrid className="invoice-total-summary">
                  <FlexboxGrid.Item
                    colspan={24}
                    className="invoice-details-prod"
                  >
                    <Row className="text-right">
                      <Col xs={7}>
                        <p className="invoice-details-head grand">
                          Grand Total (₹)
                        </p>
                      </Col>
                      <Col xs={3}>
                        <p className="invoice-details-prod grand">
                          <strong>{invoice?.total.toFixed(2)}</strong>
                        </p>
                      </Col>
                    </Row>
                  </FlexboxGrid.Item>
                </FlexboxGrid> */}
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Col>
      </Row>
    </Container>
  );
};

export default InvoiceDetails;
