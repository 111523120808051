import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Pagination,
  InputGroup,
  useToaster,
  Message,
  Affix,
} from "rsuite";

import userService from "../../services/user.service";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { Cell, HeaderCell } from "rsuite-table";
import Column from "rsuite/esm/Table/TableColumn";
import SearchIcon from "@rsuite/icons/Search";
import EnvConfig from "../../envConfig";
import { IconButton, Icon } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import { FaThList } from "react-icons/fa";
import DeleteModal from "../../components/DeleteModal/Delete.Modal";
import "./user.css";
import { setRouteData } from "../../stores/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ScrollToTop from "../../utilities/ScrollToTop";
import { formatDate } from "../../utilities/formatDate";
import { useSmallScreen } from "../../utilities/useWindowSize";
import UserDeleteModal from "../../components/DeleteModal/UserDeleteModal";
import { FaSquareCheck, FaSquareXmark } from "react-icons/fa6";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { VscCircleSlash } from "react-icons/vsc";

const Users = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const toaster = useToaster();
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [pageError, set_pageError] = useState("");
  const [status, setStatus] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const resp = await trackPromise(userService.getUsers());
      setUsers(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Failed to fetch users", error);
    }
  };

  const getData = () => {
    let filteredUsers = users;
    if (searchQuery) {
      filteredUsers = users.filter((user) =>
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    let sortedProducts = [...filteredUsers];
    if (sortColumn && sortType) {
      sortedProducts.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }

    const start = limit * (page - 1);
    const end = start + limit;
    const paginatedProducts = sortedProducts.slice(start, end);

    return paginatedProducts;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const ImageCell = ({ rowData, ...props }) => {
    return (
      <Cell {...props} className="image-cell">
        <div>
          <img
            src={`${EnvConfig?.MediaBase}/${rowData?.assetsDir}/${rowData?.profileImage}`}
            alt="users"
          />
        </div>
      </Cell>
    );
  };

  const handleOpenModal = (itemId) => {
    const user = users.find((user) => user._id === itemId);
    setStatus(user.isActive);
    setSelectedItemId(itemId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItemId(null);
  };

  async function deleteUser(id) {
    try {
      const resp = await trackPromise(userService.deleteUser(id));
      const { data } = resp;

      if (data?.success === true) {
        toast.success(data.message || "User deleted successfully");
        getUsers();
        handleCloseModal();
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.response.data.message);
      }
    }
  }

  const isSmallScreen = useSmallScreen(768);

  return (
    <Container className="users-cont">
      <ScrollToTop />
      <Affix>
        <Row
          gutter={0}
          className="users-header"
          style={{
            width: "100%",
            backgroundColor: "white",
            padding: "10px",
          }}
        >
          <Col xs={6} className="search-container">
            <InputGroup inside>
              <Input
                placeholder="Search ..."
                value={searchQuery}
                onChange={(value) => setSearchQuery(value)}
              />
              <InputGroup.Button>
                <SearchIcon />
              </InputGroup.Button>
            </InputGroup>
          </Col>
        </Row>
      </Affix>
      <Row gutter={0}>
        <Col xs={24}>
          <Table
            autoHeight
            wordWrap="break-word"
            data={getData()}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={loading}
            affixHeader={50}
          >
            <Column>
              <HeaderCell>Image</HeaderCell>
              <ImageCell dataKey="image" />
            </Column>
            <Column width={200} sortable>
              <HeaderCell>First Name</HeaderCell>
              <Cell dataKey="firstName">
                {(rowData) => (
                  <Link to={`/users/${rowData._id}`}>
                    {rowData.firstName} {rowData.lastName}
                  </Link>
                )}
              </Cell>
            </Column>

            <Column width={250}>
              <HeaderCell>Email-Id</HeaderCell>
              <Cell dataKey="email" />
            </Column>

            <Column width={200}>
              <HeaderCell>Phone</HeaderCell>
              <Cell dataKey="phone"></Cell>
            </Column>

            <Column width={150}>
              <HeaderCell>Date</HeaderCell>
              <Cell dataKey="createdAt">
                {(rowData) => formatDate(rowData.createdAt)}
              </Cell>
            </Column>

            <Column flexGrow={2}>
              <HeaderCell>Status</HeaderCell>
              <Cell dataKey="isActive">
                {(rowData) => (
                  <div>
                    {rowData.isActive ? (
                      <IconButton
                        title="status"
                        icon={<FaSquareCheck color="green" />}
                      />
                    ) : (
                      <IconButton
                        title="status"
                        icon={<FaSquareXmark color="red" />}
                      />
                    )}
                  </div>
                )}
              </Cell>
            </Column>

            <Column width={130} align="center">
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "0.625rem",
                    }}
                  >
                    <Link to={`/users/${rowData._id}`}>
                      <IconButton
                        title="details"
                        icon={<FaThList className="icon-blue" />}
                      />
                    </Link>
                    <IconButton
                      title="delete"
                      icon={<TrashIcon color="red" />}
                      onClick={() => handleOpenModal(rowData._id)}
                    />
                    <IconButton
                      title="disable"
                      icon={
                        !rowData.isActive ? (
                          <IoIosCheckmarkCircleOutline />
                        ) : (
                          <VscCircleSlash />
                        )
                      }
                      onClick={() => {
                        setDisable(true);
                        handleOpenModal(rowData._id);
                      }}
                    />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
        </Col>
      </Row>
      <div className="pagination">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size={isSmallScreen ? "xs" : "md"}
          layout={[
            "total",
            "-",
            `${!isSmallScreen ? "limit" : ""}`,
            `${!isSmallScreen ? "|" : ""}`,
            "pager",
            `${!isSmallScreen ? "|" : ""}`,
            `${!isSmallScreen ? "skip" : ""}`,
          ]}
          total={users.length}
          limitOptions={[5, 10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>

      {isModalOpen && (
        <UserDeleteModal
          itemId={selectedItemId}
          onClose={handleCloseModal}
          isOpen={handleOpenModal}
          fetch={getUsers}
          status={status}
          disable={disable}
        />
      )}
    </Container>
  );
};

export default Users;
