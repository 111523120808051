import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../assets/images/logo.png";
import { formatAmount } from "./formatAmount";
import { formatDate } from "./formatDate";

export const generateInvoicePDF = (invoice) => {
  const doc = new jsPDF();
  const adminAddress = invoice?.order?.sellerAddress;

  const startY = 20;
  const marginX = 14;
  const rightX = 140;
  const middleX = 70;

  const lineHeight = 3;

  const logoWidth = 10;
  const logoHeight = 10;

  if (logo) {
    doc.addImage(logo, "PNG", 15, 10, logoWidth, logoHeight);
  }

  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text("Ayur Harmony", marginX + logoWidth + 5, 16);

  const adjustedStartY = 25;

  doc.setFontSize(8);
  doc.setFont("helvetica", "bold");
  doc.text(`Sold By:`, marginX, adjustedStartY);

  doc.setFont("helvetica", "normal");
  const addressY = adjustedStartY + lineHeight;
  const adminAddressLines = [
    "Ayur Harmony",
    adminAddress?.landmark,
    adminAddress?.lane,
    adminAddress?.city,
    `${adminAddress?.state} - ${adminAddress?.pincode}`,
    `Phone: ${adminAddress?.phone}`,
  ].flatMap((line) => doc.splitTextToSize(line, 40));

  adminAddressLines.forEach((line, index) => {
    doc.text(line, marginX, addressY + index * lineHeight);
  });

  doc.setFont("helvetica", "bold");
  doc.text(`Invoice Info:`, middleX, adjustedStartY);

  doc.setFont("helvetica", "normal");
  const invoiceY = adjustedStartY + lineHeight;
  const invoiceInfoLines = [
    `Invoice Id: ${invoice?.invoiceId}`,
    `Invoice Date: ${formatDate(invoice?.createdAt)}`,
    `Order Id: ${invoice?.order?.orderId}`,
    `Order Date: ${formatDate(invoice?.order?.createdAt)}`,
    `Delivery Date: ${formatDate(invoice?.order?.deliveryDate)}`,
  ].flatMap((line) => doc.splitTextToSize(line, 80));

  invoiceInfoLines.forEach((line, index) => {
    doc.text(line, middleX, invoiceY + index * lineHeight);
  });

  doc.setFont("helvetica", "bold");
  doc.text("Shipping Address:", rightX, adjustedStartY);

  doc.setFont("helvetica", "normal");
  const billingY = adjustedStartY + lineHeight;
  const billingAddressLines = [
    `${invoice?.user?.firstName} ${invoice?.user?.lastName}`,
    `${invoice?.order?.deliveryAddress?.lane}, ${invoice?.order?.deliveryAddress?.city}`,
    `${invoice?.order?.deliveryAddress?.state} - ${invoice?.order?.deliveryAddress?.pincode}`,
    invoice?.order?.deliveryAddress?.landmark,
    `Contact: ${invoice?.order?.deliveryAddress?.phone}`,
    `Email: ${invoice?.user?.email}`,
  ].flatMap((line) => doc.splitTextToSize(line, 60));

  billingAddressLines.forEach((line, index) => {
    doc.text(line, rightX, billingY + index * lineHeight);
  });

  const tableStartY = Math.max(
    addressY + adminAddressLines.length * lineHeight + 5,
    billingY + billingAddressLines.length * lineHeight + 5,
    invoiceY + invoiceInfoLines.length * lineHeight + 5
  );

  const itemRows =
    invoice?.items?.map((item) => [
      item.product?.name,
      formatAmount(item?.quantity),
      `${formatAmount(item?.price)}`,
      `${formatAmount(item?.tax)}`,
      `${formatAmount(item?.discount)}`,
      `${formatAmount(item?.total)}`,
    ]) || [];

  const totals = invoice?.items?.reduce(
    (acc, item) => {
      acc.quantity += item?.quantity;
      acc.price += item?.price;
      acc.tax += item?.tax;
      acc.discount += item?.discount;
      acc.total += item?.total;
      return acc;
    },
    { quantity: 0, price: 0, tax: 0, discount: 0, total: 0 }
  );

  doc.autoTable({
    head: [["Items", "Quantity", "Price", "Tax", "Discount", "Amount"]],
    body: itemRows,
    startY: tableStartY,
    styles: { fontSize: 7 },
    headStyles: { fillColor: [169, 169, 169] },
    footStyles: { fillColor: [211, 211, 211] },
    foot: [
      [
        "Grand Total",
        formatAmount(totals?.quantity),
        formatAmount(totals?.price),
        formatAmount(totals?.tax),
        formatAmount(totals?.discount),
        formatAmount(totals?.total),
      ],
    ],
  });

  const finalY = doc.lastAutoTable.finalY + 10;
  doc.setFontSize(7);
  if (invoice?.userNote) {
    const userNoteLines = doc.splitTextToSize(
      `User Note: ${invoice?.userNote}`,
      190
    );
    userNoteLines.forEach((line, index) => {
      doc.text(line, marginX, finalY + index * lineHeight);
    });
  }

  if (invoice?.order?.status === "returned") {
    doc.setFillColor(0);
    const warningBoxY = finalY + 10;
    doc.rect(marginX, warningBoxY, 190, 10, "F");
    doc.setTextColor(255);
    doc.setFontSize(10);
    doc.text("Warning: This order has been returned", 100, warningBoxY + 6, {
      align: "center",
    });
    doc.setTextColor(0);
  }

  const signatureLineY = finalY + 25;
  const signatureMarginX = 150;
  doc.line(
    signatureMarginX,
    signatureLineY,
    signatureMarginX + 40,
    signatureLineY
  );
  doc.text("Authorized Signature", signatureMarginX, signatureLineY + 3);
  const fileName = `AyurHarmony-${invoice.invoiceId}`;

  doc.save(fileName);
};
