import React, { useEffect } from "react";
import { Container, Row, Col, Panel, Button } from "rsuite";
import "./terms.css";
import ScrollToTop from "../../../utilities/ScrollToTop";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../../stores/appSlice";

function TermsAndConditions({ pageTitle }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);
  return (
    <Container className="terms-container">
      <ScrollToTop />
      <Row className="show-grid">
        <Col xs={24} md={18} className="terms">
          <Panel header={<h3>Terms and Conditions</h3>}>
            <div className="terms-content">
              <h4>Introduction</h4>
              <p>
                Welcome to [Your Website]. These Terms and Conditions govern
                your use of our website. By accessing or using the site, you
                agree to be bound by these terms.
              </p>

              <h4>Eligibility</h4>
              <p>
                You must be at least 18 years old to use this site. By using
                this site, you represent and warrant that you meet this
                requirement.
              </p>

              <h4>Usage</h4>
              <p>
                You agree to use this site only for lawful purposes and in a way
                that does not infringe on the rights of others or restrict their
                use and enjoyment of the site.
              </p>

              <h4>Content Ownership</h4>
              <p>
                All content on this site is owned by [Your Company] or its
                licensors. You may not reproduce, distribute, or create
                derivative works from any content on this site without prior
                written permission.
              </p>

              <h4>Modifications</h4>
              <p>
                We reserve the right to modify these Terms and Conditions at any
                time. Changes will be posted on this page, and your continued
                use of the site constitutes acceptance of the modified terms.
              </p>

              <h4>Contact Us</h4>
              <p>
                If you have any questions about these Terms and Conditions,
                please contact us at support@[yourwebsite].com.
              </p>
            </div>
          </Panel>
        </Col>
      </Row>
    </Container>
  );
}

export default TermsAndConditions;
