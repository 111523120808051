import { configureStore } from "@reduxjs/toolkit";
import { trackPromise } from "react-promise-tracker";
import authReducer, {
  initAuthState,
  setAuth,
  setToken,
  setUser,
} from "./authSlice";
import appReducer from "./appSlice";
import cartReducer, { initCart } from "./cartSlice";
import UserService from "../services/user.service";
import cartService from "../services/cart.service";
import notificationReducer, { initNotification } from "./notificationSlice";
import notificationService from "../services/notification.service";
import { toast } from "react-toastify";
export const LOCAL_KEY = "AYUR_HARMONY_DATA";

const store = configureStore({
  reducer: {
    cart: cartReducer,
    authState: authReducer,
    appState: appReducer,
    notificationState: notificationReducer,
  },
});

(function initLoad() {
  const localData = localStorage.getItem(LOCAL_KEY)
    ? JSON.parse(localStorage.getItem(LOCAL_KEY))
    : { authToken: undefined };

  if (localData.authToken) {
    store.dispatch(setToken({ token: localData.authToken }));
    getUserProfile();
  } else {
    store.dispatch(setAuth({ isAuthenticated: false }));
  }

  if (localData.cart) {
    store.dispatch(initCart(localData.cart));
  }
  if (localData?.cart?.items?.length > 0) {
    getVisitorCart();
  }
})();

export async function getUserCart() {
  const cartResp = await trackPromise(cartService.cartOfUser());
  const cartData = cartResp.data.data;
  if (cartData) {
    store.dispatch(initCart(cartData));
  }
}

export async function getVisitorCart() {
  const data = JSON.parse(localStorage.getItem(LOCAL_KEY));
  const resp = await trackPromise(cartService.cartOfVisitor(data.cart));
  store.dispatch(initCart(resp.data.data));
  if (resp.success) {
    store.dispatch(initCart(resp.data.data));
  }
}

const fetchAndDispatchNotifications = async () => {
  try {
    const resp = await trackPromise(
      notificationService.getNotificationsOfUser()
    );
    const { data } = resp;
    if (data.success) {
      store.dispatch(initNotification({ notes: data.data || [] }));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.error("Failed to fetch notifications", error);
  }
};

export async function updateUserProfile() {
  try {
    const resp = await trackPromise(UserService.profile());
    const { data } = resp;

    if (data.success) {
      store.dispatch(setUser({ user: data.data }));
    } else {
      clearAppData();
    }
  } catch (err) {
    toast.error(err?.response?.data?.message);
    console.error("User Profile error catch => ", err);
    clearAppData();
  }
}

export async function getUserProfile() {
  try {
    const resp = await trackPromise(UserService.profile());
    const { data } = resp;

    if (data.success) {
      store.dispatch(setAuth({ isAuthenticated: true }));
      store.dispatch(setUser({ user: data.data }));

      fetchAndDispatchNotifications();
      if (data.data?.role?.roleCode !== "admin") {
        getUserCart();
      }
    } else {
      clearAppData();
    }
    return data.data?.role?.roleCode;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    console.error("User Profile error catch => ", err);
    clearAppData();
  }
}

export function setLocalData(data) {
  const existingDataString = localStorage.getItem(LOCAL_KEY);
  const existingData = existingDataString ? JSON.parse(existingDataString) : {};

  const updatedData = {
    ...existingData,
    ...data,
  };

  localStorage.setItem(LOCAL_KEY, JSON.stringify(updatedData));
}

export function saveCartState() {
  const state = store.getState();
  const localData = localStorage.getItem(LOCAL_KEY)
    ? JSON.parse(localStorage.getItem(LOCAL_KEY))
    : {};

  localData.cart = state.cart.products;
  localStorage.setItem(LOCAL_KEY, JSON.stringify(localData));
}

export function removeLocalData() {
  if (localStorage.getItem(LOCAL_KEY)) localStorage.removeItem(LOCAL_KEY);
}

export const clearCartFromLocalStorage = () => {
  const cartDataString = localStorage.getItem(LOCAL_KEY);
  if (cartDataString) {
    const cartData = JSON.parse(cartDataString);

    const { cart, ...rest } = cartData;

    localStorage.setItem(LOCAL_KEY, JSON.stringify(rest));
  }
};

export async function clearAppData() {
  store.dispatch(initAuthState());
  store.dispatch(initCart([]));
  removeLocalData();
}

export default store;
