import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Pagination,
  Rate,
  IconButton,
  Affix,
  SelectPicker,
} from "rsuite";
import "./reviews.css";
import { setRouteData } from "../../stores/appSlice";
import reviewService from "../../services/review.service";
import DeleteModal from "../../components/DeleteModal/Delete.Modal";
import IgnoreConfirmationModal from "./IgnoreModal";
import ReviewDetailsModal from "./ReviewDetailsModal";
import { FaThList, FaTrash } from "react-icons/fa";
import TrashIcon from "@rsuite/icons/Trash";
import productsService from "../../services/products.service";
import { trackPromise } from "react-promise-tracker";
import { isSmallScreen, useSmallScreen } from "../../utilities/useWindowSize";
import { useLocation } from "react-router";

const { Column, HeaderCell, Cell } = Table;

const Reviews = ({ pageTitle }) => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = React.useState(6);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [editingReview, setEditingReview] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const [showIgnoreConfirmation, setShowIgnoreConfirmation] = useState(false);
  const [showReviewDetailsModal, setShowReviewDetailsModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(""); // New state for selected product
  const [products, setProducts] = useState([]); // New state for products

  const authState = useSelector((state) => state.authState);
  const user = authState.user;
  const dispatch = useDispatch();
  const location = useLocation();
  const [noteRev, setNoteRev] = useState(location?.state?.noteOrigin);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  const getAllReviews = async () => {
    try {
      const reviewResp = await trackPromise(reviewService.getAllReviews());
      setReviews(reviewResp.data?.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed fetching error", error);
    }
  };

  const getSingleReview = () => {
    if (reviews.length === 0) {
      return;
    }

    const review = reviews.find((review) => review._id === noteRev);
    if (review) {
      setSelectedReview(review);
      setShowReviewDetailsModal(true);
    }
  };

  const fetchProducts = async () => {
    try {
      const productResp = await trackPromise(productsService.getproducts());
      setProducts(productResp.data?.data || []);
    } catch (error) {
      console.error("Error fetching reviews or products:", error);
      toast.error();
      toast.error(
        error.response.data.message || "Failed to fetch reviews or products."
      );
    }
  };
  useEffect(() => {
    fetchProducts();
    getAllReviews();
  }, []);

  useEffect(() => {
    if (noteRev) {
      getSingleReview();
    }
  }, [reviews, noteRev]);

  useEffect(() => {
    if (selectedReview && noteRev) {
      setShowReviewDetailsModal(true);
    }
  }, [selectedReview, noteRev]);

  const handleDeleteReview = (reviewId) => {
    const review = reviews.find((rev) => rev?._id === reviewId);
    if (review) {
      setReviewToDelete(review);
      setShowDeleteModal(true);
    }
  };

  const confirmDeletion = async () => {
    if (!reviewToDelete) return;

    try {
      const resp = await reviewService.deleteReview(reviewToDelete._id);
      toast.success(resp.data.message);
      setReviews(reviews.filter((rev) => rev?._id !== reviewToDelete._id));
      setShowDeleteModal(false);
    } catch (error) {
      toast.error(error.response.data.message || "Failed to delete review.");
    }
  };

  const handleIgnoreReview = (review) => {
    setEditingReview(review);

    setShowReviewDetailsModal(false);
    setShowIgnoreConfirmation(true);
  };

  const confirmIgnoreReview = async () => {
    if (!editingReview) return;
    const reviewPayload = {
      isActive: editingReview.isActive === true ? false : true,
    };
    try {
      const resp = await reviewService.ignoreReview(
        editingReview._id,
        reviewPayload
      );
      toast.success(resp.data.message);
      getAllReviews();
      setReviews((prevReviews) =>
        prevReviews.map((rev) =>
          rev._id === editingReview._id ? { ...rev, ...reviewPayload } : rev
        )
      );
      setShowIgnoreConfirmation(false);
      setEditingReview(null);
    } catch (error) {
      toast.error(error.response.data.message || "Failed to update review.");
    }
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortDirection(sortType);

    const sortedReviews = [...reviews].sort((a, b) => {
      const x = a[sortColumn];
      const y = b[sortColumn];

      const isNumeric = (value) =>
        !isNaN(value) && value !== null && value !== "";

      if (isNumeric(x) && isNumeric(y)) {
        return sortType === "asc" ? x - y : y - x;
      } else {
        const comparison = x?.localeCompare(y, undefined, { numeric: true });
        return sortType === "asc" ? comparison : -comparison;
      }
    });

    setReviews(sortedReviews);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeLimit = (dataKey) => {
    setLimit(dataKey);
  };

  const showDetails = (review) => {
    setSelectedReview(review);
    setShowReviewDetailsModal(true);
  };

  const handleProductChange = (value) => {
    setSelectedProduct(value);
  };

  const filteredReviews = reviews.filter((review) =>
    selectedProduct ? review.product._id === selectedProduct : true
  );

  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedReviews = filteredReviews.slice(startIndex, endIndex);

  const isSmallScreen = useSmallScreen(768);

  const productsFilterData = products.map((product) => ({
    value: product._id,
    label: product.name,
  }));

  return (
    <Container className="product-bbg">
      <Col xs={24} className="review">
        <Row className="bg">
          <Affix>
            <div
              className="filter-section"
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "10px",
              }}
            >
              <label htmlFor="productFilter">Filter by Product:</label>
              <SelectPicker
                data={productsFilterData}
                onChange={(value) => setSelectedProduct(value)}
                searchable={false}
                placeholder="All Products"
              />
            </div>
          </Affix>
          <Table
            affixHeader={50}
            data={paginatedReviews}
            autoHeight
            wordWrap="break-word"
            sortColumn={sortColumn}
            sortType={sortDirection}
            onSortColumn={handleSortColumn}
          >
            <Column width={200} sortable>
              <HeaderCell>Product Name</HeaderCell>
              <Cell dataKey="product.name" />
            </Column>
            <Column width={300} sortable>
              <HeaderCell>Review Title</HeaderCell>
              <Cell dataKey="reviewTitle" />
            </Column>
            <Column width={230} sortable>
              <HeaderCell>Rating</HeaderCell>
              <Cell dataKey="rating">
                {(rowData) => (
                  <Rate size="sm" value={rowData.rating} readOnly />
                )}
              </Cell>
            </Column>
            <Column width={200} sortable>
              <HeaderCell>Review Status</HeaderCell>
              <Cell dataKey="isActive">
                {(rowData) => (rowData?.isActive ? "Active" : "Ignored")}
              </Cell>
            </Column>
            <Column width={130}>
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div className="flex-gap">
                    <IconButton
                      title="details"
                      onClick={() => showDetails(rowData)}
                      icon={<FaThList className="icon-blue" />}
                    />

                    <IconButton
                      title="delete"
                      onClick={() => handleDeleteReview(rowData._id)}
                      icon={<TrashIcon color="red" />}
                    />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div className="pagination-0">
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size={isSmallScreen ? "xs" : "md"}
              layout={[
                "total",
                "-",
                `${!isSmallScreen ? "limit" : ""}`,
                `${!isSmallScreen ? "|" : ""}`,
                "pager",
                `${!isSmallScreen ? "|" : ""}`,
                `${!isSmallScreen ? "skip" : ""}`,
              ]}
              total={filteredReviews.length}
              limitOptions={[5, 10, 30, 50]}
              limit={limit}
              activePage={currentPage}
              onChangePage={handlePageChange}
              onChangeLimit={handleChangeLimit}
            />
          </div>
          <DeleteModal
            itemId={reviewToDelete?._id}
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onDelete={confirmDeletion}
          />
          <IgnoreConfirmationModal
            isOpen={showIgnoreConfirmation}
            onClose={() => setShowIgnoreConfirmation(false)}
            onConfirm={confirmIgnoreReview}
            activate={editingReview?.isActive === true ? false : true}
          />
          <ReviewDetailsModal
            admin={user?.role?.roleCode === "admin"}
            review={selectedReview}
            isOpen={showReviewDetailsModal}
            onIgnoreReview={handleIgnoreReview}
            onClose={() => setShowReviewDetailsModal(false)}
          />
        </Row>
      </Col>
    </Container>
  );
};

export default Reviews;
