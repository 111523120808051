import React, { useEffect, useState } from "react";
import reviewService from "../../../services/review.service";
import { useDispatch, useSelector } from "react-redux";
import ReviewCard from "../../../components/ReviewCard/ReviewCard";
import DeleteModal from "../../../components/DeleteModal/Delete.Modal";
import { toast } from "react-toastify";
import { Container, Row, Col, Modal, Form, Rate, Button } from "rsuite";
import "./../MyReviews/reviews.css";
import { setRouteData } from "../../../stores/appSlice";
import { trackPromise } from "react-promise-tracker";
import productsService from "../../../services/products.service";
import { useParams } from "react-router";
import ScrollToTop from "../../../utilities/ScrollToTop";

const AllReviews = ({ pageTitle }) => {
  const { id } = useParams();
  const [reviews, setReviews] = useState([]);
  const authState = useSelector((state) => state.authState);
  const [editingReview, setEditingReview] = useState(null);
  const [product, setProduct] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const user = authState.user;
  const [showReviewModal, setShowReviewModal] = useState(false);
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState({
    reviewTitle: "",
    rating: 3,
    reviewDescription: "",
  });

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchProduct();
  }, []);

  const fetchProduct = async () => {
    try {
      const response = await trackPromise(productsService.getProductById(id));
      setProduct(response?.data?.data);
      setReviews(response?.data?.data?.reviews);
    } catch (error) {
      console.error("Error fetching product details:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const allReviews = product?.reviews;

  const handleDeleteReview = (reviewId) => {
    const review = reviews.find((rev) => rev?._id === reviewId);
    if (review) {
      setReviewToDelete(review);
      setShowDeleteModal(true);
    }
  };

  const confirmDeletion = async () => {
    if (!reviewToDelete) return;

    try {
      const resp = await trackPromise(
        reviewService.deleteReview(reviewToDelete?._id)
      );
      toast.success(resp.data.message);
      setReviews(reviews.filter((rev) => rev._id !== reviewToDelete?._id));
      setShowDeleteModal(false);
    } catch (error) {
      toast.error(error.response.data.message || "Failed to delete review.");
    }
  };

  const handleEditReview = (review) => {
    setEditingReview(review);
    setFormValue({
      reviewTitle: review?.reviewTitle,
      rating: review?.rating,
      reviewDescription: review?.reviewDescription,
      product: review?.product?._id,
    });
    setShowReviewModal(true);
  };

  const checkIfVoted = allReviews?.find(
    (review) => review?.user?._id === authState?.user?._id
  );

  const handleReviewSubmit = async () => {
    if (!editingReview) return;

    const reviewPayload = {
      rating: formValue?.rating,
      reviewTitle: formValue?.reviewTitle,
      reviewDescription: formValue?.reviewDescription,
      product: editingReview?.product?._id,
    };

    try {
      const resp = await reviewService.addReview(reviewPayload);
      toast.success(resp.data.message);
      setReviews((prevReviews) =>
        prevReviews.map((rev) =>
          rev._id === editingReview?._id ? { ...rev, ...reviewPayload } : rev
        )
      );
    } catch (error) {
      toast.error(error.response.data.message || "Failed to update review.");
    }

    setShowReviewModal(false);
    setEditingReview(null);
    window.location.reload();
    setFormValue({
      reviewTitle: "",
      rating: 3,
      reviewDescription: "",
      product: "",
    });
  };

  return (
    <Container className="product-bg">
      <ScrollToTop />
      <Col xs={20} className="review">
        <Row className="bg">
          <div className="product-container">
            <div className="reviews-section">
              {reviews.map((review) => (
                <ReviewCard
                  key={review?._id}
                  review={review}
                  onEdit={() => handleEditReview(review)}
                  onDelete={() => handleDeleteReview(review?._id)}
                  showEditBtn={false}
                  product
                />
              ))}

              <DeleteModal
                itemId={reviewToDelete?._id}
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={confirmDeletion}
              />
            </div>
          </div>
        </Row>
      </Col>
      <Modal open={showReviewModal} onClose={() => setShowReviewModal(false)}>
        <Modal.Header>
          <Modal.Title>Edit Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <Form.Group controlId="reviewTitle">
              <Form.ControlLabel>Review Title</Form.ControlLabel>
              <Form.Control
                name="reviewTitle"
                value={formValue?.reviewTitle}
                onChange={(value) =>
                  setFormValue({ ...formValue, reviewTitle: value })
                }
              />
            </Form.Group>
            <Form.Group controlId="rating">
              <Form.ControlLabel>Rating</Form.ControlLabel>
              <Rate
                value={formValue?.rating}
                onChange={(value) =>
                  setFormValue({ ...formValue, rating: value })
                }
              />
            </Form.Group>
            <Form.Group controlId="reviewDescription">
              <Form.ControlLabel>Review Description</Form.ControlLabel>
              <Form.Control
                componentclass="textarea"
                rows={5}
                name="reviewDescription"
                value={formValue?.reviewDescription}
                onChange={(value) =>
                  setFormValue({ ...formValue, reviewDescription: value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="subtle" onClick={() => setShowReviewModal(false)}>
            Cancel
          </Button>
          <Button appearance="primary" onClick={handleReviewSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AllReviews;
