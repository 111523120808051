import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form, Schema, Panel } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import userService from "../../services/user.service";
import ScrollToTop from "../../utilities/ScrollToTop";
import EnvConfig from "../../envConfig";
import "./admin.css";
import { setUser } from "../../stores/authSlice";

const { StringType, BooleanType } = Schema.Types;

const model = Schema.Model({
  firstName: StringType().isRequired("First Name is required."),
  lastName: StringType().isRequired("Last Name is required."),
  phone: StringType()
    .isRequired("Phone is required.")
    .minLength(10, "Phone number should be atleast 10 digits")
    .maxLength(10, "Phone should be 10 digits"),
  email: StringType()
    .isEmail("Please enter a valid email.")
    .isRequired("Email is required."),
});

const addressModel = Schema.Model({
  city: StringType().isRequired("City is required."),
  state: StringType().isRequired("State is required."),
  pincode: StringType().isRequired("Postal Code is required."),
  lane: StringType().isRequired("Lane is required."),
  landmark: StringType().isRequired("Landmark is required."),
  phone: StringType().isRequired("Phone is required."),
  isDefault: BooleanType(),
});

function EditProfile({ pageTitle }) {
  const authState = useSelector((state) => state.authState);
  const formRef = useRef();
  const user = authState.user;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  const [formValue, setFormValue] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phone: user?.phone || "",
    email: user?.email || "",
  });

  const [formError, setFormError] = useState({});
  const [addressValue, setAddressValue] = useState(
    user?.addresses?.[0] || {
      city: "",
      state: "",
      pincode: "",
      lane: "",
      landmark: "",
      phone: "",
      isDefault: true,
    }
  );

  const [addressError, setAddressError] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleAddressChange = (value) => {
    setAddressValue(value);
    setAddressError({});
  };

  const getUser = async () => {
    try {
      const resp = await trackPromise(userService.profile());
      dispatch(setUser({ user: resp.data.data }));
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error fetching profile");
    }
  };

  const handleAddressFieldChange = (value, event) => {
    const { name } = event.target;
    setAddressValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      return;
    }
    const formData = new FormData();

    Object.keys(formValue).forEach((key) => {
      formData.append(key, formValue[key]);
    });

    if (selectedImage) {
      formData.append("profileImage", selectedImage);
    }

    formData.append("addresses", JSON.stringify([addressValue]));

    try {
      const resp = await trackPromise(
        userService.updateProfile(formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );
      toast.success(resp.data.message);
      getUser();
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error(error?.response?.data?.message || "Error updating profile");
    }
  };

  return (
    <Container className="profile-container">
      <ScrollToTop />
      <Col xs={24} className="edit-profile-admin">
        <Row gutter={16}>
          <Col xs={24}>
            <Container className="profile-panel">
              <Row gutter={50}>
                <Form
                  fluid
                  ref={formRef}
                  model={model}
                  onCheck={(formError) => setFormError(formError)}
                  onSubmit={handleSubmit}
                  formValue={formValue}
                  onChange={setFormValue}
                >
                  <Col xs={24} lg={6} className="image-col">
                    <img
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : `${EnvConfig?.MediaBase}/${user.assetsDir}/${user.profileImage}`
                      }
                      alt="Profile"
                      className="profile-image"
                    />
                    <div className="custom-file-upload">
                      <label
                        htmlFor="file-upload"
                        className="custom-file-label"
                      >
                        Choose File
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="image-upload-input"
                      />
                    </div>
                  </Col>
                  <Col xs={24} lg={6}>
                    <Col xs={24}>
                      <Form.Group controlId="firstName">
                        <Form.ControlLabel>First Name</Form.ControlLabel>
                        <Form.Control name="firstName" />
                      </Form.Group>
                    </Col>
                    <Col xs={24}>
                      <Form.Group controlId="lastName">
                        <Form.ControlLabel>Last Name</Form.ControlLabel>
                        <Form.Control name="lastName" />
                      </Form.Group>
                    </Col>

                    <Col xs={24}>
                      <Form.Group controlId="phone">
                        <Form.ControlLabel>Phone</Form.ControlLabel>
                        <Form.Control name="phone" />
                      </Form.Group>
                    </Col>
                    <Col xs={24}>
                      <Form.Group controlId="email">
                        <Form.ControlLabel>Email</Form.ControlLabel>
                        <Form.Control name="email" />
                      </Form.Group>
                    </Col>
                  </Col>
                </Form>

                <Col xs={24} lg={12}>
                  <Row gutter={16}>
                    <Col>
                      <Form
                        fluid
                        model={addressModel}
                        formValue={addressValue}
                        onChange={handleAddressFieldChange}
                      >
                        <Row gutter={16}>
                          <Col xs={24}>
                            <Form.Group controlId="pincode">
                              <Form.ControlLabel>Pincode</Form.ControlLabel>
                              <Form.Control
                                name="pincode"
                                value={addressValue.pincode}
                                onChange={(value, event) =>
                                  handleAddressFieldChange(value, event)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24}>
                            <Form.Group controlId="lane">
                              <Form.ControlLabel>
                                Area, Street, Sector, Village
                              </Form.ControlLabel>
                              <Form.Control
                                name="lane"
                                value={addressValue.lane}
                                onChange={(value, event) =>
                                  handleAddressFieldChange(value, event)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24}>
                            <Form.Group controlId="landmark">
                              <Form.ControlLabel>Landmark</Form.ControlLabel>
                              <Form.Control
                                name="landmark"
                                value={addressValue.landmark}
                                onChange={(value, event) =>
                                  handleAddressFieldChange(value, event)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={12}>
                            <Form.Group controlId="city">
                              <Form.ControlLabel>City</Form.ControlLabel>
                              <Form.Control
                                name="city"
                                value={addressValue.city}
                                onChange={(value, event) =>
                                  handleAddressFieldChange(value, event)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12}>
                            <Form.Group controlId="state">
                              <Form.ControlLabel>State</Form.ControlLabel>
                              <Form.Control
                                name="state"
                                value={addressValue.state}
                                onChange={(value, event) =>
                                  handleAddressFieldChange(value, event)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Col xs={24}>
                          <Form.Group controlId="phone">
                            <Form.ControlLabel>Phone</Form.ControlLabel>
                            <Form.Control
                              name="phone"
                              value={addressValue.phone}
                              onChange={(value, event) =>
                                handleAddressFieldChange(value, event)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={16} className="submit-row">
                <Col xs={24}>
                  <Button
                    className="checkout-btn"
                    appearance="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save Profile
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default EditProfile;
