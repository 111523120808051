import React from "react";
import { Whisper, IconButton, Button, Popover, Divider, Avatar } from "rsuite";
import { FaUser } from "react-icons/fa";
import "./userHeader.css";
import { clearAppData } from "../../../stores/store";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import EnvConfig from "../../../envConfig";

const UserMenuOverlay = React.forwardRef(({ style, onClose, ...rest }, ref) => {
  const styles = {
    ...style,
    color: "#000",
    background: "#fff",
    width: 200,
    padding: 10,
    borderRadius: 4,
    position: "fixed",
    border: "1px solid #ddd",
    boxShadow: "0 3px 6px -2px rgba(0, 0, 0, 0.6)",
    zIndex: 1000,
    paddingBottom: 0,
    top: "3.43rem",
  };

  const authState = useSelector((state) => state.authState);

  return (
    <Popover {...rest} style={styles} ref={ref}>
      <ul
        className="user-menu-links"
        style={{ listStyle: "none", padding: 0, margin: 0 }}
      >
        <li>
          {authState.user.firstName} {authState.user.lastName}
        </li>
        <hr />
        <li>
          <Link onClick={onClose} to="/profile">
            Profile
          </Link>
        </li>
        <li>
          <Link onClick={onClose} to="/change-password">
            Change Password
          </Link>
        </li>
        <li>
          <Link onClick={onClose} to="/place-orders">
            My orders
          </Link>
        </li>
        <li>
          <Link onClick={onClose} to="/my-reviews">
            My reviews
          </Link>
        </li>
        <hr />
        <li>
          <Link onClick={clearAppData} to="/">
            Logout
          </Link>
        </li>
      </ul>
    </Popover>
  );
});

const UserMenu = ({ triggerRef, active }) => {
  const authState = useSelector((state) => state.authState);

  return (
    <div>
      <Whisper
        trigger="click"
        placement="bottomEnd"
        speaker={(props, ref) => {
          const { className, left, top, onClose } = props;
          return (
            <UserMenuOverlay
              onClose={onClose}
              className={className}
              ref={ref}
            />
          );
        }}
      >
        <Button ref={triggerRef} title="menu">
          <Avatar
            size="sm"
            circle
            src={`${EnvConfig.MediaBase}/${authState?.user?.assetsDir}/${authState?.user?.profileImage}`}
            alt="User Avatar"
            className="usermenu-avatar"
          />
        </Button>
      </Whisper>
    </div>
  );
};

export default UserMenu;
