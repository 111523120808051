export const BREAK_POINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
};

export const CHART_TYPES = {
  line: "line",
  area: "area",
  bar: "bar",
  histogram: "histogram",
  pie: "pie",
  donut: "donut",
  radialBar: "radialBar",
  scatter: "scatter",
  bubble: "bubble",
  heatmap: "heatmap",
  treemap: "treemap",
  boxPlot: "boxPlot",
  candlestick: "candlestick",
  radar: "radar",
  polarArea: "polarArea",
  rangeBar: "rangeBar",
};

export const ORDER_STATUSES = [
  { id: "1", value: "placed", label: "Placed" },
  { id: "2", value: "processing", label: "Processing" },
  { id: "3", value: "dispatched", label: "Dispatched" },
  { id: "4", value: "delivered", label: "Delivered" },
  { id: "5", value: "cancelled", label: "Cancelled" },
  { id: "6", value: "returned", label: "Returned" },
];
