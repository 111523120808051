import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  amount: 0,
  charge: 0,
  discount: 0,
  total: 0,
  tax: 0,
};

const cartSlice = createSlice({
  name: "cartState",
  initialState,
  reducers: {
    addProduct: (state, action) => {
      const product = action.payload;
      const existingProduct = state.items.find((p) => p._id === product._id);

      if (existingProduct) {
        existingProduct.quantity += Number(product.quantity);
        existingProduct.total =
          existingProduct.quantity * existingProduct.price;
      } else {
        state.items.push({ ...product, quantity: Number(product.quantity) });
      }

      state.total = state.items.reduce((total, item) => total + item.total, 0);
    },
    removeProduct: (state, action) => {
      const productId = action.payload;
      state.items = state.items.filter((product) => product._id !== productId);
      state.total = state.items.reduce((total, item) => total + item.total, 0);
    },
    updateProductQuantity: (state, action) => {
      const { _id, quantity } = action.payload;
      const existingProduct = state.items.find(
        (product) => product._id === _id
      );

      if (existingProduct) {
        existingProduct.quantity = Number(quantity);
        existingProduct.total =
          existingProduct.quantity * existingProduct.price;
        state.total = state.items.reduce(
          (total, item) => total + item.total,
          0
        );
      }
    },
    clearCart: (state) => {
      state.items = [];
      state.total = 0;
    },
    initCart: (state, action) => {
      const { items, amount, charge, discount, total, tax } = action.payload;
      state.items = items?.map((product) => ({
        ...product,
        quantity: Number(product.quantity),
      }));
      state.amount = amount;
      state.charge = charge;
      state.discount = discount;
      state.total = total;
      state.tax = tax;
    },
  },
});

const selectCartItems = (state) => state.cart.items;

export const selectTotalQuantity = createSelector([selectCartItems], (items) =>
  items?.reduce((total, item) => total + item.quantity, 0)
);

export const selectTotalAmount = createSelector([selectCartItems], (items) =>
  items?.reduce((total, item) => total + item.total, 0)
);

export const {
  addProduct,
  removeProduct,
  updateProductQuantity,
  clearCart,
  initCart,
} = cartSlice.actions;

export default cartSlice.reducer;
