import React, { useEffect, useState } from "react";
import { Navbar, Nav, Drawer, Badge, Container, Row, Col } from "rsuite";
import { FaShoppingCart } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import UserMenu from "./UserMenu";
import LoginModal from "./LoginModal";
import { useCart } from "../../../utilities/cart";
import {
  selectTotalAmount,
  selectTotalQuantity,
} from "../../../stores/cartSlice";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./userHeader.css";
import { clearAppData } from "../../../stores/store";
import Notifications from "./Notifications";
import { IoArrowBackSharp } from "react-icons/io5";

const CustomNavbar = ({ onSelect, activeKey, authState, ...props }) => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1068);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isAuth, setAuth] = useState(authState.isAuthenticated);
  const [showLogin, setShowLogin] = useState(false);
  const totalQuantity = useSelector(selectTotalQuantity);
  const totalAmount = useSelector(selectTotalAmount);

  useEffect(() => {
    setAuth(authState.isAuthenticated);
  }, [authState]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1068);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    switch (true) {
      case location.pathname === "/":
        onSelect("1");
        break;
      case location.pathname.startsWith("/products"):
        onSelect("2");
        break;
      case location.pathname === "/about":
        onSelect("3");
        break;
      case location.pathname === "/contact":
        onSelect("4");
        break;
      case location.pathname === "/cart":
        onSelect("6");
        break;
      case location.pathname === "/place-orders":
        onSelect("7");
        break;
      case location.pathname === "/profile":
        onSelect("8");
        break;
      case location.pathname === "/change-password":
        onSelect("9");
        break;
      default:
        onSelect(null);
    }
  }, [location.pathname, onSelect]);

  const handleClose = () => {
    setShowLogin(false);
  };

  const handleNavItemClick = (eventKey) => {
    onSelect(eventKey);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  return (
    <>
      <LoginModal open={showLogin} onClose={handleClose} />
      <Navbar {...props} className="user-navbar-container">
        <Navbar.Brand as={Link} to="/" className="logo-container">
          <img
            src="https://websitedemos.net/plant-store-02/wp-content/uploads/sites/410/2020/06/plants-store-logo-green.svg"
            alt="logo"
            className="header-logo"
          />
          <div className="logo-text">Ayur Harmony</div>
        </Navbar.Brand>

        <Nav
          onSelect={handleNavItemClick}
          className="nav-items"
          activeKey={activeKey}
          pullRight
        >
          {!isMobile && (
            <div>
              <Nav.Item as={Link} eventKey="1" to="/">
                Home
              </Nav.Item>
              <Nav.Item as={Link} eventKey="2" to="/products">
                Products
              </Nav.Item>
              <Nav.Item as={Link} eventKey="3" to="/about">
                About
              </Nav.Item>
              <Nav.Item
                as={Link}
                eventKey="4"
                to="/contact"
                className="contact"
              >
                Contact
              </Nav.Item>
            </div>
          )}
          <div className="bell-icon">
            {isAuth ? (
              <Nav.Item as={Link} to="#">
                <Notifications />
              </Nav.Item>
            ) : (
              ""
            )}
          </div>
          <div>
            <Nav.Item as={Link} eventKey="6" to="/cart">
              <div className="cart-container" title="Cart">
                {totalAmount ? (
                  <>
                    <Badge content={totalQuantity}>
                      <FaShoppingCart className="icon cart-icon" />
                    </Badge>
                    <p>{totalAmount ? `₹${totalAmount}` : ""}</p>
                  </>
                ) : (
                  <FaShoppingCart className="icon cart-icon" />
                )}
              </div>
            </Nav.Item>

            {!isMobile && (
              <Nav.Item as={Link} to="#" eventKey="8" className="login-btn">
                {isAuth ? (
                  <UserMenu active={activeKey === "8"} />
                ) : (
                  <div className="login" onClick={() => setShowLogin(true)}>
                    Login
                  </div>
                )}
              </Nav.Item>
            )}
            {isMobile && (
              <Nav.Item
                as={Link}
                to="#"
                className="menu-btn"
                eventKey="7"
                icon={<IoMdMenu />}
                onClick={() => setDrawerOpen(true)}
              />
            )}
          </div>
        </Nav>
      </Navbar>

      {isMobile && (
        <Drawer
          size="xs"
          placement="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Drawer.Header>
            <Drawer.Title>Menu</Drawer.Title>
            <Drawer.Actions>
              <button
                className="backprimary"
                onClick={() => setDrawerOpen(false)}
              >
                <IoArrowBackSharp />
              </button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="mobile-drawer">
            <Nav
              onSelect={handleNavItemClick}
              activeKey={activeKey}
              appearance="subtle"
            >
              <Nav.Item as={Link} to="/" eventKey="1">
                Home
              </Nav.Item>
              <Nav.Item as={Link} to="/products" eventKey="2">
                Products
              </Nav.Item>
              <Nav.Item as={Link} to="/about" eventKey="3">
                About
              </Nav.Item>
              <Nav.Item as={Link} to="/contact" eventKey="4">
                Contact
              </Nav.Item>
              {isAuth ? (
                <>
                  <Nav.Item as={Link} to="/cart" eventKey="5">
                    Cart
                  </Nav.Item>
                  <Nav.Item as={Link} to="/place-orders" eventKey="6">
                    My Orders
                  </Nav.Item>
                  <Nav.Item as={Link} to="/my-reviews" eventKey="6">
                    My Reviews
                  </Nav.Item>
                  <Nav.Item as={Link} to="/profile" eventKey="7">
                    Profile
                  </Nav.Item>
                  <Nav.Item as={Link} to="/change-password" eventKey="8">
                    Change Password
                  </Nav.Item>
                  <Nav.Item as={Link} onClick={clearAppData}>
                    Logout
                  </Nav.Item>
                </>
              ) : (
                <Nav.Item as={Link} to="#" onClick={() => setShowLogin(true)}>
                  Login
                </Nav.Item>
              )}
            </Nav>
          </Drawer.Body>
        </Drawer>
      )}
    </>
  );
};

const UserHeader = ({ authState }) => {
  const [activeKey, setActiveKey] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container>
      <Row className="user-navbar">
        <Col xs={24} lg={20} className="user-navbar-container">
          <CustomNavbar
            authState={authState}
            appearance="subtle"
            activeKey={activeKey}
            onSelect={setActiveKey}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default UserHeader;
