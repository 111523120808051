import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Text, Carousel, Rate } from "rsuite";
import ProductCard from "../../../components/ProductCard/ProductCard";
import ProductsService from "../../../services/products.service";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../../stores/appSlice";
import "./home.css";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../../../utilities/ScrollToTop";
import reviewService from "../../../services/review.service";
import { formatDate } from "../../../utilities/formatDate";
import ReviewDetailsModal from "../../Reviews/ReviewDetailsModal";

const MAX_DESCRIPTION_LENGTH = 400;
const MAX_LINES = 6;

const Home = ({ pageTitle }) => {
  const [products, setProducts] = useState();
  const [reviews, setReviews] = useState();
  const [carousel, setCarousel] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  const getAllProducts = async () => {
    const products = await trackPromise(ProductsService.products());
    setProducts(products?.data?.data);
  };

  const getLatestReviews = async () => {
    const reviews = await trackPromise(reviewService.getLatestReviews());
    setReviews(reviews.data.data);
  };

  useEffect(() => {
    getAllProducts();
    getLatestReviews();
  }, []);

  const [expandedReviews, setExpandedReviews] = useState({});
  const [linesExceeded, setLinesExceeded] = useState({});

  const reviewRefs = useRef({});

  useEffect(() => {
    reviews?.forEach((review) => {
      const reviewId = review._id;
      const reviewElement = reviewRefs.current[reviewId];

      if (reviewElement) {
        const lineHeight = parseFloat(
          window.getComputedStyle(reviewElement).lineHeight
        );
        const reviewHeight = reviewElement.clientHeight;
        const numberOfLines = Math.round(reviewHeight / lineHeight);

        setLinesExceeded(numberOfLines > MAX_LINES);
      }
    });
  }, [reviews]);

  return (
    <>
      <ScrollToTop />
      <Container className="hero-carousel-container">
        {!carousel ? (
          <Container className="hero-img-container">
            <Row className="hero">
              <Row className="hero-text">
                <Col xs={22} md={18} lg={11} className="hero-mar">
                  <Text size="xxl">Best Quality Products</Text>
                  <Text weight="medium" className="hero-main-text">
                    Revitalize Skin & Hair with Nature’s Finest.
                  </Text>
                  <Link to="/products">
                    <Button appearance="primary" className="shop-button">
                      Shop now
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Row>
          </Container>
        ) : (
          <Carousel autoplay autoplayInterval={10000} className="custom-slider">
            <div className="hero-img-container">
              <Row className="hero">
                <Row className="hero-text">
                  <Col xs={22} md={18} lg={11} className="hero-mar">
                    <Text size="xxl">Best Quality Products</Text>
                    <Text weight="medium" className="hero-main-text">
                      Revitalize Skin & Hair with Nature’s Finest.
                    </Text>
                    <Link to="/products">
                      <Button appearance="primary" className="shop-button">
                        Shop now
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Row>
            </div>
            <div className="hero-img-container2">
              <Row className="hero">
                <Row className="hero-text">
                  <Col xs={22} md={18} lg={11} className="hero-mar">
                    <Text size="xxl">Pure Natural Soaps</Text>
                    <Text weight="medium" className="hero-main-text">
                      Revitalize Skin & Hair with Nature’s Finest.
                    </Text>
                    <Link to="/products">
                      <Button
                        color="red"
                        appearance="primary"
                        className="shop-button"
                      >
                        Shop now
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Row>
            </div>
          </Carousel>
        )}
      </Container>

      <Container>
        <Row className="products">
          <Col xs={24}>
            <div className="featured-head">Featured Products</div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="featured-products">
          <Col xs={22} md={20}>
            <Row gutter={15}>
              {products?.map((product) => (
                <Col xs={12} key={product._id} md={8} lg={6}>
                  <ProductCard product={product} slice={22} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>

      {reviews?.length > 0 && (
        <Container className="rev-con">
          <Row className="featured-products">
            <Col xs={22} md={12} className="review-carousel-container">
              <div className="featured-head">Client Reviews</div>
              <Carousel
                showNav={true}
                autoplay
                autoplayInterval={10000}
                activeIndex={openModal ? undefined : activeIndex}
                onSelect={(index) => {
                  setActiveIndex(index);
                }}
                className="custom-slider"
              >
                {reviews?.map((review, index) => {
                  const isExpanded = expandedReviews[review._id];
                  const showReadMore =
                    review.reviewDescription.length > MAX_DESCRIPTION_LENGTH ||
                    linesExceeded[review._id];

                  const reviewText = !isExpanded
                    ? review.reviewDescription.slice(0, 600)
                    : review.reviewDescription;

                  return (
                    <div key={review._id} className="review-slide">
                      <div className="reviews-card">
                        <Rate
                          defaultValue={review.rating}
                          readOnly
                          color="yellow"
                          className="reviews-stars "
                        />
                        <div>
                          <Text size="lg" weight="bold">
                            {review.reviewTitle}
                          </Text>
                          <div
                            ref={(el) => (reviewRefs.current[review._id] = el)}
                            className="reviews-text"
                            dangerouslySetInnerHTML={{
                              __html: reviewText,
                            }}
                            style={{
                              maxHeight: isExpanded ? "none" : "400px",
                              overflow: isExpanded ? "visible" : "hidden",
                              WebkitLineClamp: isExpanded ? "none" : MAX_LINES,
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                            }}
                          ></div>
                          {showReadMore && (
                            <Button
                              size="xs"
                              appearance="link"
                              color="green"
                              onClick={() => {
                                setOpenModal(true);
                                setSelectedReviewId(review._id);
                                setActiveIndex(index);
                              }}
                            >
                              Read more
                            </Button>
                          )}
                        </div>
                        <div className="reviews-user-info">
                          <div className="user-details">
                            <Text weight="bold" className="user-name">
                              {`${review.user.firstName} ${review.user.lastName}`}
                            </Text>
                            {review.hadOrdered && (
                              <span className="certified-user">
                                Certified User
                              </span>
                            )}
                          </div>
                          <div className="review-date">
                            {formatDate(review.updatedAt)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </Col>
          </Row>
          {selectedReviewId && (
            <ReviewDetailsModal
              dontShowActions={true}
              review={reviews.find((r) => r._id === selectedReviewId)}
              isOpen={openModal}
              onClose={() => {
                setActiveIndex(activeIndex);
                setOpenModal(false);
              }}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default Home;
