import React, { useEffect, useState } from "react";
import { Panel, Button, Tag } from "rsuite";
import "./review.css";
import { FaPen, FaTrash } from "react-icons/fa";
import { MdOutlineStar } from "react-icons/md";
import { formatDate } from "../../utilities/formatDate";
import { Link } from "react-router-dom";

const ReviewCarddo = ({
  review,
  onEdit,
  onDelete,
  showEditBtn,
  product,
  admin,
  onIgnore,
  showStatus,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowToggle, setShouldShowToggle] = useState(false);
  const MAX_DESCRIPTION_HEIGHT = 94;

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.visibility = "hidden";
    tempDiv.style.height = "auto";
    tempDiv.style.width = "100%";
    tempDiv.innerHTML = review.reviewDescription;

    document.body.appendChild(tempDiv);
    const fullHeight = tempDiv.scrollHeight;

    setShouldShowToggle(fullHeight > MAX_DESCRIPTION_HEIGHT);

    document.body.removeChild(tempDiv);
  }, [review]);

  return (
    <Panel className="reviews-card">
      <div className="reviews-header">
        <div className="reviews-title">
          <div className="mb-flex">
            <div className="rating" title="rating">
              {review?.rating} <MdOutlineStar color="white" />
            </div>
            {review?.reviewTitle && <h4>{review?.reviewTitle}</h4>}
          </div>
        </div>
        <div className="reviews-actions">
          {showStatus && (
            <Tag size="sm" color={review?.isActive ? "green" : "red"}>
              {review?.isActive ? "Approved" : "Yet to approve"}
            </Tag>
          )}
          <div>
            {!admin && showEditBtn && (
              <>
                <Button
                  title="edit"
                  appearance="link"
                  onClick={() => onEdit(review)}
                >
                  <FaPen className="prod-color" />
                </Button>
                <Button title="delete" appearance="link" onClick={onDelete}>
                  <FaTrash color="red" />
                </Button>
              </>
            )}
            {onIgnore && showEditBtn && (
              <Button appearance="link" onClick={() => onIgnore(review)}>
                {review.isActive ? "Ignore" : "Activate"}
              </Button>
            )}
          </div>
        </div>
      </div>

      {review.reviewDescription && (
        <div className="reviews-text">
          <div
            className={`reviews-description ${
              isExpanded ? "expanded" : "collapsed"
            }`}
            style={{
              maxHeight: isExpanded ? "none" : `${MAX_DESCRIPTION_HEIGHT}px`,
              overflow: "hidden",
            }}
            dangerouslySetInnerHTML={{
              __html: review.reviewDescription,
            }}
          ></div>
          {shouldShowToggle && (
            <span
              onClick={toggleExpand}
              className="prod-color"
              style={{ fontSize: "14px", cursor: "pointer" }}
            >
              {isExpanded ? "Show Less" : "Read More"}
            </span>
          )}
        </div>
      )}

      {product && (
        <Link to={`/products/${review.product._id}`}>
          <h6 className="prod-color">{review?.product?.name}</h6>
        </Link>
      )}

      <div className="reviews-footer">
        <div className="reviewer-info">
          <div>
            {review?.user?.firstName} {review?.user?.lastName} -{" "}
            {formatDate(review?.updatedAt)}
          </div>
        </div>
        {review?.hadOrdered && (
          <div className="certified-buyer">
            <span>Certified Buyer</span>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default ReviewCarddo;
