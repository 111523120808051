import React from "react";
import { Modal, Button } from "rsuite";

const IgnoreConfirmationModal = ({ isOpen, onClose, onConfirm, activate }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Confirm {activate ? "activate" : "ignore"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to {activate ? "activate" : "ignore"} this
          review?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="subtle" onClick={onClose}>
          Cancel
        </Button>
        <Button appearance="primary" onClick={onConfirm}>
          {activate ? "Activate" : "Ignore"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IgnoreConfirmationModal;
