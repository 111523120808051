import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  Content,
  Panel,
  List,
  FlexboxGrid,
  Row,
  Col,
  Timeline,
  Button,
  Text,
  Message,
} from "rsuite";
import OrdersService from "../../../services/orders.service";
import { useParams, useNavigate } from "react-router";
import EnvConfig from "../../../envConfig";
import ReturnModal from "./ReturnModal";
import { setRouteData } from "../../../stores/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate, formatDateTime } from "../../../utilities/formatDate";
import { trackPromise } from "react-promise-tracker";
import ScrollToTop from "../../../utilities/ScrollToTop";
import { generateInvoicePDF } from "../../../utilities/generateInvoice";
import invoiceService from "../../../services/invoice.service";
import "./myOrders.css";
import moment from "moment";
import { formatAmount } from "../../../utilities/formatAmount";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import { toast } from "react-toastify";

const OrderDetailsPage = ({ pageTitle }) => {
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [invoice, setInvoice] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [modalType, setModalType] = useState("");
  const authState = useSelector((state) => state.authState);
  const adminAddress = authState?.user?.addresses?.[0];

  const getOrderDetails = async () => {
    try {
      const resp = await trackPromise(OrdersService.getOrderById(id));
      setOrder(resp?.data?.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch order details:", error);
    }
  };

  const getInvoiceDetails = async () => {
    try {
      const resp = await invoiceService.byOrder(id);
      setInvoice(resp?.data?.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch invoice details:", error);
    }
  };

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  useEffect(() => {
    getOrderDetails();
    getInvoiceDetails();
  }, [id]);

  useEffect(() => {
    if (order?.status === "delivered") {
      setModalType("return");
    } else {
      setModalType("cancel");
    }
  }, [order?.status]);

  const handleOpenModal = (itemId) => {
    setSelectedItemId(itemId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItemId(null);
  };

  const date1 = moment(order?.deliveryDate);
  const date2 = moment();

  const diffInDays = date2.diff(date1, "days");

  return (
    <Container className="order-details-bg">
      <ScrollToTop />
      <Row>
        <Col xs={22} md={20} className="order-details-page">
          <FlexboxGrid justify="space-between" align="middle">
            <FlexboxGrid.Item>
              <h3>
                <span className="my-order-det-head">Order Id # </span>{" "}
                {order?.orderId}
              </h3>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item>
              <Button
                onClick={() => navigate("/place-orders")}
                appearance="ghost"
                className="back-primary"
              >
                <ArowBackIcon />
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <Row>
            <Col xs={24} lg={16}>
              {order?.status === "cancelled" ? (
                <Message type="error" showIcon className="dets-i">
                  <strong>
                    Order {order?.status} by {order?.canceledBy}
                  </strong>
                </Message>
              ) : (
                ""
              )}
              <Panel>
                <FlexboxGrid className="address-total">
                  <FlexboxGrid.Item colspan={12}>
                    <h4 className="my-order-det-head">Delivery Address</h4>
                    <div>
                      <div>
                        {order?.deliveryAddress?.city}{" "}
                        {order?.deliveryAddress?.state}{" "}
                        {order?.deliveryAddress?.pincode}
                      </div>
                      <p>{order?.deliveryAddress?.lane}</p>
                      <p>{order?.deliveryAddress?.landmark}</p>
                      <p>{order?.deliveryAddress?.phone}</p>
                    </div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12} className="total-check">
                    <Row>
                      <Col xs={17}>
                        <p className="order-details-head">Subtotal (₹)</p>
                      </Col>
                      <Col xs={7}>
                        <p className="order-details-prod">
                          <strong>{formatAmount(order?.amount)}</strong>
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={17}>
                        <p className="order-details-head">Tax (₹)</p>
                      </Col>
                      <Col xs={7}>
                        <p className="order-details-prod">
                          <strong>{formatAmount(order?.tax)}</strong>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={17}>
                        <p className="order-details-head">Charge (₹)</p>
                      </Col>
                      <Col xs={7}>
                        <p className="order-details-prod">
                          <strong>{formatAmount(order?.charge)}</strong>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={17}>
                        <p className="order-details-head">Discount (₹)</p>
                      </Col>
                      <Col xs={7}>
                        <p className="order-details-prod">
                          <strong>-{formatAmount(order?.discount)}</strong>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={17}>
                        <p className="order-details-head">Total (₹)</p>
                      </Col>
                      <Col xs={7}>
                        <p className="order-details-prod">
                          <strong>{formatAmount(order?.total)}</strong>
                        </p>
                      </Col>
                    </Row>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
                <h4 className="my-order-det-head">Ordered Items</h4>
                <List className="order-prod-list">
                  {order &&
                    order?.items?.map((item, index) => (
                      <List.Item key={index}>
                        <FlexboxGrid justify="space-between">
                          <FlexboxGrid.Item as={Col} xs={11} md={14}>
                            <Link to={`/products/${item?.product?._id}`}>
                              <img
                                className="order-prod-image"
                                src={`${EnvConfig.MediaBase}/${item?.product?.assetsDir}/${item?.product?.prodImages?.[0]}`}
                                alt={item.name}
                              />
                            </Link>
                            <Link to={`/products/${item?.product?._id}`}>
                              <h5 className="prod-hover prod-color">
                                {item?.product?.name}
                              </h5>
                            </Link>
                            <p>{item?.product?.size}</p>
                          </FlexboxGrid.Item>

                          <FlexboxGrid.Item as={Col} xs={13} md={10}>
                            <FlexboxGrid justify="space-between">
                              {/* <FlexboxGrid.Item colspan={12}>
                                <Link to={`/products/${item?.product?._id}`}>
                                  <h5 className="prod-hover prod-color">
                                    {item?.product?.name}
                                  </h5>
                                </Link>
                                <p>{item?.product?.size}</p>
                              </FlexboxGrid.Item> */}
                              <FlexboxGrid.Item colspan={24}>
                                <Row>
                                  <Col xs={16}>
                                    <p className="order-details-head">
                                      Price (₹)
                                    </p>
                                  </Col>
                                  <Col xs={8}>
                                    <p className="order-details-prod">
                                      <strong>
                                        {item?.price} x {item?.quantity}
                                      </strong>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={16}>
                                    <p className="order-details-head">
                                      Subtotal (₹)
                                    </p>
                                  </Col>
                                  <Col xs={8}>
                                    <p className="order-details-prod">
                                      <strong>
                                        {formatAmount(item?.amount)}
                                      </strong>
                                    </p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xs={16}>
                                    <p className="order-details-head">
                                      Tax (₹)
                                    </p>
                                  </Col>
                                  <Col xs={8}>
                                    <p className="order-details-prod">
                                      <strong>
                                        {formatAmount(item?.taxAmt)}
                                      </strong>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={16}>
                                    <p className="order-details-head">
                                      Discount (₹)
                                    </p>
                                  </Col>
                                  <Col xs={8}>
                                    <p className="order-details-prod">
                                      <strong>
                                        -{formatAmount(item?.discount)}
                                      </strong>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={16}>
                                    <p className="order-details-head">
                                      Total (₹)
                                    </p>
                                  </Col>
                                  <Col xs={8}>
                                    <p className="order-details-prod">
                                      <strong>
                                        {formatAmount(item?.total)}
                                      </strong>
                                    </p>
                                  </Col>
                                </Row>
                              </FlexboxGrid.Item>
                            </FlexboxGrid>
                          </FlexboxGrid.Item>
                        </FlexboxGrid>
                      </List.Item>
                    ))}
                </List>
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={24} className="order-details-prod">
                    <h5>
                      <span className="grand">Grand Total (₹) </span>
                      <span className="ml-6">{formatAmount(order?.total)}</span>
                    </h5>
                  </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="user-note-total">
                  <FlexboxGrid.Item colspan={24}>
                    <p>
                      User Note <strong>{order?.userNote}</strong>
                    </p>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={24}>
                    <p>
                      Seller Note <strong>{order?.sellerNote}</strong>
                    </p>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={24}>
                    <p>
                      Payment Mode{" "}
                      <strong className="capitalize1">
                        {order?.paymentMode}
                      </strong>
                    </p>
                  </FlexboxGrid.Item>
                  {order?.paymentMode === "upi" && (
                    <FlexboxGrid.Item colspan={24}>
                      <p>
                        Transaction Id{" "}
                        <strong className="capitalize1">
                          {order?.transactionId}
                        </strong>
                      </p>
                    </FlexboxGrid.Item>
                  )}
                  {order?.status === "cancelled" && (
                    <FlexboxGrid.Item colspan={24}>
                      <div className="flex12">
                        <div>Cancel Reason </div>
                        <strong>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: order?.cancelReason,
                            }}
                          ></div>
                        </strong>
                      </div>
                    </FlexboxGrid.Item>
                  )}
                  {order?.status === "returned" && (
                    <FlexboxGrid.Item colspan={24}>
                      <p>
                        Status{" "}
                        <strong>
                          Order {order?.status} by {order?.canceledBy}
                        </strong>
                      </p>
                    </FlexboxGrid.Item>
                  )}
                  <FlexboxGrid.Item colspan={24}>
                    <p>
                      Estimated Delivery Date{" "}
                      <strong>
                        {order?.deliveryDate
                          ? formatDate(order?.deliveryDate)
                          : ""}
                      </strong>
                    </p>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
                {order?.status === "returned" ? (
                  <div className="order-return">
                    <h4 className="my-order-det-head">Order Returned</h4>
                    <List className="order-prod-list">
                      <List.Item>
                        <FlexboxGrid justify="space-between">
                          <FlexboxGrid.Item as={Col} xs={7} md={4}>
                            <img
                              className="order-prod-image"
                              src={`${EnvConfig.MediaBase}/${order?.assetsDir}/${order?.returnImages?.[0]}`}
                              alt={order?.name}
                              onClick={() =>
                                window.open(
                                  `${EnvConfig.MediaBase}/${order?.assetsDir}/${order?.returnImages?.[0]}`,
                                  "_blank"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </FlexboxGrid.Item>
                          <FlexboxGrid.Item as={Col} xs={17} md={20}>
                            <FlexboxGrid justify="space-between">
                              <FlexboxGrid.Item colspan={12}>
                                <div
                                  className="retuurm"
                                  dangerouslySetInnerHTML={{
                                    __html: order?.returnReason,
                                  }}
                                ></div>
                              </FlexboxGrid.Item>
                            </FlexboxGrid>
                          </FlexboxGrid.Item>
                        </FlexboxGrid>
                      </List.Item>
                    </List>
                  </div>
                ) : (
                  ""
                )}
              </Panel>
            </Col>

            <Col xs={24} lg={7}>
              <Panel>
                <h4 className="my-order-det-head">Order History</h4>
                <Timeline isItemActive={Timeline.ACTIVE_FIRST}>
                  {order?.statusHistory && order.statusHistory.length > 0 ? (
                    order.statusHistory.map((status, index) => (
                      <Timeline.Item
                        key={index}
                        className={
                          status.active === true ? "completed-step" : ""
                        }
                      >
                        <p className="capitalize1">
                          <strong>{status?.status}</strong>
                        </p>
                        <p>{status.details}</p>
                        <p>{status.date ? formatDateTime(status.date) : ""}</p>
                      </Timeline.Item>
                    ))
                  ) : (
                    <Timeline.Item color="gray">
                      <p>{order?.status}</p>
                      <p>{formatDateTime(order?.createdAt)}</p>
                    </Timeline.Item>
                  )}
                </Timeline>
                {invoice !== null && order?.status !== "cancelled" ? (
                  <button
                    onClick={() => generateInvoicePDF(invoice)}
                    className="down-button mt-10 btn-green"
                  >
                    Download Invoice
                  </button>
                ) : (
                  ""
                )}
              </Panel>
            </Col>
          </Row>
          <Row gutter={10} className="return-order-button">
            {order?.status === "delivered" && diffInDays < 7 ? (
              <div className="return">
                <Button
                  onClick={() => handleOpenModal()}
                  color="red"
                  appearance="primary"
                >
                  Return Order
                </Button>
                <div className="returns-policy">
                  <p>Returns are accepted within 7 days</p>
                </div>
              </div>
            ) : null}
            {order?.status === "placed" || order?.status === "processing" ? (
              <Col className="return-order-button">
                <Button
                  onClick={() => {
                    handleOpenModal();
                  }}
                  color="red"
                  appearance="primary"
                >
                  Cancel Order
                </Button>
              </Col>
            ) : null}
          </Row>
          {isModalOpen && (
            <ReturnModal
              fetch={getOrderDetails}
              itemId={order._id}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalType={modalType === "cancel" ? "Cancel" : "Return"}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OrderDetailsPage;
