import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import EnvConfig from "../../envConfig";
import "./productGallery.css";

const ProductGallery = ({ images, assetsDir, switchView }) => {
  const galleryImages = images?.length
    ? images.map((imageName) => ({
        original: `${EnvConfig?.MediaBase}/${assetsDir}/${imageName}`,
        thumbnail: `${EnvConfig?.MediaBase}/${assetsDir}/${imageName}`,
        originalHeight: switchView ? 200 : 600,
        originalWidth: switchView ? 200 : 1000,
      }))
    : [];

  return (
    <div className="product-gallery-container">
      <ImageGallery
        items={galleryImages}
        showPlayButton={false}
        showFullscreenButton={true}
        slideOnThumbnailOver={false}
        useBrowserFullscreen={true}
        thumbnailPosition={switchView ? "bottom" : "left"}
        showThumbnails
        showNav={true}
        fullscreen
        lazyLoad
        useTranslate3D
      />
    </div>
  );
};

export default ProductGallery;
