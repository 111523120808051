// Footer.js
import React from "react";
import { Container, Row, Col, Heading } from "rsuite";
import "./userFooter.css";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Col xs={20} className="footer-container">
          <Row>
            <Col xs={24} md={6} className="footer-logo-col">
              <div>
                <img
                  src="https://websitedemos.net/plant-store-02/wp-content/uploads/sites/410/2020/06/plants-store-logo-green.svg"
                  className="footer-logo"
                />
              </div>
              <div>
                <Heading level={3} weight="bold">
                  Ayur Harmony
                </Heading>
              </div>
              <div className="footer-icons">
                <div className="foot-icon">
                  <FaFacebookF color="white" />
                </div>

                <div className="foot-icon">
                  <FaInstagram color="white" />
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="footer-col">
              <div className="footer-header">Quick Links</div>
              <div className="footer-links">
                <div className="foot-link">
                  <Link to="/about">Know more About Us</Link>
                </div>
                <div className="foot-link">
                  <Link to="/products">Visit Store</Link>
                </div>
                <div className="foot-link">
                  <Link to="/contact">Let's Connect</Link>
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="footer-col">
              <div className="footer-header">Important Links</div>
              <div className="footer-links">
                <div className="foot-link">
                  <Link to="#">Privacy Policy</Link>
                </div>
                <div className="foot-link">
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="footer-col">
              <div className="footer-header">
                Get In Touch With Us For Premium Healthcare Soaps & Shampoos
              </div>
              <div className="footer-links">
                <div>
                  Experience the perfect blend of nature and science with our
                  healthcare soaps and shampoos. Formulated to nourish and
                  protect, our products are designed to meet the unique needs of
                  your skin and hair, providing gentle care without compromising
                  on quality.
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <hr />
        <Col xs={20} className="copyright">
          <Row className="copy">
            <Col xs={24} md={12}>
              Copyright © 2024 Ayur Harmony
            </Col>
            <Col xs={24} md={12} className="powered">
              Powered by{" "}
              <Link className="prod-color" to="/#">
                Ayur Harmony
              </Link>
            </Col>
          </Row>
        </Col>
      </Container>
    </footer>
  );
};

export default Footer;
