import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "rsuite";
import { LuPalmtree } from "react-icons/lu";
import "./header.css";

const Brand = (props) => {
  return (
    <Stack className="brand" {...props}>
      <Link to="/">
        <span className="brand-container" style={{ marginLeft: 14 }}>
          <LuPalmtree />
          Ayur Harmony
        </span>
      </Link>
    </Stack>
  );
};

export default Brand;
