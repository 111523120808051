import React, { useEffect, useState } from "react";
import { Container, Panel, Grid, Row, Col, FlexboxGrid, Button } from "rsuite";
import { Link, useNavigate, useParams } from "react-router-dom";
import userService from "../../services/user.service";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { useDispatch } from "react-redux";
import ScrollToTop from "../../utilities/ScrollToTop";
import "./user.css";
import EnvConfig from "../../envConfig";
import { toast } from "react-toastify";
import ArowBackIcon from "@rsuite/icons/ArowBack";

const UserDetails = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [user, set_userDetails] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const resp = await trackPromise(userService.getUserById(id));
        const { data } = resp;
        if (data.success) {
          set_userDetails(data.data);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message);
        console.error("Fetch user details catch => ", err);
      }
    }
    fetchUserDetails();
  }, [id]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <ScrollToTop />
      <Panel className="user-details-panel">
        <FlexboxGrid justify="end">
          <FlexboxGrid.Item>
            <Button
              onClick={() => navigate("/users")}
              appearance="ghost"
              color="blue"
            >
              <ArowBackIcon />
            </Button>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid justify="space-between">
          <FlexboxGrid.Item colspan={10}>
            <h3>User Information</h3>
            <Row className="user-info-details">
              <Col xs={24} className="profile-image-col">
                <img
                  src={`${EnvConfig?.MediaBase}/${user.assetsDir}/${user.profileImage}`}
                  alt="Profile"
                  className="profile-image"
                />
              </Col>
              <Col xs={24} className="user-info-col">
                <Row className="user-info-row">
                  <Col xs={6} className="user-info-label">
                    First Name
                  </Col>
                  <Col xs={14} className="user-info-value">
                    <strong>{user.firstName}</strong>
                  </Col>
                </Row>
                <Row className="user-info-row">
                  <Col xs={6} className="user-info-label">
                    Last Name
                  </Col>
                  <Col xs={14} className="user-info-value">
                    <strong>{user.lastName}</strong>
                  </Col>
                </Row>
                <Row className="user-info-row">
                  <Col xs={6} className="user-info-label">
                    Email
                  </Col>
                  <Col xs={14} className="user-info-value">
                    <strong>{user.email}</strong>
                  </Col>
                </Row>
                <Row className="user-info-row">
                  <Col xs={6} className="user-info-label">
                    Phone
                  </Col>
                  <Col xs={14} className="user-info-value">
                    <strong>{user.phone}</strong>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={13}>
            <Row className="user-details-row">
              <Col xs={24} className="addresses-col">
                <h3>Addresses</h3>
                <div className="addresses-container">
                  {user.addresses.length === 0 && <div>No addresses found</div>}
                  {user.addresses.map((address) => (
                    <div className="address-item" key={address._id}>
                      <Row className="address-row">
                        <Col xs={6} className="address-label">
                          Lane
                        </Col>
                        <Col xs={14} className="address-value">
                          <strong>{address.lane}</strong>
                        </Col>
                      </Row>
                      <Row className="address-row">
                        <Col xs={6} className="address-label">
                          City
                        </Col>
                        <Col xs={14} className="address-value">
                          <strong>{address.city}</strong>
                        </Col>
                      </Row>
                      <Row className="address-row">
                        <Col xs={6} className="address-label">
                          State
                        </Col>
                        <Col xs={14} className="address-value">
                          <strong>{address.state}</strong>
                        </Col>
                      </Row>
                      <Row className="address-row">
                        <Col xs={6} className="address-label">
                          Pincode
                        </Col>
                        <Col xs={14} className="address-value">
                          <strong>{address.pincode}</strong>
                        </Col>
                      </Row>
                      <Row className="address-row">
                        <Col xs={6} className="address-label">
                          Landmark
                        </Col>
                        <Col xs={14} className="address-value">
                          <strong>{address.landmark}</strong>
                        </Col>
                      </Row>
                      <Row className="address-row">
                        <Col xs={6} className="address-label">
                          Phone
                        </Col>
                        <Col xs={14} className="address-value">
                          <strong>{address.phone}</strong>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Panel>
    </Container>
  );
};

export default UserDetails;
