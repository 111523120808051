import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Pagination,
  InputGroup,
  useToaster,
  Message,
  Affix,
  Button,
  Form,
  Modal,
  FlexboxGrid,
  SelectPicker,
} from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { trackPromise } from "react-promise-tracker";
import { Cell, HeaderCell } from "rsuite-table";
import Column from "rsuite/esm/Table/TableColumn";
import SearchIcon from "@rsuite/icons/Search";
import { IconButton } from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import { setRouteData } from "../../stores/appSlice";
import subCategoryService from "../../services/subCategory.service";
import { useDispatch } from "react-redux";
import CategoryService from "../../services/Category.Service";
import { toast } from "react-toastify";
import ScrollToTop from "../../utilities/ScrollToTop";
import "./category.css";
import { isSmallScreen, useSmallScreen } from "../../utilities/useWindowSize";
import SubCategoryDeleteModal from "../../components/DeleteModal/SubCategoryDeleteModal";
import { FaSquareCheck, FaSquareXmark } from "react-icons/fa6";
import { VscCircleSlash } from "react-icons/vsc";

const SubCategories = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(6);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [formValue, setFormValue] = useState({
    categoryName: "",
  });
  const [pageError, setPageError] = useState("");
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [disable, setDisable] = useState(false);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    getSubCategories();
    getCategoryOptions();
  }, []);

  const getSubCategories = async () => {
    try {
      const resp = await trackPromise(subCategoryService.getSubCategoryList());
      setCategories(resp.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch categories", error);
      setPageError("Failed to fetch categories.");
    }
  };

  const getCategoryOptions = async () => {
    try {
      const resp = await trackPromise(CategoryService.getCategoryList());
      const data = resp?.data.data?.map((cat) => ({
        value: cat?._id,
        label: cat?.categoryName,
      }));

      setCategoryOptions(data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch category options", error);
    }
  };

  const getData = () => {
    let filteredCategories = categories.filter((category) =>
      category?.subCatName?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (sortColumn && sortType) {
      filteredCategories.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        return sortType === "asc" ? x - y : y - x;
      });
    }

    const start = limit * (page - 1);
    const end = start + limit;
    return filteredCategories.slice(start, end);
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const openDeleteModal = (itemId) => {
    const subCategory = categories.find((cat) => cat._id === itemId);
    setStatus(subCategory?.isActive);
    setIsModalOpen(true);
    setSelectedItemId(itemId);
  };
  const closeDeleteModal = (itemId) => {
    setIsModalOpen(false);
  };

  const handleOpenModal = (itemId) => {
    setSelectedItemId(itemId);
    setIsAddOpen(true);
    if (itemId) {
      const subCategory = categories.find((cat) => cat._id === itemId);
      setStatus(subCategory.isActive);

      if (subCategory) {
        setFormValue({
          category: subCategory.category._id, // Set the selected category ID
          subCatName: subCategory.subCatName,
        });
      }
    } else {
      setFormValue({ category: "", subCatName: "", isActive: false });
    }
  };

  const handleCloseModal = () => {
    setIsAddOpen(false);
    setSelectedItemId(null);
  };

  const handleSubmit = async () => {
    try {
      const selectedCategory = categoryOptions.find(
        (cat) => cat.value === formValue.category
      );

      if (!selectedCategory) {
        toast.error("Please select a category");
        return;
      }

      const payload = {
        category: selectedCategory.value,
        subCatName: formValue.subCatName,
      };

      if (selectedItemId) {
        const resp = await trackPromise(
          subCategoryService.updateSubCategory(selectedItemId, payload)
        );
        toast.success(resp.data.message || "SubCategory updated successfully");
      } else {
        const resp = await trackPromise(
          subCategoryService.createSubCategory(payload)
        );
        toast.success(resp.data.message || "SubCategory created successfully");
      }
      getSubCategories();
      handleCloseModal();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error(error);
    }
  };

  const isSmallScreen = useSmallScreen(768);

  return (
    <Container className="cate-cot">
      <ScrollToTop />
      <div className="cate-box">
        <Affix>
          <FlexboxGrid
            style={{
              width: "100%",
              backgroundColor: "white",
              padding: "10px",
            }}
            justify="space-between"
            className="products-header"
          >
            <FlexboxGrid.Item className="filters-row">
              <InputGroup inside>
                <Input
                  placeholder="Search ..."
                  value={searchQuery}
                  onChange={(value, event) =>
                    setSearchQuery(event.target.value)
                  }
                />
                <InputGroup.Button>
                  <SearchIcon />
                </InputGroup.Button>
              </InputGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <Button
                appearance="primary"
                size="md"
                onClick={() => handleOpenModal(null)}
              >
                Add SubCategory
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Affix>
        <Row gutter={0}>
          <Col xs={24}>
            <Table
              affixHeader={60}
              autoHeight
              wordWrap="break-word"
              data={getData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
            >
              <Column width={180} sortable>
                <HeaderCell>SubCategory Name</HeaderCell>
                <Cell dataKey="subCatName"></Cell>
              </Column>
              <Column width={180} sortable>
                <HeaderCell>Category Name</HeaderCell>
                <Cell dataKey="category?.categoryName">
                  {(rowData) => rowData.category?.categoryName}
                </Cell>
              </Column>

              <Column flexGrow={2}>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="isActive">
                  {(rowData) => (
                    <div>
                      {rowData.isActive ? (
                        <IconButton
                          className="nohiver"
                          title="status"
                          icon={<FaSquareCheck color="green" />}
                        />
                      ) : (
                        <IconButton
                          className="nohiver"
                          title="status"
                          icon={<FaSquareXmark color="red" />}
                        />
                      )}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={130} align="center">
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "0.625rem",
                      }}
                    >
                      <IconButton
                        title="edit"
                        icon={<EditIcon className="icon-blue" />}
                        onClick={() => handleOpenModal(rowData._id)}
                      />
                      <IconButton
                        title="delete"
                        icon={<TrashIcon color="red" />}
                        onClick={() => {
                          setDisable(false);
                          openDeleteModal(rowData._id);
                        }}
                      />
                      <IconButton
                        title="disable"
                        icon={
                          !rowData.isActive ? (
                            <IoIosCheckmarkCircleOutline />
                          ) : (
                            <VscCircleSlash />
                          )
                        }
                        onClick={() => {
                          setDisable(true);
                          openDeleteModal(rowData._id);
                        }}
                      />
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
          </Col>
        </Row>
        <div className="pagination-0">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size={isSmallScreen ? "xs" : "md"}
            layout={[
              "total",
              "-",
              `${!isSmallScreen ? "limit" : ""}`,
              `${!isSmallScreen ? "|" : ""}`,
              "pager",
              `${!isSmallScreen ? "|" : ""}`,
              `${!isSmallScreen ? "skip" : ""}`,
            ]}
            total={categories.length}
            limitOptions={[5, 10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        <Modal open={isAddOpen} onClose={handleCloseModal} size="sm">
          <Modal.Header>
            <Modal.Title>
              {selectedItemId ? "Edit SubCategory" : "Add SubCategory"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group controlId="category">
                <Form.ControlLabel>Category</Form.ControlLabel>
                <SelectPicker
                  data={categoryOptions}
                  value={formValue.category || ""}
                  onChange={(value) =>
                    setFormValue((prev) => ({
                      ...prev,
                      category: value,
                    }))
                  }
                  placeholder="Select a category"
                  searchable={false}
                  style={{ width: 400 }}
                />
              </Form.Group>
              <Form.Group controlId="subCatName">
                <Form.ControlLabel>SubCategory Name</Form.ControlLabel>
                <Input
                  placeholder="SubCategory Name"
                  value={formValue.subCatName}
                  onChange={(value) =>
                    setFormValue((prev) => ({ ...prev, subCatName: value }))
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSubmit} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleCloseModal} appearance="default">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {isModalOpen && (
        <SubCategoryDeleteModal
          itemId={selectedItemId}
          onClose={closeDeleteModal}
          isOpen={openDeleteModal}
          fetch={getSubCategories}
          disable={disable}
          status={status}
        />
      )}
    </Container>
  );
};

export default SubCategories;
