import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Avatar,
  Input,
  Button,
  Pagination,
  Rate,
  InputGroup,
  useToaster,
  Message,
  Affix,
  FlexboxGrid,
} from "rsuite";
import "./products.css";
import { FaSquareCheck, FaSquareXmark } from "react-icons/fa6";
import productsService from "../../services/products.service";
import { Link } from "react-router-dom";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import BlockRoundIcon from "@rsuite/icons/BlockRound";
import Column from "rsuite/esm/Table/TableColumn";
import SearchIcon from "@rsuite/icons/Search";
import EditIcon from "@rsuite/icons/Edit";
import { Cell, HeaderCell } from "rsuite-table";
import { trackPromise } from "react-promise-tracker";
import EnvConfig from "../../envConfig";
import { IconButton, Icon } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import { setRouteData } from "../../stores/appSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../components/DeleteModal/Delete.Modal";
import { toast } from "react-toastify";
import ScrollToTop from "../../utilities/ScrollToTop";
import { isSmallScreen, useSmallScreen } from "../../utilities/useWindowSize";
import ProductDeleteModal from "../../components/DeleteModal/ProductDeleteModal";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { VscCircleSlash } from "react-icons/vsc";

const Products = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const toaster = useToaster();
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [pageError, set_pageError] = useState("");
  const [status, setStatus] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    // getUsers();
  }, []);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const resp = await trackPromise(productsService.getproducts());
      setProducts(resp.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch products", error);
    }
  };

  const getData = () => {
    let filteredProducts = products;
    if (searchQuery) {
      filteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    let sortedProducts = [...filteredProducts];
    if (sortColumn && sortType) {
      sortedProducts.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }

    const start = limit * (page - 1);
    const end = start + limit;
    const paginatedProducts = sortedProducts.slice(start, end);

    return paginatedProducts;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const handleOpenModal = (itemId) => {
    const product = products.find((product) => product._id === itemId);
    setStatus(product.isActive);
    setSelectedItemId(itemId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItemId(null);
  };

  const ImageCell = ({ rowData, ...props }) => {
    return (
      <Cell {...props} className="image-cell">
        <Link to={`/products/${rowData?._id}`}>
          <img
            src={`${EnvConfig?.MediaBase}/${rowData?.assetsDir}/${rowData?.prodImages[0]}`}
            alt="Product"
          />
        </Link>
      </Cell>
    );
  };

  const isSmallScreen = useSmallScreen(768);

  return (
    <Container className="produc-contains">
      <ScrollToTop />
      <div className="prodd">
        <Affix>
          <FlexboxGrid
            style={{
              width: "100%",
              backgroundColor: "white",
              padding: "10px",
            }}
            justify="space-between"
            className="products-header"
          >
            <FlexboxGrid.Item className="filters-row">
              <InputGroup inside>
                <Input
                  placeholder="Search ..."
                  value={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                />
                <InputGroup.Button>
                  <SearchIcon />
                </InputGroup.Button>
              </InputGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <Link
                to={{
                  pathname: "/add-product",
                  state: { products: products },
                }}
              >
                <Button appearance="primary" size="md">
                  Add Product
                </Button>
              </Link>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Affix>
        <Row gutter={0}>
          <Col xs={24} className="table-container">
            <Table
              affixHeader={65}
              className="table-tb"
              autoHeight
              wordWrap="break-word"
              data={getData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
            >
              <Column width={100} fixed resizable>
                <HeaderCell>Image</HeaderCell>
                <ImageCell dataKey="" />
              </Column>

              <Column resizable width={200} sortable>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name">
                  {(rowData) => (
                    <Link to={`/products/${rowData?._id}`}>{rowData.name}</Link>
                  )}
                </Cell>
              </Column>

              <Column width={200} resizable sortable>
                <HeaderCell>Category</HeaderCell>
                <Cell dataKey="subCategory.category.categoryName" />
              </Column>
              <Column width={200} resizable sortable>
                <HeaderCell>Sub-category</HeaderCell>
                <Cell dataKey="subCategory.subCatName" />
              </Column>
              <Column width={100} resizable sortable>
                <HeaderCell>Price (₹)</HeaderCell>
                <Cell dataKey="price" />
              </Column>

              <Column width={120} resizable sortable>
                <HeaderCell>Discount (₹)</HeaderCell>
                <Cell dataKey="discount" />
              </Column>

              <Column width={100} resizable sortable>
                <HeaderCell>Tax (₹)</HeaderCell>
                <Cell dataKey="tax" />
              </Column>

              <Column width={220} sortable>
                <HeaderCell>Ratings( No. of reviews )</HeaderCell>
                <Cell dataKey="rating">
                  {(rowData) => (
                    <>
                      <span>
                        {rowData.averageRating
                          ? rowData.averageRating.toFixed(1)
                          : 0}
                      </span>
                      <span>({rowData.reviewCount})</span>
                    </>
                  )}
                </Cell>
              </Column>

              <Column resizable width={100} sortable>
                <HeaderCell>Size</HeaderCell>
                <Cell dataKey="size" />
              </Column>

              <Column flexGrow={2}>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="isActive">
                  {(rowData) => (
                    <div>
                      {rowData.isActive ? (
                        <IconButton
                          title="status"
                          icon={<FaSquareCheck color="green" />}
                        />
                      ) : (
                        <IconButton
                          title="status"
                          icon={<FaSquareXmark color="red" />}
                        />
                      )}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column resizable width={130} align="center">
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "0.625rem",
                      }}
                    >
                      <Link to={`/product/edit/${rowData._id}`}>
                        <IconButton
                          title="edit"
                          icon={<EditIcon className="icon-blue" />}
                        />
                      </Link>
                      <IconButton
                        title="delete"
                        icon={<TrashIcon color="red" />}
                        onClick={() => {
                          setDisable(false);
                          handleOpenModal(rowData._id);
                        }}
                      />
                      <IconButton
                        title="disable"
                        icon={
                          !rowData.isActive ? (
                            <IoIosCheckmarkCircleOutline />
                          ) : (
                            <VscCircleSlash />
                          )
                        }
                        onClick={() => {
                          setDisable(true);
                          handleOpenModal(rowData._id);
                        }}
                      />
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
          </Col>
        </Row>
        <div className="pagination-0">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size={isSmallScreen ? "xs" : "md"}
            layout={[
              "total",
              "-",
              `${!isSmallScreen ? "limit" : ""}`,
              `${!isSmallScreen ? "|" : ""}`,
              "pager",
              `${!isSmallScreen ? "|" : ""}`,
              `${!isSmallScreen ? "skip" : ""}`,
            ]}
            total={products.length}
            limitOptions={[5, 10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {isModalOpen && (
          <ProductDeleteModal
            itemId={selectedItemId}
            onClose={handleCloseModal}
            isOpen={handleOpenModal}
            fetch={getProducts}
            status={status}
            disable={disable}
          />
        )}
      </div>
    </Container>
  );
};

export default Products;
