import React from "react";
import { Navigate } from "react-router-dom";
import { VscTable } from "react-icons/vsc";
import { MdDashboard } from "react-icons/md";
import { Icon } from "@rsuite/icons";
import { FaShoppingCart } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { MdCategory } from "react-icons/md";
import { MdOutlineReviews } from "react-icons/md";
import { RiSubwayLine } from "react-icons/ri";
import { FaBell } from "react-icons/fa";

// Layouts
import UserLayout from "./layouts/UserLayout";
import ProtectedLayout from "./layouts/ProtectedLayout";

// Pages
import Dashboard from "./pages/Dashboard/Dashboard";
import Products from "./pages/Products/Products.list";
import ProductDetail from "./pages/Products/Product.details";
import AddProduct from "./pages/Products/Product.add";
import Invoice from "./pages/Invoices/Invoice.list";
import Users from "./pages/Users/Users";
import UserDetails from "./pages/Users/UserDetails";
import OrderDetails from "./pages/Orders/Order.details";
import Order from "./pages/Orders/Order.list";
import Home from "./pages/User/Home/Home";
import Productslist from "./pages/User/Products/Products.list";
import ProductsDetails from "./pages/User/Products/Products.Details";
import Cart from "./pages/User/Cart/Cart";
import Checkout from "./pages/User/Checkout/Checkout";
import CreatePassword from "./pages/User/ForgotPassword/CreatePassword";
import ChangePassword from "./pages/User/ForgotPassword/ChangePassword";
import ProfileDisplay from "./pages/User/Profile/Profile.Display";
import UserProfile from "./pages/User/Profile/Profile.Edit";
import Categories from "./pages/Category/Categoy.List";
import SubCategories from "./pages/Category/SubCategory.List";

import ForgotPassword from "./pages/User/ForgotPassword/ForgotPassword";
import MyOrdersList from "./pages/User/MyOrders/MyOrders.list";
import OrderDetailsPage from "./pages/User/MyOrders/MyOrders.Details";
import About from "./pages/User/About/About";
import Contact from "./pages/User/Contact/Contact";
import TermsAndConditions from "./pages/User/TermsConditions/TermsAndConditions";
import MyReviews from "./pages/User/MyReviews/MyReviews";
import AllReviews from "./pages/User/Products/MoreReviews";
import Reviews from "./pages/Reviews/Reviews";
import Messages from "./pages/Messages/Messages";
import MessageDetails from "./pages/Messages/Messages.Details";
import EditProfile from "./pages/Profile/Admin.Profile";
import InvoiceDetails from "./pages/Invoices/Invoice.Details";

const USER_ROLES = { admin: "admin", user: "user" };

const AdminNavs = [
  {
    eventKey: "dashboard",
    icon: <Icon as={MdDashboard} />,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    eventKey: "products",
    icon: <Icon as={VscTable} />,
    title: "Products",
    to: "/products",
  },
  {
    eventKey: "orders",
    icon: <Icon as={FaShoppingCart} />,
    title: "Orders",
    to: "/orders",
  },
  {
    eventKey: "invoices",
    icon: <Icon as={FaFileInvoiceDollar} />,
    title: "Invoices",
    to: "/invoices",
  },
  {
    eventKey: "users",
    icon: <Icon as={FaUsers} />,
    title: "Users",
    to: "/users",
  },
  {
    eventKey: "reviews",
    icon: <Icon as={MdOutlineReviews} />,
    title: "Reviews",
    to: "/reviews",
  },
  {
    eventKey: "messages",
    icon: <Icon as={FaBell} />,
    title: "Messages",
    to: "/messages",
  },
  {
    eventKey: "categories",
    icon: <Icon as={MdCategory} />,
    title: "Categories",
    to: "/categories",
  },
  {
    eventKey: "subCategories",
    icon: <Icon as={RiSubwayLine} />,
    title: "SubCategories",
    to: "/subCategories",
  },
];

const AdminRoutes = [
  {
    path: "/",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="dashboard" />,
      },
      {
        path: "dashboard",
        element: <Dashboard pageTitle="Dashboard" />,
        id: "dashboard",
      },
      {
        path: "products",
        element: <Products pageTitle="Products" />,
        id: "products",
      },
      {
        path: "products/:id",
        element: <ProductDetail pageTitle="Product Details" />,
        id: "productDetail",
      },
      {
        path: "add-product",
        element: <AddProduct pageTitle="Add Product" />,
        id: "addProduct",
      },
      {
        path: "product/edit/:id",
        element: <AddProduct pageTitle="Edit Product" />,
      },
      {
        path: "orders",
        element: <Order pageTitle="Orders" />,
        id: "orders",
      },
      {
        path: "orders/:id",
        element: <OrderDetails pageTitle="Order Details" />,
        id: "orderDetails",
        title: "Order Details",
      },
      {
        path: "invoices",
        element: <Invoice pageTitle="Invoices" />,
        id: "invoices",
      },
      {
        path: "invoices/:id",
        element: <InvoiceDetails pageTitle="Invoice Details" />,
        id: "invoiceDetails",
      },
      {
        path: "users",
        element: <Users pageTitle="Users" />,
        id: "users",
      },
      {
        path: "users/:id",
        element: <UserDetails pageTitle="User Details" />,
        id: "userDetails",
      },
      {
        path: "/change-password",
        element: <ChangePassword pageTitle="Change Password" />,
        id: "changePassword",
      },
      {
        path: "profile/edit",
        element: <EditProfile pageTitle="Manage Profile" />,
      },
      {
        path: "categories",
        element: <Categories pageTitle="Category" />,
      },
      {
        path: "subCategories",
        element: <SubCategories pageTitle="SubCategory" />,
      },
      {
        path: "reviews",
        element: <Reviews pageTitle="Reviews" />,
      },
      {
        path: "messages",
        element: <Messages pageTitle="Messages" />,
      },
      {
        path: "messages/:id",
        element: <MessageDetails pageTitle="Message Details" />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" replace />,
  },
];

const commonRoutes = [
  {
    path: "/",
    element: <Home pageTitle="Home" />,
    id: "home",
  },
  {
    path: "/products",
    element: <Productslist pageTitle="Products List" />,
    id: "productsList",
  },
  {
    path: "/products/:id",
    element: <ProductsDetails pageTitle="Products Detail" />,
    id: "productsDetails",
  },
  {
    path: "/about",
    element: <About pageTitle="About" />,
    id: "about",
  },
  {
    path: "/contact",
    element: <Contact pageTitle="Contact" />,
    id: "contact",
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions pageTitle="Terms and Conditions" />,
    id: "terms",
  },
  {
    path: "/cart",
    element: <Cart pageTitle="Cart" />,
    id: "cart",
  },
  {
    path: "/all-reviews/:id",
    element: <AllReviews pageTitle="All Reviews" />,
    id: "allReviews",
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

const UserRoutes = [
  {
    path: "/",
    element: <UserLayout />,
    children: [
      ...commonRoutes,
      {
        path: "/checkout",
        element: <Checkout pageTitle="Checkout" />,
        id: "checkout",
      },
      {
        path: "/profile",
        element: <ProfileDisplay pageTitle="Profile Display" />,
        id: "profile",
      },
      {
        path: "/edit-profile",
        element: <UserProfile pageTitle="User Profile" />,
        id: "profileEdit",
      },
      {
        path: "/change-password",
        element: <ChangePassword pageTitle="Change Password" />,
        id: "changePassword",
      },
      {
        path: "/place-orders",
        element: <MyOrdersList pageTitle="My Orders" />,
        id: "myOrders",
      },
      {
        path: "/my-reviews",
        element: <MyReviews pageTitle="My Reviews" />,
        id: "myReviews",
      },
      {
        path: "/my-orders/:id",
        element: <OrderDetailsPage pageTitle="Orders Details Page" />,
        id: "myOrderDetails",
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
];

const PublicRoutes = [
  {
    path: "/",
    element: <UserLayout />,
    children: [
      ...commonRoutes,
      {
        path: "/createPassword/:token",
        element: <CreatePassword pageTitle="Create Password" />,
        id: "createPassword",
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword pageTitle="Forgot Password" />,
        id: "forgotPassword",
      },
    ],
  },
];

const GetRoutes = (isAuth = false, user = {}) => {
  const { role } = user;

  if (isAuth) {
    switch (role?.roleCode) {
      case USER_ROLES?.admin:
        return AdminRoutes;
      case USER_ROLES?.user:
        return UserRoutes;
      default:
        return PublicRoutes;
    }
  } else {
    return PublicRoutes;
  }
};

export { USER_ROLES, GetRoutes, AdminNavs, AdminRoutes };
