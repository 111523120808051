import httpClient from "../httpClient";
const baseSlug = "/publicMessages";

class httpService {
  getAllMessages() {
    return httpClient.get(`${baseSlug}`);
  }

  sendMessage(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  updateMessageStatus(id, data) {
    return httpClient.put(`${baseSlug}/status/${id}`, data);
  }

  getMessageById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  getMessagesByDates(data) {
    return httpClient.post(`${baseSlug}/byDates`, data);
  }

  deleteMessage(id) {
    return httpClient.delete(`${baseSlug}/${id}`);
  }
}

export default new httpService();
