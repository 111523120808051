import React, { forwardRef } from "react";
import { NavLink as BaseNavLink } from "react-router-dom";

const NavLink = ({ to, children, ...rest }, ref) => {
  return (
    <BaseNavLink ref={ref} to={to} {...rest}>
      {children}
    </BaseNavLink>
  );
};

export default forwardRef(NavLink);
