import httpClient from "../httpClient";
const baseSlug = "/notifications";

class httpService {
  getNotificationsOfUser() {
    return httpClient.get(`${baseSlug}/byUser`);
  }

  markRead(id) {
    return httpClient.put(`${baseSlug}/markRead/${id}`);
  }
}

export default new httpService();
