import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Divider,
  Heading,
  Text,
  FlexboxGrid,
  Panel,
  Button,
  Input,
  RadioGroup,
  Radio,
} from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../../stores/appSlice";
import { useCart } from "../../../utilities/cart";
import { clearCart, selectTotalAmount } from "../../../stores/cartSlice";
import AddressCard from "../../../components/AddressCard/AddressCard";
import OrdersService from "../../../services/orders.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import "./checkout.css";
import ScrollToTop from "../../../utilities/ScrollToTop";
import { trackPromise } from "react-promise-tracker";
import ordersService from "../../../services/orders.service";
import { Link } from "react-router-dom";
import userService from "../../../services/user.service";
import cartService from "../../../services/cart.service";
import { formatAmount } from "../../../utilities/formatAmount";
import qrCodeImage from "../../../assets/images/qr-code.jpeg";

function Checkout({ pageTitle }) {
  const { cart1 } = useCart();
  const authState = useSelector((state) => state.authState);
  const user = authState.user;
  const addresses = user?.addresses || [];

  const totalAmount1 = useSelector(selectTotalAmount);
  const [totalAmount, setTotalAmount] = useState(totalAmount1);
  const [cart, setCart] = useState(cart1);
  const [products, setProducts] = useState(cart1?.items);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [userNote, setUserNote] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [order, setOrder] = useState();
  const [send, setSend] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));

    if (addresses.length > 0) {
      const defaultAddress = addresses.find((address) => address.isDefault);

      if (defaultAddress) {
        setSelectedAddress(defaultAddress._id);
      } else {
        setSelectedAddress(addresses[0]._id);
      }
    }
  }, [dispatch, pageTitle, addresses]);

  const getAllProducts = async () => {
    const products = await trackPromise(cartService.cartOfUser());
    setProducts(products?.data?.data?.items);
    setTotalAmount(products?.data?.data?.total);
    setCart(products?.data?.data);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleAddressClick = (addressId) => {
    setSelectedAddress(addressId);
  };

  const handleUserNoteChange = (value) => {
    setUserNote(value);
  };

  useEffect(() => {
    if (send) {
      navigate(`/my-orders/${order.id}`, { replace: true });
    }
  }, [send, order]);

  const handleSubmitOrder = async () => {
    const resp = await userService.profile();
    const address = resp?.data?.data?.addresses[0]?._id;
    if (!address) {
      toast.error("Please add an address");
      return;
    }

    if (paymentMethod === "online" && transactionId === "") {
      toast.error("Please enter the Transaction ID");
      return;
    }

    const payload = {
      address: selectedAddress ? selectedAddress : address,
      userNote,
      paymentMode: paymentMethod === "cash" ? "cod" : "upi",
      transactionId,
    };
    try {
      const resp = await trackPromise(OrdersService.createOrder(payload));
      setOrder(resp.data.data);
      dispatch(clearCart());
      toast.success("Order placed successfully!");
      setSend(true);
    } catch (error) {
      console.error("Error creating order:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  function useSmallScreen(breakpoint) {
    const [isSmallScreen, setIsSmallScreen] = useState(
      window.innerWidth < breakpoint
    );

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < breakpoint);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [breakpoint]);

    return isSmallScreen;
  }

  const isSmallScreen = useSmallScreen(998);

  return (
    <>
      <ScrollToTop />
      <Container className="checkout">
        <Row>
          <Col xs={24} className="check-container">
            <Container className="checkout-container">
              <h3>Checkout</h3>
              <Row gutter={30}>
                <Col xs={24} lg={16}>
                  <Panel className="checkout-cart">
                    <Heading className="checkout-cart-title" level={4}>
                      Your order
                    </Heading>
                    <FlexboxGrid justify="space-between">
                      <FlexboxGrid.Item>
                        <Text weight="regular" align="left" size="lg">
                          Product
                        </Text>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item>
                        <Text weight="regular" align="right" size="lg">
                          Sub Total
                        </Text>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <Divider />
                    {products?.map((product) => (
                      <FlexboxGrid
                        key={product._id}
                        justify="space-between"
                        className="checkout-item"
                      >
                        <FlexboxGrid.Item>
                          <Text weight="regular" align="left" size="lg">
                            {product.name} x {product.quantity}
                          </Text>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                          <Text weight="regular" align="right" size="lg">
                            ₹{formatAmount(product.price * product.quantity)}
                          </Text>
                        </FlexboxGrid.Item>
                      </FlexboxGrid>
                    ))}
                    <Divider />
                    <FlexboxGrid justify="space-between">
                      <FlexboxGrid.Item>
                        <Text weight="regular" align="left" size="lg">
                          Subtotal(₹)
                        </Text>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item>
                        <Text weight="regular" align="right" size="lg">
                          {formatAmount(cart?.amount)}
                        </Text>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid justify="space-between">
                      <FlexboxGrid.Item>
                        <Text weight="regular" align="left" size="lg">
                          Tax(₹)
                        </Text>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item>
                        <Text weight="regular" align="right" size="lg">
                          {formatAmount(cart?.tax)}
                        </Text>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid justify="space-between">
                      <FlexboxGrid.Item>
                        <Text weight="regular" align="left" size="lg">
                          Discount(₹)
                        </Text>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item>
                        <Text weight="regular" align="right" size="lg">
                          {formatAmount(cart?.discount)}
                        </Text>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <Divider />
                    <FlexboxGrid justify="space-between">
                      <FlexboxGrid.Item>
                        <Text weight="bold" align="left" size="lg">
                          Total(₹)
                        </Text>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item>
                        <Text weight="bold" align="right" size="lg">
                          {formatAmount(totalAmount)}
                        </Text>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                    {!isSmallScreen ? (
                      <>
                        <Row className="user-note">
                          <Col xs={24}>
                            <Heading level={4}>User Note</Heading>
                            <Input
                              as="textarea"
                              rows={5}
                              value={userNote}
                              onChange={handleUserNoteChange}
                              placeholder="Enter your note here..."
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={8}>
                            <Heading level={4}>Payment Method</Heading>
                            <RadioGroup
                              name="paymentMethod"
                              value={paymentMethod}
                              onChange={setPaymentMethod}
                              appearance="default"
                            >
                              <Radio value="cash">Cash on Delivery</Radio>
                              <Radio value="online">Online Payment</Radio>
                            </RadioGroup>
                          </Col>
                          {paymentMethod && paymentMethod === "online" && (
                            <Col xs={16} className="qr-container">
                              <img
                                src={qrCodeImage}
                                width="150"
                                alt="QR Code"
                                style={{ marginBottom: 10 }}
                                height="150"
                              />
                              <p>
                                Scan the Qr for Online Payment and Enter the
                                Transaction Id for confirmation
                              </p>
                              <div>
                                <Input
                                  placeholder="Enter your Transaction Id"
                                  onChange={(value) => setTransactionId(value)}
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </Panel>
                </Col>

                <Col xs={24} lg={8} className="mt-20">
                  <Heading level={4}>Select Delivery Address</Heading>
                  {addresses.length > 0 ? (
                    <Row className="address-grid">
                      {addresses.map((address) => (
                        <Col
                          xs={24}
                          md={12}
                          lg={24}
                          key={address._id}
                          className={`address-card-item ${
                            selectedAddress === address._id ? "selected" : ""
                          }`}
                          onClick={() => handleAddressClick(address._id)}
                        >
                          <AddressCard
                            address={address}
                            isSelected={selectedAddress === address._id}
                            showEdit={false}
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <Text>
                      No addresses available.{" "}
                      <Link to="/edit-profile">Please add one.</Link>{" "}
                    </Text>
                  )}
                </Col>
              </Row>

              {isSmallScreen ? (
                <>
                  <Row className="user-note">
                    <Col xs={24} lg={16}>
                      <Heading level={4}>User Note</Heading>
                      <Input
                        as="textarea"
                        rows={5}
                        value={userNote}
                        onChange={handleUserNoteChange}
                        placeholder="Enter your note here..."
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={16}>
                      <Heading level={4}>Payment Method</Heading>
                      <RadioGroup
                        name="paymentMethod"
                        value={paymentMethod}
                        onChange={setPaymentMethod}
                        appearance="default"
                      >
                        <Radio value="cash">Cash on Delivery</Radio>
                        <Radio value="online">Online Payment</Radio>
                      </RadioGroup>
                    </Col>
                    {paymentMethod && paymentMethod === "online" && (
                      <Col xs={24} lg={16} className="qr-container">
                        <img
                          src={qrCodeImage}
                          width="150"
                          alt="QR Code"
                          style={{ marginBottom: 10 }}
                          height="150"
                        />
                        <p>Scan the qr for online payment</p>
                        <div>
                          <Input
                            placeholder="Enter your Transaction Id"
                            onChange={(value) => setTransactionId(value)}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </>
              ) : (
                ""
              )}

              <Row>
                <Col xs={24}>
                  <Button
                    appearance="primary"
                    className="checkout-btn btn-green"
                    block
                    onClick={handleSubmitOrder}
                  >
                    <h5>Place Order</h5>
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Checkout;
