import React, { useState, useEffect } from "react";
import { Modal, Button, useToaster } from "rsuite";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import userService from "../../services/user.service";
import "./delete.css";

const UserDeleteModal = ({
  itemId,
  isOpen,
  onClose,
  fetch,
  status,
  disable,
}) => {
  const [message, setMessage] = useState("");
  const [warning, setWarning] = useState("");

  const deleteUser = async (itemId) => {
    try {
      const resp = await trackPromise(userService.deleteUser(itemId));
      setMessage(resp.data.message);

      if (!resp.data.data.consentRequired) {
        onClose();
        toast.success(resp.data.message);
        fetch();
        return;
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      toast.error(error.response.data.message);
      setWarning(error.response.data.message);
    }
  };

  const disableUser = async () => {
    try {
      const resp = await trackPromise(
        userService.changeStatus(itemId, { status: !status })
      );
      toast.success(resp.data.message);
      fetch();
      onClose();
    } catch (error) {
      console.error("Delete error:", error);
      toast.error(error.response.data.message);
      setWarning(error.response.data.message);
    }
  };

  return (
    <Modal open={!!isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>
          {disable ? (!status ? "Enable" : "Disable") : "Delete"} User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            {message ||
              (disable
                ? `Are you sure you want to ${
                    !status ? "enable" : "disable"
                  } this user?`
                : "Are you sure you want to delete this user?")}
          </p>{" "}
          <p className="error-message">{warning}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {disable ? (
          <Button
            onClick={disableUser}
            appearance="primary"
            color={status ? "red" : "blue"}
          >
            {status ? "Disable" : "Enable"}
          </Button>
        ) : (
          <Button
            onClick={() => deleteUser(itemId)}
            appearance="primary"
            color="red"
          >
            Delete
          </Button>
        )}
        <Button onClick={onClose} appearance="default">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserDeleteModal;
