import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "rsuite";
import { DatePicker } from "rsuite";
import ordersService from "../../services/orders.service";
import { toast } from "react-toastify";
import { ORDER_STATUSES } from "../../utilities/constants";

const UpdateOrderStatusModal = ({
  show,
  onClose,
  orders,
  onStatusUpdate,
  bulkStatus,
  setCancel,
  cancel,
}) => {
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [sellerNotes, setSellerNotes] = useState("");
  const [statusDetails, setStatusDetails] = useState("");
  const [formValue, setFormValue] = useState({
    status: "",
    deliveryDate: null,
    sellerNote: "",
    statusDetails: "",
  });

  useEffect(() => {
    if (orders?.length > 0) {
      const firstOrderStatus = orders[0].status;
      setFormValue((prev) => ({
        ...prev,
        status: firstOrderStatus,
      }));
    }
  }, [orders]);
  const currentStatusIndex = ORDER_STATUSES.findIndex(
    (orderStatus) => orderStatus.value === bulkStatus
  );

  const handleUpdate = async () => {
    const payload = {
      orderIds: orders,
      newStatus: cancel
        ? "cancelled"
        : ORDER_STATUSES[currentStatusIndex + 1]?.value,
      statusDetails: statusDetails,
      deliveryDate: deliveryDate ? deliveryDate.toISOString() : null,
      sellerNote: sellerNotes,
    };

    try {
      await ordersService.bulkUpdateStatus(payload);
      setCancel(false);
      onStatusUpdate();
      setStatusDetails("");
      onClose();
      setFormValue({
        status: "",
        deliveryDate: null,
        sellerNote: "",
        statusDetails: "",
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleFormChange = (value) => {
    setFormValue((prev) => ({ ...prev, ...value }));
  };

  const disableBeforeToday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const handleClose = () => {
    onClose();
    setFormValue({
      status: "",
      deliveryDate: null,
      sellerNote: "",
      statusDetails: "",
    });
    setSellerNotes(""); // Reset sellerNotes
    setStatusDetails(""); // Reset statusDetails
    setDeliveryDate(null);
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Update Order Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid formValue={formValue} onChange={handleFormChange}>
          <Form.Group>
            <Form.ControlLabel>Status</Form.ControlLabel>
            <div readOnly className="orderStatus">
              {cancel
                ? "Cancelled"
                : ORDER_STATUSES[currentStatusIndex + 1]?.value}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Delivery Date</Form.ControlLabel>
            <DatePicker
              oneTap
              value={deliveryDate}
              onChange={(date) => setDeliveryDate(date)}
              disabledDate={disableBeforeToday}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Status Details</Form.ControlLabel>
            <Form.Control
              name="statusDetails"
              placeholder="Add status details"
              value={statusDetails}
              onChange={setStatusDetails}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Seller Notes</Form.ControlLabel>
            <Form.Control
              name="sellerNotes"
              placeholder="Add any notes"
              value={sellerNotes}
              onChange={setSellerNotes}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUpdate} appearance="primary">
          Update
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateOrderStatusModal;
