import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CustomProvider,
  Container,
  Sidebar,
  Sidenav,
  Content,
  Nav,
  DOMHelper,
} from "rsuite";
import classNames from "classnames";
import NavToggle from "../components/Header/NavToggle";
import Header from "../components/Header/Header";
import NavLink from "../components/Header/NavLink";
import Brand from "../components/Header/Brand";
import { AdminNavs } from "../AppRoutes";
import "./ProtectedLayout.css";

const { getHeight, on } = DOMHelper;

const NavItem = (props) => {
  const { title, eventKey, ...rest } = props;
  return (
    <Nav.Item eventKey={eventKey} as={NavLink} {...rest}>
      {title}
    </Nav.Item>
  );
};

function ProtectedLayout() {
  const authState = useSelector((state) => state.authState);
  const [theme, setTheme] = useState("light");
  const [expand, setExpand] = useState(true);
  const [windowHeight, setWindowHeight] = useState(getHeight(window));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleTheme = (checked) => {
    setTheme(checked ? "light" : "dark");
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowHeight(getHeight(window));
      setWindowWidth(width);
      setExpand(width >= 768);
    };

    handleResize();

    const resizeListener = on(window, "resize", handleResize);

    return () => {
      resizeListener.off();
    };
  }, []);

  const containerClasses = classNames("content-container", {
    "container-full": !expand,
  });

  const navBodyStyle = expand
    ? { height: windowHeight - 112, overflow: "auto" }
    : {};

  const sidebarClasses = classNames("sidebar", {
    "sidebar-overlay": !expand && windowWidth < 1200,
  });

  const sidebarWidth =
    windowWidth < 1200 ? (expand ? 190 : 50) : expand ? 260 : 50;

  return (
    <CustomProvider theme={theme}>
      <Container className="app-container">
        <Sidebar
          className={sidebarClasses}
          style={{ display: "flex", flexDirection: "column" }}
          width={sidebarWidth}
          collapsible
        >
          <Sidenav.Header>
            <Brand />
          </Sidenav.Header>
          <Sidenav expanded={expand} appearance="subtle" defaultOpenKeys={[]}>
            <Sidenav.Body style={navBodyStyle}>
              <Nav activeKey="1">
                {AdminNavs.map((item) => {
                  const { children, ...rest } = item;
                  if (children) {
                    return (
                      <Nav.Menu
                        key={item.eventKey}
                        placement="rightStart"
                        trigger="hover"
                        {...rest}
                      >
                        {children.map((child) => {
                          return <NavItem key={child.eventKey} {...child} />;
                        })}
                      </Nav.Menu>
                    );
                  }

                  if (rest.target === "_blank") {
                    return (
                      <Nav.Item key={item.eventKey} {...rest}>
                        {item.title}
                      </Nav.Item>
                    );
                  }

                  return <NavItem key={rest.eventKey} {...rest} />;
                })}
              </Nav>
            </Sidenav.Body>
          </Sidenav>
          <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
        </Sidebar>

        <Container className={containerClasses}>
          <Header user={authState.user} />
          <Content>
            <Outlet />
          </Content>
        </Container>
      </Container>
    </CustomProvider>
  );
}

export default ProtectedLayout;
