import React, { useState, useCallback } from "react";
import {
  Timeline,
  Checkbox,
  Input,
  Button,
  DatePicker,
  Panel,
  FlexboxGrid,
  Row,
  Col,
} from "rsuite";
import { formatDateTime } from "../../utilities/formatDate";
import ordersService from "../../services/orders.service";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import ReturnModal from "../User/MyOrders/ReturnModal";
import { useNavigate } from "react-router";
import "./order.css";

const StatusTimeline = ({ order, hide }) => {
  const [updatedStatusHistory, setUpdatedStatusHistory] = useState(
    order.statusHistory.map((item) => ({
      ...item,
      active: item.active,
    }))
  );
  const [sellerNote, setSellerNote] = useState(order.sellerNote || "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [modalType, setModalType] = useState("cancel");
  const [deliveryDate, setDeliveryDate] = useState(
    order.deliveryDate ? new Date(order.deliveryDate) : null
  );

  const currentStepIndex = order.statusHistory.findIndex(
    (item) => item.status === order.status
  );

  const handleCheckboxChange = useCallback(
    (index) => {
      setUpdatedStatusHistory((prevState) => {
        const newStatusHistory = prevState.map((item, idx) => {
          if (idx === index && idx === currentStepIndex + 1) {
            return { ...item, active: !item.active };
          }
          return item;
        });

        return newStatusHistory;
      });
    },
    [currentStepIndex]
  );

  const handleInputChange = useCallback(
    (index, value) => {
      setUpdatedStatusHistory((prevState) =>
        prevState.map((item, idx) =>
          idx === index && idx === currentStepIndex + 1
            ? { ...item, details: value }
            : item
        )
      );
    },
    [currentStepIndex]
  );

  const handleSaveChanges = async () => {
    const payload = {
      statusHistory: updatedStatusHistory,
      sellerNote,
      deliveryDate: deliveryDate ? deliveryDate.toISOString() : null,
    };

    try {
      const resp = await trackPromise(
        ordersService.updateOrder(order._id, payload)
      );
      toast.success("Changes saved successfully!");
      navigate(-1);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="status-timeline-container">
      <FlexboxGrid justify="space-between" className="timeline-flexbox">
        {!hide && (
          <FlexboxGrid.Item colspan={24} md={12}>
            <Panel header="Seller Note" className="panel">
              <Input
                as="textarea"
                rows={5}
                value={sellerNote}
                placeholder="Enter seller note"
                onChange={(value) => setSellerNote(value)}
              />
            </Panel>
          </FlexboxGrid.Item>
        )}
        <FlexboxGrid.Item colspan={24} md={8}>
          <Panel header="Delivery Date" className="panel">
            <DatePicker
              readOnly={hide ? true : false}
              className="delivery-datepicker"
              value={deliveryDate}
              onChange={(date) => setDeliveryDate(date)}
              placeholder="Select delivery date"
              format="dd/MM/yyyy"
            />
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24} className="tine">
          <Timeline isItemActive={(index) => index === currentStepIndex + 1}>
            {updatedStatusHistory.map((item, index) => (
              <Timeline.Item
                className={index <= currentStepIndex ? "completed-step" : ""}
                key={item._id}
              >
                <Checkbox
                  checked={item.active}
                  disabled={index !== currentStepIndex + 1}
                  onChange={() => handleCheckboxChange(index)}
                  className="capitalize1"
                >
                  {item.status}
                </Checkbox>
                <p className="timeline-time">
                  {item.date ? formatDateTime(item.date) : ""}
                </p>
                <Input
                  value={item.details || ""}
                  placeholder="Enter details"
                  disabled={index !== currentStepIndex + 1}
                  onChange={(value) => handleInputChange(index, value)}
                />
              </Timeline.Item>
            ))}
            {!hide && (
              <Button
                appearance="primary"
                className="order-de2ts-btn"
                onClick={handleSaveChanges}
              >
                Save Changes
              </Button>
            )}

            {order?.status === "placed" || order?.status === "processing" ? (
              <Button
                appearance="primary"
                color="red"
                className="cancel-bth"
                onClick={handleOpenModal}
              >
                Cancel Order
              </Button>
            ) : null}
          </Timeline>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {isModalOpen && (
        <ReturnModal
          itemId={order._id}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modalType={modalType === "cancel" ? "Cancel" : "Return"}
        />
      )}
    </div>
  );
};

export default StatusTimeline;
