import React, { useEffect, useRef, useState } from "react";
import ProductsService from "../../../services/products.service";
import { useLocation, useNavigate, useParams } from "react-router";
import "./products.css";

import {
  Button,
  Col,
  Container,
  InputNumber,
  Row,
  Tabs,
  Form,
  Rate,
  Input,
  Modal,
  Text,
  SelectPicker,
  FlexboxGrid,
} from "rsuite";
import EnvConfig from "../../../envConfig";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../../stores/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { useCart } from "../../../utilities/cart";
import ProductGallery from "../../../components/ProductGallery/ProductGallery";
import reviewService from "../../../services/review.service";
import { toast } from "react-toastify";
import ReviewCard from "../../../components/ReviewCard/ReviewCard";
import ScrollToTop from "../../../utilities/ScrollToTop";
import DeleteModal from "../../../components/DeleteModal/Delete.Modal";
import { Link } from "react-router-dom";
import { formats, modules } from "../../../utilities/reactQuill";
import ReactQuill from "react-quill";
import ReviewCarddo from "../../../components/ReviewCard/newReviewCard";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import { useSmallScreen } from "../../../utilities/useWindowSize";

const ProductsDetails = ({ pageTitle }) => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const { handleUpdateQuantity, cart, saveCartToLocalStorage } = useCart();
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState({
    reviewTitle: "",
    rating: 0,
    reviewDescription: "",
  });
  const authState = useSelector((state) => state.authState);
  const isAuth = authState.isAuthenticated;
  const [editingReview, setEditingReview] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const reviewsRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const [switchView, setSwitchView] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  const fetchProduct = async () => {
    try {
      const response = await trackPromise(ProductsService.getProductById(id));
      setProduct(response.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Error fetching product details:", error);
    }
  };

  const isSmallScreen = useSmallScreen(768);

  useEffect(() => {
    fetchProduct();
    if (isSmallScreen) {
      setSwitchView(true);
    }
  }, []);

  const allReviews = product?.reviews;

  const filteredReviews = allReviews
    ?.filter(
      (review) =>
        review.reviewTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
        review.reviewDescription
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === "highest") {
        return b.rating - a.rating;
      } else if (sortOrder === "lowest") {
        return a.rating - b.rating;
      }
      return 0;
    });

  const checkIfVoted = allReviews?.find(
    (review) => review?.user?._id === authState?.user?._id
  );

  const handleReviewSubmit = async () => {
    if (formValue.rating === 0) {
      toast.error("Please select a rating before submitting.");
      return;
    }
    const reviewPayload = {
      product: product._id,
      rating: formValue.rating,
      reviewTitle: formValue.reviewTitle,
      reviewDescription: formValue.reviewDescription,
    };
    try {
      const resp = await trackPromise(reviewService.addReview(reviewPayload));
      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setShowReviewModal(false);
    setFormValue({
      reviewTitle: "",
      rating: 0,
      reviewDescription: "",
    });
  };

  const handleDeleteReview = (review) => {
    setReviewToDelete(review);
    setShowDeleteModal(true);
  };

  const confirmDeletion = async () => {
    try {
      const resp = await trackPromise(
        reviewService.deleteReview(reviewToDelete)
      );
      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setShowDeleteModal(false);
    fetchProduct();
  };

  const handleEditReview = (review) => {
    setEditingReview(review);
    setFormValue({
      reviewTitle: review.reviewTitle,
      rating: review.rating,
      reviewDescription: review.reviewDescription,
    });
    setShowReviewModal(true);
  };

  const handleReviewLinkClick = (e) => {
    e.preventDefault();
    setActiveTab("2");
    if (reviewsRef.current) {
      reviewsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!product) return null;

  const reviewFilterData = [
    { value: "highest", label: "Highest Rating" },
    { value: "lowest", label: "Lowest Rating" },
  ];

  return (
    <Container className="product-bg">
      <ScrollToTop />
      <Col xs={24} md={20} className="product">
        <Row className="bg">
          <FlexboxGrid justify="end" className="mt-50">
            <FlexboxGrid.Item>
              <Button
                onClick={() => navigate(-1)}
                appearance="ghost"
                className="back-primary"
              >
                <ArowBackIcon />
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <div className="product-container">
            <Col xs={24} md={12}>
              <ProductGallery
                images={product?.prodImages}
                assetsDir={product?.assetsDir}
                switchView={switchView}
              />
            </Col>
            <Col xs={24} md={12}>
              <div className="details-section">
                <div className="product-name">{product?.name}</div>
                <div className="price">
                  <div className="product-rating">
                    <strong className="rating-text">
                      Avg Rating: (
                      {`${product?.averageRating?.toFixed(1) || 0}`})
                    </strong>
                    <Rate
                      className="rate-stars"
                      value={product?.averageRating || 0}
                      readOnly
                      style={{ marginRight: 8, color: "#f5a623" }}
                    />
                    <span className="review-count-text  prod-color">
                      <Link to="#" onClick={handleReviewLinkClick}>
                        {` ${product?.reviewCount || 0} ${
                          product?.reviewCount === 1 ? "review" : "reviews"
                        }`}
                      </Link>
                    </span>
                  </div>
                </div>

                <div className="price">
                  <span className="detail-title">Price: </span>
                  <strong className="detail-value">
                    ₹ {product?.price} + Free Shipping
                  </strong>
                </div>

                <div className="price">
                  <span className="detail-title">Size: </span>
                  <strong className="detail-value">{product?.size}</strong>
                </div>

                {/* <div className="price">
                  <span className="detail-title">Category: </span>
                  <strong className="detail-value">
                    {product?.subCategory?.category?.categoryName}
                  </strong>
                </div>

                <div className="price">
                  <span className="detail-title">Sub-Category: </span>
                  <strong className="detail-value">
                    {product?.subCategory?.subCatName}
                  </strong>
                </div> */}

                <div className="price1">
                  <div className="detail-title">Description :</div>
                  <div
                    className="detail-value"
                    dangerouslySetInnerHTML={{ __html: product?.description }}
                  ></div>
                </div>

                {!product.isDeleted && (
                  <div className="add-to-cart">
                    <Col xs={6} md={4}>
                      <div>
                        <InputNumber
                          min={1}
                          max={10}
                          value={quantity}
                          onChange={(value) => setQuantity(value)}
                        />
                      </div>
                    </Col>

                    <Col xs={18} md={12}>
                      <Button
                        ref={reviewsRef}
                        disabled={!product?.isActive}
                        className="add-to-cart-button btn-green"
                        onClick={() =>
                          isAuth
                            ? handleUpdateQuantity(product?._id, quantity)
                            : saveCartToLocalStorage(
                                product?._id,
                                parseInt(quantity),
                                false
                              )
                        }
                      >
                        Add to Cart
                      </Button>
                    </Col>
                  </div>
                )}
              </div>
            </Col>
          </div>
          <Col xs={24} className="description">
            <Tabs
              onSelect={(key) => setActiveTab(key)}
              defaultActiveKey="1"
              activeKey={activeTab}
              appearance="subtle"
            >
              {/* <Tabs.Tab eventKey="1" title="Description">
                <div className="description-content">
                  <div className="description-head">Description</div>
                  <div
                    dangerouslySetInnerHTML={{ __html: product?.description }}
                  ></div>
                </div>
              </Tabs.Tab> */}
              <Tabs.Tab eventKey="1" title="Reviews">
                <div className="reviews-controls">
                  <div className="flex-01">
                    <Input
                      placeholder="Search reviews"
                      value={searchQuery}
                      onChange={(value) => setSearchQuery(value)}
                      style={{ width: 200, marginRight: 10 }}
                    />
                    <SelectPicker
                      data={reviewFilterData}
                      searchable={false}
                      onChange={(value) => setSortOrder(value)}
                    />
                  </div>
                  {!product.isDeleted &&
                    (isAuth ? (
                      !checkIfVoted ? (
                        <Button
                          className="back-primary"
                          appearance="ghost"
                          onClick={() => setShowReviewModal(true)}
                        >
                          Add Review
                        </Button>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    ))}
                </div>
                <div className="reviewssection">
                  {filteredReviews?.slice(0, 3)?.map((review) => (
                    <ReviewCarddo
                      key={review._id}
                      review={review}
                      onEdit={handleEditReview}
                      onDelete={() => handleDeleteReview(review?._id)}
                      showEditBtn={
                        checkIfVoted
                          ? review?.user?._id === authState?.user?._id
                          : false
                      }
                    />
                  ))}
                  {filteredReviews?.length > 3 ? (
                    <Link to={`/all-reviews/${product?._id}`}>
                      <Button appearance="primary" color="green">
                        View more
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </Tabs.Tab>
            </Tabs>
          </Col>
        </Row>
      </Col>

      <DeleteModal
        itemId={reviewToDelete?._id}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={confirmDeletion}
      />

      <Modal
        open={showReviewModal}
        onClose={() => {
          setShowReviewModal(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <strong>{editingReview ? "Edit Review" : "Add Review"}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "75vh" }}>
          <Form fluid className="rev-form">
            <Form.Group controlId="reviewTitle">
              <Form.ControlLabel>Review Title</Form.ControlLabel>
              <Form.Control
                name="reviewTitle"
                value={formValue?.reviewTitle}
                onChange={(value) =>
                  setFormValue({ ...formValue, reviewTitle: value })
                }
              />
            </Form.Group>
            <Form.Group controlId="rating">
              <Form.ControlLabel>Rating</Form.ControlLabel>
              <Rate
                className="prod-color"
                allowHalf
                size="lg"
                defaultValue={2.5}
                value={formValue.rating}
                onChange={(value) =>
                  setFormValue({ ...formValue, rating: value })
                }
              />
            </Form.Group>
            <Form.Group controlId="reviewDescription">
              <Form.ControlLabel>Review Description</Form.ControlLabel>
              <ReactQuill
                theme="snow"
                value={formValue.reviewDescription}
                onChange={(value) =>
                  setFormValue({ ...formValue, reviewDescription: value })
                }
                modules={modules}
                formats={formats}
                name="description"
                placeholder="Write your content ..."
                rows={3}
                style={{ height: isSmallScreen ? "28rem" : "13rem" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="default"
            onClick={() => setShowReviewModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="btn-green"
            appearance="primary"
            onClick={handleReviewSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ProductsDetails;
