import httpClient from "../httpClient";
const baseSlug = "/cart";

class httpService {
  cartOfUser() {
    return httpClient.get(`${baseSlug}/ofUser`);
  }

  cartOfVisitor(data) {
    return httpClient.post(`${baseSlug}/validate`, data);
  }

  syncCart(data) {
    return httpClient.put(`${baseSlug}/sync`, data);
  }

  cartAlter(data) {
    return httpClient.put(`${baseSlug}/alter`, data);
  }

  removeProduct(id) {
    return httpClient.put(`${baseSlug}/removeItem/${id}`);
  }
}

export default new httpService();
