import React from "react";
import { Divider, Panel, Rate, Button, FlexboxGrid } from "rsuite";
import "./review.css";
import { formatDate } from "../../utilities/formatDate";
import { FaPen, FaTrash } from "react-icons/fa";

const ReviewCard = ({
  review,
  onEdit,
  onDelete,
  showEditBtn,
  product,
  admin,
  onIgnore,
}) => {
  return (
    <Panel className="review-card">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={18}>
          <h4>{review?.reviewTitle}</h4>
        </FlexboxGrid.Item>
        {showEditBtn && (
          <FlexboxGrid.Item colspan={6}>
            {!admin ? (
              <>
                <Button appearance="link" onClick={() => onEdit(review)}>
                  Edit
                </Button>
              </>
            ) : (
              ""
            )}
            {}
            <Button appearance="link" color="red" onClick={onDelete}>
              <FaTrash color="darkred" title="Delete" />
            </Button>
            {onIgnore && (
              <Button appearance="link" onClick={() => onIgnore(review)}>
                {review.isActive === true ? "Ignore" : "Activate"}
              </Button>
            )}
          </FlexboxGrid.Item>
        )}
      </FlexboxGrid>

      <div className="review-star-size">
        <Rate
          readOnly
          defaultValue={review?.rating}
          className="review-rating"
        />
      </div>
      <p className="review-date">{formatDate(review?.createdAt)}</p>
      {product ? <h6>{review?.product?.name}</h6> : ""}
      <div className="review-text">
        <div
          dangerouslySetInnerHTML={{
            __html: review?.reviewDescription,
          }}
        />
      </div>
      <Divider />
      <div className="review-actions"></div>
    </Panel>
  );
};

export default ReviewCard;
