import httpClient from "../httpClient";
const baseSlug = "/subCategory";

class httpService {
  createSubCategory(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  updateSubCategory(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }

  getSubCategoryList() {
    return httpClient.get(`${baseSlug}`);
  }

  getSubCategoryById(id) {
    return httpClient.get(`${baseSlug}/byCat/${id}`);
  }

  deleteSubCategory(id, data) {
    return httpClient.put(`${baseSlug}/delete/${id}`, data);
  }

  changeStatus(id, data) {
    return httpClient.put(`${baseSlug}/status/${id}`, data);
  }
}

export default new httpService();
