// MessageDetails.js

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { trackPromise } from "react-promise-tracker";
import { Col, Container, List, Row, Button, Modal, FlexboxGrid } from "rsuite";
import messageService from "../../services/message.service";
import ScrollToTop from "../../utilities/ScrollToTop";
import { formatDateTime } from "../../utilities/formatDate";
import { setRouteData } from "../../stores/appSlice";
import { useDispatch } from "react-redux";
import "./messages.css";
import { toast } from "react-toastify";
import ArowBackIcon from "@rsuite/icons/ArowBack";

const ResolveModal = ({ show, onClose, onConfirm }) => {
  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Resolve Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to mark this message as resolved?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onConfirm} appearance="primary">
          Yes, Resolve
        </Button>
        <Button onClick={onClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MessageDetails = ({ pageTitle }) => {
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const [showResolveModal, setShowResolveModal] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  const fetchMessage = async () => {
    try {
      const resp = await trackPromise(messageService.getMessageById(id));
      setMessage(resp.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch message", error);
    }
  };

  useEffect(() => {
    fetchMessage();
  }, [id]);

  const handleResolveMessage = (id) => {
    setSelectedMessageId(id);
    setShowResolveModal(true);
  };

  const confirmResolveMessage = async () => {
    try {
      const response = await messageService.updateMessageStatus(
        selectedMessageId,
        {
          isResolved: true,
        }
      );
      toast.success(response?.data?.message || "Message resolved successfully");
      setShowResolveModal(false);
      setSelectedMessageId(null);
      fetchMessage();
    } catch (error) {
      toast.error(
        error.data.response.message || "Failed to resolve the message."
      );
      setShowResolveModal(false);
    }
  };

  if (!message) return <div>Loading...</div>;

  return (
    <Container className="message-detail">
      <ScrollToTop />
      <Row>
        <Col xs={23} className="message-details-container">
          <FlexboxGrid justify="end">
            <FlexboxGrid.Item>
              <Button
                onClick={() => navigate("/messages")}
                appearance="ghost"
                color="blue"
              >
                <ArowBackIcon />
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Row className="order-item">
            <Col xs={6}>Sender Name:</Col>
            <Col xs={14}>
              <strong>{message?.senderName}</strong>
            </Col>
          </Row>
          <Row className="order-item">
            <Col xs={6}>Sender Email:</Col>
            <Col xs={14}>
              <strong>
                <a href={`mailto:${message?.senderEmail}`}>
                  {message?.senderEmail}
                </a>
              </strong>
            </Col>
          </Row>
          <Row className="order-item">
            <Col xs={6}>Subject:</Col>
            <Col xs={14}>
              <strong>{message?.subject}</strong>
            </Col>
          </Row>
          <Row className="order-item">
            <Col xs={6}>Received Date:</Col>
            <Col xs={14}>
              <strong>{formatDateTime(message.createdAt)}</strong>
            </Col>
          </Row>
          <Row className="order-item">
            <Col xs={6}>Message:</Col>
            <Col xs={14}>
              <p>
                <strong>{message?.message}</strong>
              </p>
            </Col>
          </Row>
          <Row className="order-item">
            <Col xs={6}>Status:</Col>
            <Col xs={14}>
              <strong>{message.isResolved ? "Resolved" : "Unresolved"}</strong>
            </Col>
          </Row>
          {!message.isResolved && (
            <Button
              onClick={() => handleResolveMessage(message._id)}
              appearance="primary"
              color="orange"
              style={{ marginTop: 16 }}
            >
              Resolve
            </Button>
          )}

          <ResolveModal
            show={showResolveModal}
            onClose={() => setShowResolveModal(false)}
            onConfirm={confirmResolveMessage}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MessageDetails;
