import React, { useState } from "react";
import { Modal, Button, Form, Schema, Uploader } from "rsuite";
import ordersService from "../../../services/orders.service";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import ReactQuill from "react-quill";
import { formats, modules } from "../../../utilities/reactQuill";
import "./myOrders.css";
import FormControl from "rsuite/esm/FormControl";

const { StringType } = Schema.Types;

const model = Schema.Model({
  cancelReason: StringType().isRequired("Reason is required."),
});

function ReturnModal({ itemId, fetch, isOpen, onClose, modalType }) {
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState("");
  const [formValue, setFormValue] = useState({
    cancelReason: "",
  });
  const [cancelReason, setCancelReason] = useState("");

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (typeof value === "object" && !value.uri) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    return formData;
  }

  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const handleSubmit = async () => {
    if (!formValue.cancelReason) {
      setError("Please provide a reason");
      toast.error(
        `Please provide a reason for the ${modalType.toLowerCase()}.`
      );
      return;
    }

    try {
      const formData = getFormData({
        returnReason: formValue.cancelReason,
      });

      if (fileList.length > 0) {
        fileList.forEach((file) => {
          formData.append("returnImages", file.blobFile);
        });
      }

      let response;
      if (modalType === "Return") {
        response = await trackPromise(
          ordersService.returnlOrder(itemId, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
        );
      } else if (modalType === "Cancel") {
        response = await trackPromise(
          ordersService.cancelOrder(itemId, {
            cancelReason: formValue.cancelReason,
          })
        );
      }

      if (response.status === 200) {
        toast.success(
          `Order successfully ${
            modalType === "Cancel" ? "cancelled" : "returned"
          }.`
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        onClose();
      } else {
        throw new Error("Failed to submit.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setError(error?.response?.data?.message);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>{modalType} Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          className="return-mod"
          model={model}
          formValue={formValue}
          onChange={(value) => setFormValue(value)}
        >
          <Form.Group controlId="cancelReason">
            <Form.ControlLabel>Reason for {modalType}</Form.ControlLabel>
            {/* <FormControl
              as={"textarea"}
              value={formValue.cancelReason}
              onChange={(value) =>
                setFormValue((prev) => ({ ...prev, cancelReason: value }))
              }
              name="cancelReason"
              placeholder="Write your content ..."
              rows={3}
              style={{ height: "7.5rem" }}
            /> */}
            <textarea
              name="cancelReason"
              rows={5}
              placeholder="Write your content ..."
              value={formValue.cancelReason}
              onChange={(e) =>
                setFormValue({ ...e, cancelReason: e.target.value })
              }
              className="rs-input"
              style={{ height: "7.5rem" }}
            />
          </Form.Group>
          {modalType === "Return" && (
            <Form.Group controlId="image" className="upload-image">
              <Form.ControlLabel>Upload Image</Form.ControlLabel>
              <Uploader
                action=""
                autoUpload={false}
                fileList={formValue.image}
                onChange={handleFileChange}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="default">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          appearance="primary"
          className="btn-green"
        >
          Submit
        </Button>
      </Modal.Footer>
      <div className="error-message">{error}</div>
    </Modal>
  );
}

export default ReturnModal;
