import httpClient from "../httpClient";
const baseSlug = "/products";

class httpService {
  products() {
    return httpClient.get(`${baseSlug}/valid`);
  }
  getproducts() {
    return httpClient.get(`${baseSlug}`);
  }

  getAllproducts() {
    return httpClient.get(`${baseSlug}/list`);
  }

  getProductById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }
  createProduct(data, config) {
    return httpClient.post(`${baseSlug}`, data, config);
  }

  updateProduct(id, data, config) {
    return httpClient.put(`${baseSlug}/${id}`, data, config);
  }
  deleteProduct(id, data) {
    return httpClient.put(`${baseSlug}/delete/${id}`, data);
  }

  changeStatus(id, data) {
    return httpClient.put(`${baseSlug}/status/${id}`, data);
  }
}

export default new httpService();
