import React from "react";
import Chart from "react-apexcharts";
import "./dashboard.css";
import { formatAmount } from "../../utilities/formatAmount";

const BarChart = ({
  title,
  actions,
  data,
  chartType,
  labels,
  month,
  height,
}) => {
  const options = {
    chart: {
      type: chartType,
      height: height,
      stacked: month ? false : true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors:
        chartType === "bar"
          ? ["transparent"]
          : [
              "#7898E3",
              "#54B399",
              "#F78A6C",
              "#FF5959",
              "#47D9E6",
              "#2E9FED",
              "#A4C7F0",
              "#FFB48F",
              "#FFE087",
              "#FF99B1",
              "#90DFAA",
              "#FF867D",
              "#BD97DB",
              "#FFCD73",
              "#FFAC6B",
              "#729EF0",
              "#6FCFC4",
              "#F48A9C",
              "#FFE7B5",
              "#AC9EDB",
            ],
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      title: {
        text: "Sales (₹)",
      },
      labels: {
        formatter: function (value) {
          return Math.floor(value);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      intersect: true,
      y: {
        formatter: function (val, opts) {
          const seriesIndex = opts.seriesIndex;
          const dataPointIndex = opts.dataPointIndex;

          const total = opts.w.globals.series
            .map((series) => series[dataPointIndex])
            .reduce((a, b) => a + b, 0);

          return ` </strong>₹${formatAmount(
            val
          )}<strong> (Total: ₹${formatAmount(total)})`;
        },
      },
    },
    colors: [
      "#7898E3",
      "#54B399",
      "#F78A6C",
      "#FF5959",
      "#47D9E6",
      "#2E9FED",
      "#A4C7F0",
      "#FFB48F",
      "#FFE087",
      "#FF99B1",
      "#90DFAA",
      "#FF867D",
      "#BD97DB",
      "#FFCD73",
      "#FFAC6B",
      "#729EF0",
      "#6FCFC4",
      "#F48A9C",
      "#FFE7B5",
      "#AC9EDB",
    ],
  };

  const series = data?.map((item) => ({
    name: item.name,
    data: item.data,
  }));

  return (
    <div>
      <div className="flexbox1">
        <span>
          <strong className="dets-i">{title}</strong>
        </span>
        <span>{actions}</span>
      </div>
      <Chart
        options={options}
        series={series}
        type={chartType}
        height={height}
      />
    </div>
  );
};

export default BarChart;
