import React, { useEffect } from "react";
import { Button, Col, Container, FlexboxGrid, Row, Text } from "rsuite";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../../stores/appSlice";
import "./about.css";
import ScrollToTop from "../../../utilities/ScrollToTop";

const About = ({ pageTitle }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [pageTitle, dispatch]);

  return (
    <>
      <Container className="about-bg">
        <ScrollToTop />
        <Container className="about-img-container">
          <Row className="about">
            <Text align="left" weight="medium" className="about-main-text">
              About Us
            </Text>
          </Row>
        </Container>
        <Row>
          <Col xs={20} className="about-container">
            {/* Hero Section */}

            <Container className="about-content">
              <Row gutter={40} className="about-content-row">
                <Col xs={24} md={8} className="about-text-col">
                  <div className="about-mains-text">
                    We Work Hard To Provide You The Best Quality Soaps And
                    Shampoos
                  </div>
                </Col>
                <Col xs={24} md={16} className="about-text-col">
                  <Text className="about-text">
                    At AyurHarmony, we embrace the timeless principles of
                    Ayurveda, meticulously crafting each product with the finest
                    natural ingredients. Our creations not only nurture your
                    hair and skin but also resonate with a deeper connection to
                    nature’s inherent vitality. We believe that true beauty lies
                    in authenticity, and our mission is to offer you bespoke
                    care solutions that celebrate and enhance your
                    individuality. Let AyurHarmony be your guide to a harmonious
                    journey of beauty, wellness, and natural radiance.
                  </Text>
                </Col>
              </Row>
            </Container>

            {/* <Container className="about-company">
              <div className="about-company-title">Our Company</div>
              <Col xs={14} className="about-company-text">
                <div className="company-text">
                  I am a text block. Click edit button to change this text.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                  leo, when an unknown printer took a galley.
                </div>
              </Col>
            </Container> */}

            <Container>
              <img
                className="company-image"
                src="https://websitedemos.net/plant-store-02/wp-content/uploads/sites/410/2019/01/bg4-free-img.jpg"
              />
            </Container>

            {/* <Container className="company-lorem">
              <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item className="lorem" colspan={11}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Provident, excepturi! Voluptatibus fuga ipsa aperiam id ut
                  aliquid, aut natus laborum eos tenetur omnis sequi quasi,
                  molestiae deserunt esse eum vitae necessitatibus nulla illo at
                  iusto minus ipsum magni. Natus quasi tenetur non dignissimos
                  inventore doloribus debitis architecto cumque. Deserunt, non?
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="lorem" colspan={11}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Provident, excepturi! Voluptatibus fuga ipsa aperiam id ut
                  aliquid, aut natus laborum eos tenetur omnis sequi quasi,
                  molestiae deserunt esse eum vitae necessitatibus nulla illo at
                  iusto minus ipsum magni. Natus quasi tenetur non dignissimos
                  inventore doloribus debitis architecto cumque. Deserunt, non?
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Container> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
