import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Button,
  Schema,
  Panel,
  InputNumber,
  Uploader,
  Grid,
  Row,
  Col,
  useToaster,
  Message,
  Checkbox,
  IconButton,
  InputGroup,
  SelectPicker,
} from "rsuite";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import PlusIcon from "@rsuite/icons/Plus";
import { trackPromise } from "react-promise-tracker";
import ProductsService from "../../services/products.service";
import { useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CategoryService from "../../services/Category.Service";
import { copyObjectByKeys } from "../../utilities/functions";
import EnvConfig from "../../envConfig";
import subCategoryService from "../../services/subCategory.service";
import { toast } from "react-toastify";
import { formats, modules } from "../../utilities/reactQuill";
import "./products.css";

function AddProduct({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const toaster = useToaster();
  const [formValue, setFormValue] = useState(getFormObject());
  const [fileList, setFileList] = useState([]);
  const [pageError, setPageError] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [productDetails, set_productDetails] = useState({});
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [showImageError, setShowImageError] = useState(false);

  const { StringType, NumberType, BooleanType } = Schema.Types;

  const model = Schema.Model({
    name: StringType().isRequired("Product name is required."),
    description: StringType().isRequired("Description is required."),
    price: NumberType()
      .isRequired("Price is required.")
      .min(0, "Price must be greater than zero."),
    size: StringType().isRequired("Size is required."),
    tax: NumberType()
      .isRequired("Tax is required.")
      .min(0, "Tax must be a positive value."),
    discount: NumberType()
      .isRequired("Discount is required.")
      .min(0, "Discount must be a positive value.")
      .max(100, "Discount cannot exceed 100%."),
    isActive: BooleanType().isRequired("Active status is required."),
    category: StringType().isRequired("Category is required."),
    subCategory: StringType().isRequired("Subcategory is required."),
  });

  function getFormObject() {
    return {
      name: "",
      description: "",
      price: "",
      size: "",
      tax: 0,
      discount: 0,
      isActive: true,
      subCategory: "",
    };
  }

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchCategories();
    if (id) {
      fetchProductDetails();
    }
  }, [id]);

  useEffect(() => {
    if (productDetails) {
      populateForm();
    }
  }, [productDetails]);
  function populateForm() {
    let formobj = { ...formValue, ...productDetails };
    formobj.category = productDetails.subCategory?.category._id;

    setFormValue((prev) => ({ ...prev, ...formobj }));
  }
  async function fetchCategories() {
    try {
      const resp = await trackPromise(CategoryService.getCategory());
      const { data } = resp;
      if (data.success) {
        setCategories(
          data.data.map((cat) => ({
            label: cat.categoryName,
            value: cat._id,
          }))
        );
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error("Fetch categories error: ", err);
    }
  }

  async function fetchSubcategories(categoryId) {
    try {
      const resp = await trackPromise(
        subCategoryService.getSubCategoryById(categoryId)
      );
      const { data } = resp;
      if (data.success) {
        const subCategories = data.data.map((subcat) => ({
          value: subcat?._id,
          label: subcat?.subCatName,
        }));
        setSubcategories(subCategories);
        setFormValue((prev) => ({
          ...prev,
          subCategory: subCategories[0]?.value,
        }));
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error("Fetch subcategories error: ", err);
    }
  }

  async function fetchProductDetails() {
    try {
      const resp = await trackPromise(ProductsService.getProductById(id));
      const { data } = resp;
      if (data.success) {
        const product = data.data;
        set_productDetails(product);

        const categoryId = product.subCategory?.category?._id;
        if (categoryId) {
          fetchSubcategories(categoryId).then(() => {
            setFormValue((prev) => ({
              ...prev,
              subCategory: product?.subCategory?._id,
            }));
          });
        }

        if (data.data.prodImages) {
          const files = data.data.prodImages.map((image) => ({
            name: image,
            url: `${EnvConfig?.MediaBase}/${data.data.assetsDir}/${image}`,
          }));
          setFileList(files);
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error("Fetch product details catch => ", err);
    }
  }

  const handleFileChange = (newFileList) => {
    const removedFiles = fileList.filter((file) => {
      return !newFileList.some((newFile) => newFile.url === file.url);
    });

    const removedImages = removedFiles?.map((image) => image.name);
    setImagesToDelete((prev) => [...prev, ...removedImages]);

    setFileList(newFileList);
  };

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (typeof value === "object" && !value.uri) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    return formData;
  }

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);

    fetchSubcategories(value);
  };

  const handleSubCategoryChange = (value) => {
    setSelectedSubCategory(value);
    setFormValue((prev) => ({ ...prev, subCategory: value }));
  };

  const handleCheckboxChange = (value, checked) => {
    setFormValue((prevState) => ({
      ...prevState,
      isActive: checked,
    }));
  };

  async function handleSubmit(event) {
    if (fileList.length === 0) {
      setShowImageError(true);
      return;
    }
    const payload = {
      ...formValue,
      ...(imagesToDelete.length > 0 && { imagesToDelete }),
    };

    const formData = getFormData(payload);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("prodImages", file.blobFile || file);
      });
    }

    try {
      const resp = id
        ? await trackPromise(
            ProductsService.updateProduct(id, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
          )
        : await trackPromise(
            ProductsService.createProduct(formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
          );

      const { data } = resp;
      if (data.success) {
        toast.success("Product saved successfully!");
        navigate(-1);
      } else {
      }
    } catch (err) {
      console.error("Product save error catch => ", err);
      toast.error(err.response.data.message);
    }
  }

  return (
    <Panel className="panelClass">
      <Form
        className="productForm"
        model={model}
        formValue={formValue}
        onChange={setFormValue}
        fluid
        onSubmit={handleSubmit}
      >
        <Grid fluid className="mb-20">
          <Row gutter={24}>
            <Col xs={16} md={8}>
              <Form.Group controlId="name">
                <Form.ControlLabel>Product Name</Form.ControlLabel>
                <Form.Control name="name" />
              </Form.Group>
            </Col>
            <Col xs={16} md={8}>
              <Form.Group controlId="category">
                <Form.ControlLabel>Category</Form.ControlLabel>
                <Form.Control
                  name="category"
                  accepter={SelectPicker}
                  data={categories}
                  value={formValue.category || selectedCategory}
                  onChange={handleCategoryChange}
                  placeholder="Select a category"
                  searchable={false}
                  style={{ width: 400 }}
                />
              </Form.Group>
            </Col>
            <Col xs={16} md={8}>
              <Form.Group controlId="subcategory">
                <Form.ControlLabel>Subcategory</Form.ControlLabel>
                <Form.Control
                  name="subCategory"
                  accepter={SelectPicker}
                  data={subcategories}
                  value={formValue.subCategory || selectedSubCategory}
                  onChange={handleSubCategoryChange}
                  placeholder="Select a subcategory"
                  searchable={false}
                  style={{ width: 400 }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="mb-20">
          <Row>
            <Col xs={16} md={8}>
              <Form.Group controlId="price">
                <Form.ControlLabel>Price</Form.ControlLabel>
                <Form.Control name="price" accepter={InputNumber} />
              </Form.Group>
            </Col>
            <Col xs={16} md={8}>
              <Form.Group controlId="size">
                <Form.ControlLabel>Size</Form.ControlLabel>
                <Form.Control name="size" />
              </Form.Group>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="mb-20">
          <Row gutter={24}>
            <Col xs={16} md={8}>
              <Form.Group controlId="tax">
                <Form.ControlLabel>Tax</Form.ControlLabel>
                <InputGroup>
                  <Form.Control name="tax" />
                  <InputGroup.Addon>%</InputGroup.Addon>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={16} md={8}>
              <Form.Group controlId="discount">
                <Form.ControlLabel>Discount</Form.ControlLabel>
                <InputGroup>
                  <Form.Control name="discount" />
                  <InputGroup.Addon>%</InputGroup.Addon>
                </InputGroup>
              </Form.Group>
            </Col>
            {/* <Col xs={16} md={8}>
              <Form.Group controlId="isActive">
                <Form.ControlLabel>Is Active</Form.ControlLabel>
                <Form.Control
                  name="isActive"
                  accepter={Checkbox}
                  checked={formValue.isActive}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            </Col> */}
          </Row>
        </Grid>
        <div style={{ height: "12.5rem" }}>
          <Row gutter={24}>
            <Col xs={16} md={12}>
              <Form.Group controlId="description">
                <Form.ControlLabel>Description</Form.ControlLabel>
                <Form.Control
                  name="description"
                  accepter={ReactQuill}
                  theme="snow"
                  value={formValue.description}
                  onChange={(value) =>
                    setFormValue((prev) => ({ ...prev, description: value }))
                  }
                  modules={modules}
                  formats={formats}
                  placeholder="Write your content ..."
                  style={{ height: "7.5rem" }}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <Row gutter={24} className="image-pr">
          <Form.Group controlId="prodImages">
            <Form.ControlLabel>Product Images</Form.ControlLabel>
            <Uploader
              name="prodImages"
              fileList={fileList}
              listType="picture"
              multiple
              onChange={handleFileChange}
              action="" // Disable automatic upload
              autoUpload={false}
              accept=".jpg, .jpeg, .png"
            >
              <IconButton
                icon={fileList.length > 0 ? <PlusIcon /> : <CameraRetroIcon />}
                className="image-upload"
              />
            </Uploader>
            {showImageError && !fileList.length && (
              <div className="error-messaaage">
                Please upload at least one image
              </div>
            )}
          </Form.Group>
        </Row>
        <div className="frm-btn-grp">
          <Button as={Link} to="../products" size="lg">
            Cancel
          </Button>
          <Button
            appearance="primary"
            size="lg"
            type="submit"
            onClick={() => {
              if (fileList.length === 0) {
                setShowImageError(true);
                return;
              }
            }}
          >
            {id ? "Update Product" : "Add Product"}
          </Button>
        </div>
        {/* {pageError && <div>{pageError}</div>} */}
      </Form>
    </Panel>
  );
}

export default AddProduct;
