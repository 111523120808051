const ServerBase =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3030"
    : "http://theayurharmony.com:3030";

const EnvConfig = {
  ApiBase: `${ServerBase}/api`,
  MediaBase: `${ServerBase}/media`,
};

export default EnvConfig;
