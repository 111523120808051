import React, { useEffect, useState } from "react";
import reviewService from "../../../services/review.service";
import { useDispatch, useSelector } from "react-redux";
import ReviewCard from "../../../components/ReviewCard/ReviewCard";
import DeleteModal from "../../../components/DeleteModal/Delete.Modal";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Rate,
  Button,
  Heading,
  Text,
} from "rsuite";
import "./reviews.css";
import { setRouteData } from "../../../stores/appSlice";
import { trackPromise } from "react-promise-tracker";
import { formats, modules } from "../../../utilities/reactQuill";
import ReactQuill from "react-quill";
import ReviewCarddo from "../../../components/ReviewCard/newReviewCard";

const MyReviews = ({ pageTitle }) => {
  const [reviews, setReviews] = useState([]);
  const authState = useSelector((state) => state.authState);
  const [editingReview, setEditingReview] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const user = authState.user;
  const [showReviewModal, setShowReviewModal] = useState(false);
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState({
    reviewTitle: "",
    rating: 3,
    reviewDescription: "",
  });

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  const getAllReviews = async () => {
    try {
      const resp = await trackPromise(reviewService.getReviewsByUser(user._id));
      setReviews(resp.data?.data);
    } catch (error) {
      console.error("Error fetching reviews:", error);
      toast.error(error.response.data.message || "Failed to fetch reviews.");
    }
  };

  useEffect(() => {
    getAllReviews();
  }, []);

  const handleDeleteReview = (reviewId) => {
    const review = reviews.find((rev) => rev?._id === reviewId);
    if (review) {
      setReviewToDelete(review);
      setShowDeleteModal(true);
    }
  };

  const confirmDeletion = async () => {
    if (!reviewToDelete) return;

    try {
      const resp = await trackPromise(
        reviewService.deleteReview(reviewToDelete?._id)
      );
      toast.success(resp.data.message);
      setReviews(reviews.filter((rev) => rev._id !== reviewToDelete?._id));
      setShowDeleteModal(false);
    } catch (error) {
      toast.error(error.response.data.message || "Failed to delete review.");
    }
  };

  const handleEditReview = (review) => {
    setEditingReview(review);
    setFormValue({
      reviewTitle: review?.reviewTitle,
      rating: review?.rating,
      reviewDescription: review?.reviewDescription,
      product: review?.product?._id,
    });
    setShowReviewModal(true);
  };

  const handleReviewSubmit = async () => {
    if (!editingReview) return;

    const reviewPayload = {
      rating: formValue?.rating,
      reviewTitle: formValue?.reviewTitle,
      reviewDescription: formValue?.reviewDescription,
      product: editingReview?.product?._id,
    };

    try {
      const resp = await trackPromise(reviewService.addReview(reviewPayload));
      toast.success(resp.data.message);
      getAllReviews();
      setReviews((prevReviews) =>
        prevReviews.map((rev) =>
          rev._id === editingReview?._id ? { ...rev, ...reviewPayload } : rev
        )
      );
    } catch (error) {
      toast.error(error.response.data.message || "Failed to update review.");
    }

    setShowReviewModal(false);
    setEditingReview(null);
    setFormValue({
      reviewTitle: "",
      rating: 3,
      reviewDescription: "",
      product: "",
    });
  };

  return (
    <Container className="product-bg">
      <Col xs={24} md={20} className="review">
        <Row className="bg">
          <div className="product-container">
            {reviews.length > 0 ? (
              <div className="reviews-section">
                {reviews.map((review) => (
                  <ReviewCarddo
                    showStatus={true}
                    key={review._id}
                    review={review}
                    onEdit={() => handleEditReview(review)}
                    onDelete={() => handleDeleteReview(review._id)}
                    showEditBtn
                    product
                  />
                ))}
                <DeleteModal
                  itemId={reviewToDelete?._id}
                  isOpen={showDeleteModal}
                  onClose={() => setShowDeleteModal(false)}
                  onDelete={confirmDeletion}
                />
              </div>
            ) : (
              <Text level={5}>No reviews yet</Text>
            )}
          </div>
        </Row>
      </Col>

      <Modal open={showReviewModal} onClose={() => setShowReviewModal(false)}>
        <Modal.Header>
          <Modal.Title>Edit Review</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "75vh" }}>
          <Form fluid>
            <Form.Group controlId="reviewTitle">
              <Form.ControlLabel>Review Title</Form.ControlLabel>
              <Form.Control
                name="reviewTitle"
                value={formValue?.reviewTitle}
                onChange={(value) =>
                  setFormValue({ ...formValue, reviewTitle: value })
                }
              />
            </Form.Group>
            <Form.Group controlId="rating">
              <Form.ControlLabel>Rating</Form.ControlLabel>
              <Rate
                allowHalf
                className="prod-color"
                size="lg"
                defaultValue={2.5}
                value={formValue.rating}
                onChange={(value) =>
                  setFormValue({ ...formValue, rating: value })
                }
              />
            </Form.Group>
            <Form.Group controlId="reviewDescription">
              <Form.ControlLabel>Review Description</Form.ControlLabel>
              <ReactQuill
                theme="snow"
                value={formValue.reviewDescription}
                onChange={(value) =>
                  setFormValue({ ...formValue, reviewDescription: value })
                }
                modules={modules}
                formats={formats}
                name="description"
                placeholder="Write your content ..."
                rows={3}
                style={{ height: "10rem" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="subtle" onClick={() => setShowReviewModal(false)}>
            Cancel
          </Button>
          <Button
            appearance="primary"
            className="btn-green"
            onClick={handleReviewSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MyReviews;
