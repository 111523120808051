import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Schema,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
} from "rsuite";
import { trackPromise } from "react-promise-tracker";
import UserService from "../../../services/user.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearAppData } from "../../../stores/store";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../../stores/appSlice";
import "./password.css";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import { useNavigate } from "react-router";

const { StringType } = Schema.Types;

const model = Schema.Model({
  oldPassword: StringType().isRequired("Old password is required."),
  newPassword: StringType()
    .isRequired("New password is required.")
    .minLength(8, "Password should be at least 8 characters long."),
  confirmPassword: StringType()
    .addRule(
      (value, data) => value === data.newPassword,
      "Passwords do not match."
    )
    .isRequired("Please confirm your new password."),
});

const ChangePassword = ({ pageTitle }) => {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);

  const handleChange = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  const handleSubmit = async () => {
    const { oldPassword, newPassword } = formValue;
    const payload = { oldPassword, newPassword };
    try {
      const resp = await trackPromise(UserService.changePassword(payload));
      toast.success(resp.data.message);
      setFormValue({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setTimeout(() => {
        clearAppData();
      }, 500);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Container className="change-container">
      <Row>
        <Col xs={8} className="password-container">
          <div className="change-password-container">
            <h3>Change Password</h3>
            <Form
              model={model}
              formValue={formValue}
              onChange={setFormValue}
              onCheck={setFormError}
              onSubmit={handleSubmit}
              layout="vertical"
            >
              <Form.Group controlId="oldPassword">
                <Form.ControlLabel>Old Password</Form.ControlLabel>
                <InputGroup>
                  <Form.Control
                    name="oldPassword"
                    type={visible ? "text" : "password"}
                    errorMessage={formError.oldPassword}
                    accepter={Input}
                  />
                  <InputGroup.Button
                    title={visible ? "hide password" : "show password"}
                    onClick={handleChange}
                  >
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputGroup.Button>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="newPassword">
                <Form.ControlLabel>New Password</Form.ControlLabel>
                <InputGroup>
                  <Form.Control
                    name="newPassword"
                    type={visible ? "text" : "password"}
                    errorMessage={formError.newPassword}
                    accepter={Input}
                  />
                  <InputGroup.Button
                    title={visible ? "hide password" : "show password"}
                    onClick={handleChange}
                  >
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputGroup.Button>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.ControlLabel>Confirm New Password</Form.ControlLabel>
                <InputGroup>
                  <Form.Control
                    name="confirmPassword"
                    type={visible ? "text" : "password"}
                    errorMessage={formError.confirmPassword}
                    accepter={Input}
                  />
                  <InputGroup.Button
                    title={visible ? "hide password" : "show password"}
                    onClick={handleChange}
                  >
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputGroup.Button>
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Button
                  appearance="primary"
                  style={{ backgroundColor: "var(--clr--primary)" }}
                  type="submit"
                >
                  Change Password
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePassword;
