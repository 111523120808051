import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import OrdersService from "../../services/orders.service";
import {
  Col,
  Container,
  Pagination,
  Row,
  Table,
  Tabs,
  Button,
  DateRangePicker,
  Heading,
  InputGroup,
  Input,
  IconButton,
  Affix,
} from "rsuite";

import Column from "rsuite/esm/Table/TableColumn";
import { Cell, HeaderCell } from "rsuite-table";
import { Link, useNavigate } from "react-router-dom";
import { formatDate, formatDateTime } from "../../utilities/formatDate";
import { TiThSmallOutline } from "react-icons/ti";
import UpdateOrderStatusModal from "./UpdateOrderStatusModal";
import { ORDER_STATUSES } from "../../utilities/constants";
import { setRouteData } from "../../stores/appSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReturnModal from "../User/MyOrders/ReturnModal";
import ScrollToTop from "../../utilities/ScrollToTop";
import { FaThList } from "react-icons/fa";
import { isSmallScreen, useSmallScreen } from "../../utilities/useWindowSize";
import { formatAmount } from "../../utilities/formatAmount";

const Order = ({ pageTitle }) => {
  const [orders, setOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryName, setSearchQueryName] = useState("");
  const [limit, setLimit] = useState(7);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [bulkStatus, setBulkStatus] = useState("");
  const [cancelOrders, setCancelOrders] = useState([]);
  const [cancel, setCancel] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  useEffect(() => {
    const now = new Date();
    const startDate = new Date(now); // Start date is 30 days prior
    startDate.setDate(now.getDate() - 30); // Subtract 30 days

    setDateRange([startDate, now]);
    getAllOrders(startDate, now);
  }, []);

  const getAllOrders = async (startDate, endDate) => {
    const startOfDay = new Date(startDate?.setHours(0, 0, 0, 0));
    const endOfDay = new Date(endDate?.setHours(23, 79, 59, 999));
    const dateRangeObj = {
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
    };

    try {
      const resp = await trackPromise(
        OrdersService.getOrderByDates(dateRangeObj)
      );
      setOrders(resp.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleDateRangeChange = (range) => {
    setDateRange(range);
    if (range[0] && range[1]) {
      getAllOrders(range[0], range[1]);
    }
    setPage(1);
  };

  const getStatusCounts = () => {
    const counts = {};

    orders.forEach((order) => {
      const orderStatus = order.status.toLowerCase();
      counts[orderStatus] = (counts[orderStatus] || 0) + 1;
    });

    return counts;
  };

  const statusCounts = getStatusCounts();

  const filteredOrders = orders.filter((order) => {
    const orderId = (order.orderId || order._id).toLowerCase();
    const userName = order?.user?.firstName.toLowerCase();
    const lowerCaseSearchQuery = searchQuery.trim().toLowerCase();
    const lowerCaseSearchQueryName = searchQueryName.trim().toLowerCase();

    return (
      (selectedStatus === "all" ||
        order.status.toLowerCase() === selectedStatus.toLowerCase()) &&
      orderId.includes(lowerCaseSearchQuery) &&
      userName.includes(lowerCaseSearchQueryName)
    );
  });

  const getData = () => {
    let filteredOrder = filteredOrders;

    let sortedOrder = [...filteredOrder];
    if (sortColumn && sortType) {
      sortedOrder.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];

        if (sortColumn === "orderDate" || sortColumn === "deliveryDate") {
          x = new Date(x);
          y = new Date(y);
        } else if (typeof x === "string" && isNaN(Date.parse(x))) {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    const start = limit * (page - 1);
    const end = start + limit;
    const paginatedOrder = sortedOrder.slice(start, end);

    return paginatedOrder;
  };

  const handleStatusUpdate = async () => {
    await getAllOrders(dateRange[0], dateRange[1]);
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const openModal = () => {
    if (selectedOrders.length === 0) {
      toast.error("Please select at least one order to update.");
      return;
    }

    setShowModal(true);
  };
  const closeModal = () => {
    if (selectedOrders.length === 0) {
      toast.error("Please select at least one order to update.");
      return;
    }
    setCancel(false);
    setShowModal(false);
  };

  useEffect(() => {
    setSelectedOrders([]);
  }, [selectedStatus]);

  const handleSelectOrder = (order) => {
    setSelectedOrders((prevSelectedOrders) => {
      if (prevSelectedOrders.includes(order._id)) {
        return prevSelectedOrders.filter((id) => id !== order._id);
      } else {
        setBulkStatus(order?.status);
        setCancelOrders(order._id);
        return [...prevSelectedOrders, order._id];
      }
    });
  };

  const isSmallScreen = useSmallScreen(768);

  const visibleStatuses = ["placed", "processing", "dispatched"];
  const visibleStatuses2 = ["placed", "processing"];

  return (
    <Container className="orrrd">
      <ScrollToTop />
      <div className="orddd">
        <Affix>
          <Row
            style={{
              width: "100%",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Col xs={24} sm={12}>
              <DateRangePicker
                placeholder="Select Date Range"
                onChange={handleDateRangeChange}
                value={dateRange}
                format="dd/MM/yyyy"
              />
            </Col>
            <Col xs={6}>
              <InputGroup inside style={{ marginBottom: 10 }}>
                <Input
                  placeholder="Search by Order ID"
                  value={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                />
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup inside style={{ marginBottom: 10 }}>
                <Input
                  placeholder="Search by User name"
                  value={searchQueryName}
                  onChange={(value) => setSearchQueryName(value)}
                />
              </InputGroup>
            </Col>
          </Row>
        </Affix>
        <div className="custom-tabs">
          <Tabs
            activeKey={selectedStatus}
            onSelect={(status) => {
              setSelectedStatus(status);
              setPage(1);
            }}
            className="custom-tabs"
          >
            <Tabs.Tab
              eventKey="all"
              title={`All (${orders.length})`}
              key="all"
            />
            {ORDER_STATUSES.map((status) => (
              <Tabs.Tab
                eventKey={status.value}
                title={`${status.label} (${statusCounts[status.value] || 0})`}
                key={status.id}
              />
            ))}
          </Tabs>

          {visibleStatuses.includes(selectedStatus) && (
            <div className="cancel-btns">
              <Button
                appearance="primary"
                className="update-status-btn"
                onClick={openModal}
              >
                Update Status
              </Button>
              {visibleStatuses2.includes(selectedStatus) && (
                <Button
                  appearance="primary"
                  color="red"
                  onClick={() => {
                    openModal();
                    setCancel(true);
                  }}
                  style={{ marginLeft: "0.625rem" }}
                >
                  Cancel Orders
                </Button>
              )}
            </div>
          )}
        </div>

        <Row gutter={0} className="custom-tab-content">
          <Col xs={24}>
            <Table
              autoHeight
              affixHeader={50}
              wordWrap="break-word"
              data={getData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
            >
              {visibleStatuses.includes(selectedStatus) && (
                <Column flexGrow={0.7} fixed>
                  <HeaderCell>Select</HeaderCell>
                  <Cell>
                    {(rowData) => (
                      <input
                        type="checkbox"
                        checked={selectedOrders.includes(rowData._id)}
                        onChange={() => handleSelectOrder(rowData)}
                      />
                    )}
                  </Cell>
                </Column>
              )}
              <Column width={240}>
                <HeaderCell>OrderId</HeaderCell>
                <Cell dataKey="_id">
                  {(rowData) => (
                    <Link to={`/orders/${rowData._id}`}>
                      {rowData.orderId || rowData._id}
                    </Link>
                  )}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>User</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <>
                      {rowData?.user?.firstName} {rowData?.user?.lastName}
                    </>
                  )}
                </Cell>
              </Column>
              <Column width={130}>
                <HeaderCell>Payment Mode</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div className="upper">{rowData?.paymentMode}</div>
                  )}
                </Cell>
              </Column>
              <Column width={140}>
                <HeaderCell>Mobile</HeaderCell>
                <Cell dataKey="user.phone" />
              </Column>
              <Column width={200}>
                <HeaderCell>Order Date</HeaderCell>
                <Cell>
                  {(rowData) => {
                    const date = formatDateTime(rowData.createdAt);
                    return date;
                  }}
                </Cell>
              </Column>

              <Column width={150}>
                <HeaderCell>Product Count</HeaderCell>
                <Cell>{(rowData) => rowData?.items?.length}</Cell>
              </Column>
              <Column width={150}>
                <HeaderCell>City</HeaderCell>
                <Cell>{(rowData) => rowData?.deliveryAddress?.city}</Cell>
              </Column>
              <Column width={150}>
                <HeaderCell>
                  {`${selectedStatus === "returned" ? "Returned" : "Delivery"}`}{" "}
                  Date
                </HeaderCell>
                <Cell>
                  {(rowData) => {
                    const returnedStatus = rowData?.statusHistory?.find(
                      (status) => status.status === "returned"
                    );

                    if (selectedStatus === "returned" && returnedStatus) {
                      return formatDate(returnedStatus.date);
                    } else {
                      return rowData?.deliveryDate
                        ? formatDate(rowData.deliveryDate)
                        : " - ";
                    }
                  }}
                </Cell>
              </Column>
              <Column width={120}>
                <HeaderCell>Order Status</HeaderCell>
                <Cell dataKey="status" className="orderStatus" />
              </Column>
              <Column width={90}>
                <HeaderCell>Total (₹)</HeaderCell>
                <Cell dataKey="total">
                  {(rowData) => formatAmount(rowData?.total)}
                </Cell>
              </Column>
            </Table>
          </Col>
        </Row>

        <div className="pagination-0">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size={isSmallScreen ? "xs" : "md"}
            layout={[
              "total",
              "-",
              `${!isSmallScreen ? "limit" : ""}`,
              `${!isSmallScreen ? "|" : ""}`,
              "pager",
              `${!isSmallScreen ? "|" : ""}`,
              `${!isSmallScreen ? "skip" : ""}`,
            ]}
            total={filteredOrders?.length}
            limitOptions={[5, 10, 30, 50]}
            limit={limit}
            pages={Math.ceil(filteredOrders?.length / limit)}
            activePage={page}
            onChangePage={(page) => setPage(page)}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        <UpdateOrderStatusModal
          setCancel={setCancel}
          show={showModal}
          onClose={closeModal}
          orders={selectedOrders}
          bulkStatus={bulkStatus}
          onStatusUpdate={handleStatusUpdate}
          cancel={cancel}
        />
      </div>
    </Container>
  );
};

export default Order;
