import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Pagination,
  InputGroup,
  useToaster,
  Message,
  Affix,
  Button,
  Form,
  Modal,
  FlexboxGrid,
  Checkbox,
} from "rsuite";
import { trackPromise } from "react-promise-tracker";
import { VscCircleSlash } from "react-icons/vsc";

import { Link } from "react-router-dom";
import { Cell, HeaderCell } from "rsuite-table";
import Column from "rsuite/esm/Table/TableColumn";
import SearchIcon from "@rsuite/icons/Search";
import { IconButton } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
import CategoryService from "../../services/Category.Service";
import DeleteModal from "../../components/DeleteModal/Delete.Modal";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { toast } from "react-toastify";
import ScrollToTop from "../../utilities/ScrollToTop";
import "./category.css";
import { useSmallScreen } from "../../utilities/useWindowSize";
import CategoryDeleteModal from "../../components/DeleteModal/CategoryDeleteModal";
import { FaSquareCheck, FaSquareXmark } from "react-icons/fa6";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const Categories = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(6);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [disable, setDisable] = useState(false);
  const [formValue, setFormValue] = useState({
    categoryName: "",
    isActive: true,
  });
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    getCategories();
  }, [dispatch, pageTitle]);

  const getCategories = async () => {
    try {
      const resp = await trackPromise(CategoryService.getCategoryList());
      setCategories(resp.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch categories", error);
    }
  };

  const getData = () => {
    let filteredCategories = categories.filter((category) =>
      category.categoryName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (sortColumn && sortType) {
      filteredCategories.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        return sortType === "asc" ? x - y : y - x;
      });
    }

    const start = limit * (page - 1);
    const end = start + limit;
    return filteredCategories.slice(start, end);
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const handleOpenModal = (itemId) => {
    setSelectedItemId(itemId);
    const category = categories.find((cat) => cat._id === itemId);
    setStatus(category.isActive);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsAddOpen(false);
    setIsModalOpen(false);
    setSelectedItemId(null);
  };

  const addEditOpenModal = (itemId = null) => {
    if (itemId) {
      const category = categories.find((cat) => cat._id === itemId);
      setStatus(category.isActive);
      if (category) {
        setFormValue({
          categoryName: category.categoryName,
        });
      }
    } else {
      setFormValue({ categoryName: "" });
    }
    setSelectedItemId(itemId);
    setIsAddOpen(true);
  };

  const handleSubmit = async () => {
    try {
      if (selectedItemId) {
        const resp = await trackPromise(
          CategoryService.updateCategory(selectedItemId, formValue)
        );
        toast.success("Category updated successfully");
      } else {
        await trackPromise(CategoryService.createCategory(formValue));
        toast.success("Category added successfully");
      }
      getCategories();
      handleCloseModal();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error(error);
    }
  };

  const isSmallScreen = useSmallScreen(768);

  return (
    <Container className="cate-cot">
      <ScrollToTop />
      <div className="cate-box">
        <Affix>
          <FlexboxGrid
            style={{
              width: "100%",
              backgroundColor: "white",
              padding: "10px",
            }}
            justify="space-between"
            className="products-header"
          >
            <FlexboxGrid.Item className="filters-row">
              <InputGroup inside>
                <Input
                  placeholder="Search ..."
                  value={searchQuery}
                  onChange={setSearchQuery}
                />
                <InputGroup.Button>
                  <SearchIcon />
                </InputGroup.Button>
              </InputGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <Button
                appearance="primary"
                size="md"
                onClick={() => addEditOpenModal()}
              >
                Add Category
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Affix>
        <Row gutter={0}>
          <Col xs={24}>
            <Table
              affixHeader={60}
              wordWrap="break-word"
              data={getData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
              autoHeight
            >
              <Column width={300} sortable>
                <HeaderCell>Category Name</HeaderCell>
                <Cell dataKey="categoryName" />
              </Column>
              <Column flexGrow={2}>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="isActive">
                  {(rowData) => (
                    <div>
                      {rowData.isActive ? (
                        <IconButton
                          className="nohiver"
                          title="status"
                          icon={<FaSquareCheck color="green" />}
                        />
                      ) : (
                        <IconButton
                          className="nohiver"
                          title="status"
                          icon={<FaSquareXmark color="red" />}
                        />
                      )}
                    </div>
                  )}
                </Cell>
              </Column>
              <Column width={130} align="center">
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "0.625rem",
                      }}
                    >
                      <IconButton
                        title="edit"
                        icon={<EditIcon className="icon-blue" />}
                        onClick={() => addEditOpenModal(rowData._id)}
                      />
                      <IconButton
                        title="delete"
                        icon={<TrashIcon color="red" />}
                        onClick={() => {
                          setDisable(false); // Set disable to false for delete action
                          handleOpenModal(rowData._id);
                        }}
                      />
                      <IconButton
                        title="disable"
                        icon={
                          !rowData.isActive ? (
                            <IoIosCheckmarkCircleOutline />
                          ) : (
                            <VscCircleSlash />
                          )
                        }
                        onClick={() => {
                          setDisable(true); // Set disable to true for disable action
                          handleOpenModal(rowData._id);
                        }}
                      />
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
          </Col>
        </Row>
        <div className="pagination-0">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size={isSmallScreen ? "xs" : "md"}
            layout={[
              "total",
              "-",
              `${!isSmallScreen ? "limit" : ""}`,
              `${!isSmallScreen ? "|" : ""}`,
              "pager",
              `${!isSmallScreen ? "|" : ""}`,
              `${!isSmallScreen ? "skip" : ""}`,
            ]}
            total={categories.length}
            limitOptions={[5, 10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        <Modal open={isAddOpen} onClose={handleCloseModal}>
          <Modal.Header>
            <Modal.Title>
              {selectedItemId ? "Edit Category" : "Add Category"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid onChange={setFormValue} formValue={formValue}>
              <Form.Group>
                <Form.ControlLabel>Category Name</Form.ControlLabel>
                <Form.Control name="categoryName" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSubmit} appearance="primary">
              {selectedItemId ? "Update" : "Create"}
            </Button>
            <Button onClick={handleCloseModal} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {isModalOpen && (
          <CategoryDeleteModal
            itemId={selectedItemId}
            onClose={handleCloseModal}
            isOpen={isModalOpen}
            fetch={getCategories}
            status={status}
            disable={disable}
          />
        )}
      </div>
    </Container>
  );
};

export default Categories;
