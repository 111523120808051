import React from "react";
import Chart from "react-apexcharts";
import { Panel } from "rsuite";
import { formatAmount } from "../../utilities/formatAmount";

const defaultOptions = {
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      customScale: 0.8,
      donut: {
        size: "75%",
      },
      offsetY: 0,
    },
    stroke: {
      colors: undefined,
    },
  },
  colors: [
    "#7898E3",
    "#54B399",
    "#F78A6C",
    "#FF5959",
    "#47D9E6",
    "#2E9FED",
    "#A4C7F0",
    "#FFB48F",
    "#FFE087",
    "#FF99B1",
    "#90DFAA",
    "#FF867D",
    "#BD97DB",
    "#FFCD73",
    "#FFAC6B",
    "#729EF0",
    "#6FCFC4",
    "#F48A9C",
    "#FFE7B5",
    "#AC9EDB",
  ],
  tooltip: {
    y: {
      formatter: function (val, { seriesIndex, series }) {
        const orders = this.orders;
        const orderCount = orders ? orders[seriesIndex] : 0;
        return ` Orders: ${orderCount} | Sales (₹): ${val.toFixed()}`;
      },
    },
  },
  legend: {
    position: "bottom",
    offsetY: 0,
    horizontalAlign: "left",
    // formatter: (seriesName, opts) => {
    //   const label = opts.w.globals.labels[opts.seriesIndex];
    //   return label.length > 20 ? `${label.slice(0, 15)}...` : label;
    // },
  },
  chart: {
    toolbar: {
      show: true,
      tools: {
        download: true,
      },
    },
  },
};

const PieChart = ({ title, data, type, labels, options, orders, height }) => {
  const combinedOptions = Object.assign({}, defaultOptions, options, {
    labels,
    tooltip: {
      ...defaultOptions.tooltip,
      y: {
        ...defaultOptions.tooltip.y,
        formatter: function (val, { seriesIndex }) {
          const orderCount = orders ? orders[seriesIndex] : 0;
          return `Orders: ${orderCount} Sales (₹): ${formatAmount(val)}`;
        },
      },
    },
  });

  return (
    <Panel className="card" bodyFill header={title}>
      <Chart
        series={data}
        type={type}
        height={height}
        options={combinedOptions}
      ></Chart>
    </Panel>
  );
};

export default PieChart;
