import { createSlice } from "@reduxjs/toolkit";

const authInitState = {
  isAuthenticated: undefined,
  token: "",
  user: {},
};

const authSlice = createSlice({
  name: "authState",
  initialState: { ...authInitState },
  reducers: {
    initAuthState: (state) => {
      state.isAuthenticated = false;
      state.token = authInitState.token;
      state.user = authInitState.user;
    },
    setAuth: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    updateUserAddresses: (state, action) => {
      state.user.addresses = action.payload;
    },
  },
});

export default authSlice.reducer;
export const {
  initAuthState,
  setAuth,
  setToken,
  setUser,
  updateUserAddresses,
} = authSlice.actions;
