export const THEME = [
  {
    theme_code: "default",
    color_theme: "defaultTheme",
    CLR_PRIMARY: "#B18C93",
    CLR_SECONDARY: "#E8DCDF",
    CLR_TRANSPARENT: "transparent",
    CLR_WHITE_01: "#FFFFFF",
    CLR_BLACK_01: "#000000",
    CLR_GREY_01: "#433B39",
  },
];
