// Notifications.js

import React, { useEffect, useState } from "react";
import { Whisper, Button, Badge, Popover } from "rsuite";
import { FaBell } from "react-icons/fa";
import "./userHeader.css";
import { Link } from "react-router-dom";
import { timeDifference } from "../../../utilities/timeDifference";
import { trackPromise } from "react-promise-tracker";
import notificationService from "../../../services/notification.service";
import { useDispatch, useSelector } from "react-redux";
import {
  initNotification,
  selectAllNotifications,
  selectUnreadCount,
} from "../../../stores/notificationSlice";
import { toast } from "react-toastify";
import { useSmallScreen } from "../../../utilities/useWindowSize";

const NotificationsOverlay = React.forwardRef(
  ({ onClose, notifications, visibleCount, loadMore, ...rest }, ref) => {
    const dispatch = useDispatch();
    const [isScrollable, setIsScrollable] = useState(false);

    const getPath = (noteType, noteOrigin) => {
      switch (noteType) {
        case "order":
          return `/my-orders/${noteOrigin}`;
        default:
          return "/";
      }
    };

    const handleClick = async (id) => {
      try {
        const resp = await trackPromise(notificationService.markRead(id));
        dispatch(initNotification({ notes: resp.data?.data || [] }));
      } catch (error) {
        toast.error(error.response.data.message);
        console.error("Failed to mark read", error);
      }
      onClose();
    };

    useEffect(() => {
      if (visibleCount > 5) {
        setIsScrollable(true);
      }
    }, [visibleCount]);

    const isSmallScreen = useSmallScreen(768);

    return (
      <Popover
        {...rest}
        ref={ref}
        arrow
        style={{
          width: isSmallScreen ? 240 : 330,
          position: "fixed",
          top: "3rem",
        }}
      >
        <h5
          style={{
            margin: 0,
            paddingBottom: "8px",
            borderBottom: "1px solid #eaeaea",
          }}
        >
          Notifications
        </h5>
        <div
          style={{
            maxHeight: isScrollable ? "500px" : "auto",
            overflowY: isScrollable ? "scroll" : "visible",
          }}
        >
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            {notifications?.length > 0 ? (
              notifications.slice(0, visibleCount).map((notification) => (
                <React.Fragment key={notification._id}>
                  <p>
                    {notification.isRead ? (
                      <span className="dot-dot"></span>
                    ) : (
                      <span className="red-dot"></span>
                    )}
                    {timeDifference(notification.createdAt)}
                  </p>
                  <Link
                    to={getPath(notification.noteType, notification.noteOrigin)}
                    className="noti-click"
                    onClick={() => handleClick(notification._id)}
                  >
                    <p className="noti-title">{notification.title}</p>
                  </Link>
                  <hr className="noti-divider" />
                </React.Fragment>
              ))
            ) : (
              <li>
                <p>No new notifications</p>
              </li>
            )}
          </ul>
        </div>
        {visibleCount < notifications?.length && (
          <Button
            appearance="link"
            className="load-more-btn prod-color"
            onClick={loadMore}
          >
            More notifications
          </Button>
        )}
      </Popover>
    );
  }
);

const Notifications = ({ triggerRef }) => {
  const notifications = useSelector(selectAllNotifications);
  const unreadCount = useSelector(selectUnreadCount);
  const [visibleCount, setVisibleCount] = useState(3);

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  return (
    <div className="bell">
      <Whisper
        trigger="click"
        placement="bottomEnd"
        speaker={(props, ref) => {
          const { className, onClose } = props;
          return (
            <NotificationsOverlay
              onClose={onClose}
              notifications={notifications}
              visibleCount={visibleCount}
              loadMore={loadMore}
              className={className}
              ref={ref}
            />
          );
        }}
      >
        <Button ref={triggerRef} title="notifications">
          <Badge content={unreadCount}>
            <FaBell color="black" />
          </Badge>
        </Button>
      </Whisper>
    </div>
  );
};

export default Notifications;
