import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalCount: 0,
  unreadCount: 0,
  notifications: [],
};

const notificationSlice = createSlice({
  name: "notificationState",
  initialState,
  reducers: {
    initNotification(state, action) {
      state.notifications = action.payload.notes;
      state.totalCount = action.payload?.notes?.length;
      state.unreadCount = action.payload.notes?.filter(
        (noti) => !noti.isRead
      ).length;
    },
  },
});

export const { initNotification, markNotificationRead } =
  notificationSlice.actions;

export const selectAllNotifications = (state) =>
  state.notificationState.notifications;
export const selectTotalCount = (state) => state.notificationState.totalCount;
export const selectUnreadCount = (state) => state.notificationState.unreadCount;

export default notificationSlice.reducer;
