import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Panel,
  ButtonGroup,
  Button,
  Affix,
  DateRangePicker,
  Form,
  CheckPicker,
  Divider,
} from "rsuite";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import moment from "moment";
import { CHART_TYPES } from "../../utilities/constants";
import "./dashboard.css";

import { useDispatch } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import ScrollToTop from "../../utilities/ScrollToTop";
import dashboardService from "../../services/dashboard.service";
import { trackPromise } from "react-promise-tracker";
import productsService from "../../services/products.service";
import { toast } from "react-toastify";

const Dashboard = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const previous30DaysStart = moment()
    .subtract(30, "days")
    .startOf("day")
    .toDate();
  const today = moment().endOf("day").toDate();
  const [selectedDates, setSelectedDates] = useState([
    previous30DaysStart,
    today,
  ]);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [month, setMonth] = useState(false);
  const [count, setCount] = useState({
    placed: 0,
    delivered: 0,
    cancelled: 0,
    returned: 0,
  });
  const [timePeriod, setTimePeriod] = useState("week");
  const [labels, setLabels] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartLabels, setPieChartLabels] = useState([]);
  const [pieOrders, setPieOrders] = useState();
  const [diff, setDiff] = useState(0);
  const [chartType, setChartType] = useState("bar");
  const [height, setHeight] = useState(0);
  const [activeUsers, setActiveUsers] = useState();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    getAllProducts();
    getActiveUsers();
  }, [dispatch, pageTitle]);

  useEffect(() => {
    if (products.length > 0) {
      handleSubmit();
    }
  }, [selectedDates, selectedProduct, timePeriod, products]);

  useEffect(() => {
    if (orders) {
      const uniqueUsers = getActiveUsers(orders);
      setActiveUsers(uniqueUsers);
    }
  }, [orders]);

  const handleDateChange = (range) => {
    setSelectedDates(range);

    const diffDays = moment(range[1]).diff(moment(range[0]), "days");
    const diffWeeks = moment(range[1]).diff(moment(range[0]), "weeks");
    setDiff(diffDays);

    if (diffDays > 20 * 7) {
      setTimePeriod("month");
      setMonth(false);
    } else if (diffDays > 30) {
      setTimePeriod("week");
    } else if (diffDays <= 1) {
      setTimePeriod("day");
    } else if (diffDays <= 7) {
      setTimePeriod("week");
    } else {
      setTimePeriod("month");
    }

    setMonth(diffDays <= 30);
  };

  const getAllProducts = async () => {
    try {
      const resp = await trackPromise(productsService.getAllproducts());
      const productsData = resp.data.data.map((product) => ({
        id: product._id,
        label: product.name,
      }));
      setProducts(productsData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error fetching products list", error);
    }
  };

  const handleProductChange = (value) => {
    setSelectedProduct(value);
  };

  const handleSubmit = async () => {
    const productIds =
      selectedProduct?.length > 0
        ? selectedProduct
        : products?.map((product) => product?.id);

    const payload = {
      startDate: moment(selectedDates[0]).toISOString(),
      endDate: moment(selectedDates[1]).toISOString(),
      productIds,
    };

    try {
      const response = await trackPromise(
        dashboardService.getAnalytics(payload)
      );
      setOrders(response.data.data.orderList);
      updateCount(response.data.data.orderList);
      updateBarChartData(response.data.data.productOrders);
      updatePieChartData(response.data.data.productOrders);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error fetching data:", error);
    }
  };

  const updateCount = (orders) => {
    let updatedCount = {
      placed: 0,
      processing: 0,
      dispatched: 0,
      delivered: 0,
      cancelled: 0,
      returned: 0,
    };

    orders.forEach((order) => {
      switch (order.status) {
        case "placed":
          updatedCount.placed += 1;
          break;
        case "processing":
          updatedCount.processing += 1;
          break;
        case "dispatched":
          updatedCount.dispatched += 1;
          break;
        case "delivered":
          updatedCount.delivered += 1;
          break;
        case "cancelled":
          updatedCount.cancelled += 1;
          break;
        case "returned":
          updatedCount.returned += 1;
          break;
        default:
          break;
      }
    });

    setCount(updatedCount);
  };

  const updateBarChartData = (products) => {
    let startDate = moment(selectedDates[0]);
    const endDate = moment(selectedDates[1]);

    const labelsArray = [];
    const productData = {};

    products.forEach((product) => {
      productData[product._id] = {
        name: product.name,
        salesByPeriod: [],
        orderCounts: [],
      };
    });

    if (timePeriod === "week") {
      let weekStart = startDate.clone();
      let weekEnd = weekStart.clone().endOf("week");

      while (weekStart.isBefore(endDate)) {
        if (weekEnd.isAfter(endDate)) {
          weekEnd = endDate.clone();
        }

        diff < 24
          ? labelsArray.push(
              `${weekStart.format("DD/M/YY")} - ${weekEnd.format("DD/M/YY")}`
            )
          : labelsArray.push(
              `${weekStart.format("DD/MM")} - ${weekEnd.format("DD/MM")}`
            );

        products.forEach((product) => {
          const periodOrders = product.orders.filter((order) => {
            const orderDate = moment(order.createdAt);
            return orderDate.isBetween(weekStart, weekEnd, null, "[]");
          });

          const totalSales = periodOrders.reduce(
            (total, order) => total + order.priceBreakUp.total,
            0
          );
          const orderCount = periodOrders.length;

          const periodIndex = labelsArray.length - 1;

          if (!productData[product._id].salesByPeriod[periodIndex]) {
            productData[product._id].salesByPeriod[periodIndex] = 0;
            productData[product._id].orderCounts[periodIndex] = 0;
          }

          productData[product._id].salesByPeriod[periodIndex] += totalSales;
          productData[product._id].orderCounts[periodIndex] += orderCount;
        });

        weekStart = weekEnd.clone().add(1, "day");
        weekEnd = weekStart.clone().endOf("week");
      }
    } else {
      while (startDate <= endDate) {
        if (timePeriod === "day") {
          switch (true) {
            case diff <= 10:
              labelsArray.push(startDate.format("DD/MM/YY"));
              break;

            case diff <= 20 && diff > 10:
              labelsArray.push(startDate.format("D/MM"));
              break;

            default:
              labelsArray.push(startDate.format("D/M"));
              break;
          }
        } else {
          labelsArray.push(startDate.format("DD/MM/YY"));
        }

        products.forEach((product) => {
          const periodOrders = product.orders.filter((order) => {
            const orderDate = moment(order.createdAt);
            if (timePeriod === "day") return orderDate.isSame(startDate, "day");
            if (timePeriod === "month")
              return orderDate.isSame(startDate, "month");
            if (timePeriod === "year")
              return orderDate.isSame(startDate, "year");
            return false;
          });

          const totalSales = periodOrders.reduce(
            (total, order) => total + order.priceBreakUp.total,
            0
          );
          const orderCount = periodOrders.length;

          const periodIndex = labelsArray.length - 1;

          if (!productData[product._id].salesByPeriod[periodIndex]) {
            productData[product._id].salesByPeriod[periodIndex] = 0;
            productData[product._id].orderCounts[periodIndex] = 0;
          }

          productData[product._id].salesByPeriod[periodIndex] += totalSales;
          productData[product._id].orderCounts[periodIndex] += orderCount;
        });

        startDate.add(1, timePeriod);
      }
    }

    const chartData = Object.keys(productData).map((productId) => ({
      name: productData[productId].name,
      data: productData[productId].salesByPeriod,
    }));

    setLabels(labelsArray);
    setBarChartData(chartData);
  };

  const updatePieChartData = (products) => {
    const productSales = {};
    const productOrderCounts = {};

    products.forEach((product) => {
      const productId = product._id;

      if (!productSales[productId]) {
        productSales[productId] = 0;
        productOrderCounts[productId] = 0;
      }

      product.orders.forEach((order) => {
        const orderTotal = order.priceBreakUp.total;

        productSales[productId] += orderTotal;
        productOrderCounts[productId]++;
      });
    });

    const data = products.map((product) => productSales[product._id] || 0);
    const orderData = products.map(
      (product) => productOrderCounts[product._id] || 0
    );
    const labels = products.map((product) => `${product.name} `);

    setPieChartData(data);
    setPieOrders(orderData);
    setPieChartLabels(labels);
  };

  const calculateChartHeight = (numLabels) => {
    let baseHeight = 400;

    const width = window.innerWidth;

    if (width < 768) {
      baseHeight = 350;
    } else if (width >= 768 && width < 1024) {
      baseHeight = 400;
    }

    switch (numLabels) {
      case numLabels > 5:
        setHeight(420);
        break;
      case numLabels > 10:
        setHeight(440);
        break;
      case numLabels > 15:
        setHeight(460);
        break;
      case numLabels > 20:
        setHeight(480);
        break;
    }

    if (numLabels > 10) {
      baseHeight += (numLabels - 10) * 10;
    }

    return baseHeight;
  };

  useEffect(() => {
    const pieHeight = calculateChartHeight(pieChartLabels.length);

    setHeight(pieHeight);
  }, [pieChartLabels]);

  const getActiveUsers = () => {
    const userSet = new Set();
    orders.forEach((order) => {
      userSet.add(order.user);
    });
    return Array.from(userSet);
  };

  return (
    <div className="dashboard-container">
      <ScrollToTop />
      <div className="topbar-container">
        <div className="topbar">
          <Panel>
            <Form onSubmit={handleSubmit} className="form-flex">
              <Form.Group>
                <DateRangePicker
                  name="dateRange"
                  format="dd/MM/yy"
                  placeholder="Select date range"
                  onChange={handleDateChange}
                  value={selectedDates}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="product"
                  accepter={CheckPicker}
                  data={products}
                  placeholder="Select Product"
                  value={selectedProduct}
                  onChange={handleProductChange}
                  valueKey="id"
                  labelKey="label"
                  className="products-dropdown"
                />
              </Form.Group>
            </Form>
          </Panel>
        </div>
      </div>
      <Row gutter={10} className="trend-box-container">
        <Col xs={8} md={8} lg={3}>
          <Panel className="trend-box bg-gradient-green">
            <div className="panel-content">
              <span className="title">
                <span>Orders</span>
                <br />
                <span>Placed</span>
              </span>
              <span className="value">{count?.placed}</span>
            </div>
          </Panel>
        </Col>
        <Col xs={8} md={8} lg={3}>
          <Panel className="trend-box bg-gradient-yellow">
            <div className="panel-content">
              <span className="title">
                <span>Orders</span>
                <br />
                <span>Processing</span>
              </span>
              <span className="value">{count?.processing}</span>
            </div>
          </Panel>
        </Col>
        <Col xs={8} md={8} lg={3}>
          <Panel className="trend-box bg-gradient-blue ">
            <div className="panel-content">
              <span className="title">
                <span>Orders</span>
                <br />
                <span>Dispatched</span>
              </span>
              <span className="value">{count?.dispatched}</span>
            </div>
          </Panel>
        </Col>
        <Col xs={8} md={8} lg={3}>
          <Panel className="trend-box bg-gradient-teal">
            <div className="panel-content">
              <span className="title">
                <span>Orders</span>
                <br />
                <span>Delivered</span>
              </span>
              <span className="value">{count?.delivered}</span>
            </div>
          </Panel>
        </Col>
        <Col xs={8} md={8} lg={3}>
          <Panel className="trend-box bg-gradient-orange-red">
            <div className="panel-content">
              <span className="title">
                <span>Orders</span>
                <br />
                <span>Cancelled</span>
              </span>
              <span className="value">{count?.cancelled}</span>
            </div>
          </Panel>
        </Col>
        <Col xs={8} md={8} lg={3}>
          <Panel className="trend-box bg-gradient-lavender">
            <div className="panel-content">
              <span className="title">
                <span>Orders</span>
                <br />
                <span>Returns</span>
              </span>
              <span className="value">{count?.returned}</span>
            </div>
          </Panel>
        </Col>
        <Col xs={8} md={8} lg={3}>
          <Panel className="trend-box bg-gradient-purple-pink">
            <div className="panel-content">
              <span className="title">
                <span>Active</span>
                <br />
                <span>Users</span>
              </span>
              <span className="value">{activeUsers?.length}</span>
            </div>
          </Panel>
        </Col>
      </Row>

      <Row className="charts-container">
        <Col xs={24} lg={15} className="bar-chart">
          <BarChart
            month={month}
            height={height}
            title="Periodic Sales Overview"
            actions={
              <>
                <ButtonGroup className="butn-grp">
                  <Button
                    size="xs"
                    active={chartType === "bar"}
                    onClick={() => setChartType("bar")}
                  >
                    Bar Chart
                  </Button>
                  <Button
                    size="xs"
                    active={chartType === "line"}
                    onClick={() => setChartType("line")}
                  >
                    Line Chart
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button
                    size="xs"
                    active={timePeriod === "day"}
                    onClick={() => {
                      setTimePeriod("day");
                      setMonth(false);
                    }}
                  >
                    Days
                  </Button>
                  <Button
                    size="xs"
                    active={timePeriod === "week"}
                    onClick={() => {
                      setTimePeriod("week");
                      setMonth(false);
                    }}
                  >
                    Weeks
                  </Button>
                  <Button
                    size="xs"
                    active={timePeriod === "month"}
                    onClick={() => {
                      setTimePeriod("month");
                      setMonth(true);
                    }}
                  >
                    Month
                  </Button>
                </ButtonGroup>
              </>
            }
            data={barChartData}
            labels={labels}
            chartType={chartType}
          />
        </Col>
        <Col xs={24} lg={8} className="pie-chart">
          <PieChart
            height={height}
            title="Product Sales"
            data={pieChartData}
            orders={pieOrders}
            type={CHART_TYPES.pie}
            labels={pieChartLabels}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
