import httpClient from "../httpClient";
const baseSlug = "/reviews";

class httpService {
  addReview(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  getAllReviews() {
    return httpClient.get(`${baseSlug}`);
  }

  getReviewsByUser() {
    return httpClient.get(`${baseSlug}/byUser`);
  }

  getLatestReviews() {
    return httpClient.get(`${baseSlug}/list`);
  }

  deleteReview(id) {
    return httpClient.delete(`${baseSlug}/${id}`);
  }

  ignoreReview(id, data) {
    return httpClient.put(`${baseSlug}/status/${id}`, data);
  }
}

export default new httpService();
