import React, { useState } from "react";
import { Modal, Button, IconButton, useToaster } from "rsuite";

function DeleteModal({ itemId, isOpen, onClose, onDelete }) {
  const confirmDeletion = () => {
    onDelete(itemId);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Are you sure you want to delete this item?</div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="default">
          Cancel
        </Button>
        <Button color="red" onClick={confirmDeletion} appearance="primary">
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
